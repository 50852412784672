import axios from 'axios'

import { API_ENDPOINT } from '../constants/API_ENDPOINT'

export const httpGet = async (endpoint, token, data = {}) => {
	return await axios.get(`${API_ENDPOINT}/${endpoint}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params: {
			...data
		}
	})
}

export const httpDownloadUrl = async (endpoint, token, data = {}) => {
	return await axios.get(endpoint, {
		headers: {
			Authorization: `Bearer ${token}`
		},
		params: {
			...data
		}
	})
}

export const httpDownload = async (endpoint, token, data = {}) => {
	return await httpDownloadUrl(`${API_ENDPOINT}/${endpoint}`, token, data);
}

export const httpPost = async (endpoint, token, body, headers = {}) => {
	return await axios.post(`${API_ENDPOINT}/${endpoint}`, body, {
		headers: {
			...headers,
			Authorization: `Bearer ${token}`,
		},
	})
}

export const httpCustomPost = async (endpoint, token, body, data = {}) => {
	return await axios.post(`${API_ENDPOINT}/${endpoint}`, body, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params:{
			...data
		}
	})
}

export const httpPatch = async (endpoint, token, body, id) => {
	return await axios.patch(`${API_ENDPOINT}/${endpoint}/${id}`, body, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
}


export const httpCustomPatch = async (endpoint, token, body, headers = {}) => {
	return await axios.patch(`${API_ENDPOINT}/${endpoint}`, body, {
		headers: {
			...headers,
			Authorization: `Bearer ${token}`,
		},
	})
}

export const httpUpload = async (endpoint, token, body) => {
	return await axios.patch(`${API_ENDPOINT}/${endpoint}`, body, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
}

export const httpForm = async (endpoint, token, formData) => {
	return await axios.post(`${API_ENDPOINT}/${endpoint}`, formData, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
}

export const httpDelete = async (endpoint, token, id) => {
	return await axios.delete(`${API_ENDPOINT}/${endpoint}/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
}

export const httpCustomDelete = async (endpoint, token) => {
	return await axios.delete(`${API_ENDPOINT}/${endpoint}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
}

export const uploadFileToS3 = async (
	signedUrl: string,
	file: File,
) => {
	try {
		let uri = URL.createObjectURL(file);
		const fileBody = await getBlob(uri);
		const fileType = fileBody["type"];
		if (fileBody == null) {
			return null;
		} else {
			var options = {
				headers: {
					'Content-Type': fileType ?? "application/octet-stream",
				}
			};
			// const base64 = await getBase64(file);
			// const buffer = Buffer.from(base64, "base64");
			const uploadFile = await axios.put(signedUrl, fileBody, options);
			// const uploadFile = await axios({
			// 	method: "PUT",
			// 	url: signedUrl,
			// 	data: fileBody,
			// 	headers: { "Content-Type": fileType ?? "application/octet-stream" },
			// });
			if (uploadFile.status === 200) {
				console.log("🥳🥳");
				return uploadFile;
			} else {
				console.log("😩😩");
				throw new Error("😩😩");
			}
		}
	} catch (error) {
		console.log("😩😩");
	}
};

// export const httpPutFile = async (endpoint, token, file) => {
// 	var options = {
// 		headers: {
// 			'Content-Type': file.type,
// 		}
// 	};
// 	// return await axios.put(endpoint, file, options);


// 	// const ImageResponse = await fetch(imageFilePath);
// 	// const imageBlob = await ImageResponse.blob();

// 	// return await axios(
// 	// 	{
// 	// 		method: 'put',
// 	// 		url: endpoint,
// 	// 		data: file,
// 	// 		headers: {
// 	// 			"Content-Type": file.type,
// 	// 			"Content-Length": file.size.toString(),
// 	// 		}
// 	// 	});

// 	const fileContent = await fileReader(file);

// 	return await axios.put(endpoint, fileContent, options);
// }

export const httpPut = async (endpoint, token, body, id) => {
	const last = !!id ? `/${id}` : "";
	return await axios.put(`${API_ENDPOINT}/${endpoint}${last}`, body, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
}

export const getBase64 = async (file: File): Promise<string | ArrayBuffer> => {
	return new Promise((resolve, reject) => {
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			resolve(reader.result);
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
			reject(error);
		};
	});
}

async function getBlob(fileUri) {
	const resp = await fetch(fileUri);
	const imageBody = await resp.blob();
	return imageBody;
}