import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ArchivesApp from '../components/archives-app';
import ArchivesAppMy from '../components/archives-app/my-files';
import { ArchivesProvider } from '../contexts/archives-context';
import { CustomerProvider } from '../contexts/customer-context';
import { FilesProvider } from '../contexts/files-context';

const Archives = ({ match }) => {
	return (
		<div className="archive-main">
			<ArchivesProvider>
				<CustomerProvider>
					<FilesProvider>
						<Switch>
							<Route exact path={`${match.path}`} component={ArchivesApp} />
							<Route exact path={`${match.path}/my-file`} component={ArchivesAppMy} />
						</Switch>
					</FilesProvider>
				</CustomerProvider>
			</ArchivesProvider>
		</div>
	)
}

export default Archives;