import { useState, useEffect, useCallback } from 'react';
import { useEthosNotification } from '../../contexts/ethos-notification-context';
import { useConfigurations } from '../../contexts/configurations-context';
import { useEthosModal } from '../../contexts/ethos-modal-context';
import { useService } from '../../contexts/service-context';
import { InputSelect } from '../../ui-components/forms';
import { useFiles } from '../../contexts/files-context';

const ServicesConfiguration = (props) => {
	const [services, setServices] = useState(null);
	const [selectedService, setSelectedService] = useState(null);
	const [selectedServiceId, setSelectedServiceId] = useState(null);
	const [templatesInnerList, setTemplatesInnerList] = useState(null);
	const [servicesOptions, setServicesOptions] = useState(null);
	const { showSuccessToast, showErrorToast, handleError } = useEthosNotification();
	const { configurations } = useConfigurations();
	const { getServices, associateServiceTemplate, uploadServiceTemplate, updateService } = useService();
	const { openUploadFileModal, openDocumentListModal, openChooseNewFileModeModal, openServiceTemplatesModal } = useEthosModal();
	const [uploadedFileList, setUploadedFileList] = useState(null);
	const { downloadFile, getFilesInfo } = useFiles();


	useEffect(() => {
		if (configurations && configurations.typeTemplate && configurations.typeTemplate.length) {
			var lista = [];
			if (selectedService && selectedService.templates) {
				for (var i = 0; i < configurations.typeTemplate.length; i++) {
					const item = configurations.typeTemplate[i];
					var obj = {};
					obj.templateType = item.value;
					obj.templateTypeLabel = item.label;
					obj.templates = selectedService.templates.filter(x => x.type === item.value);
					lista.push(obj);
				}
			}
			setTemplatesInnerList(lista);
		}
	}, [configurations, selectedService]);

	useEffect(() => {
		if (selectedServiceId) {
			var service = services.find(x => x._id === selectedServiceId);
			if (!service.templates) {
				service.templates = [];
			}
			setSelectedService(service);
		} else {
			setSelectedService(null);
		}

	}, [selectedServiceId, services]);

	const loadTemplates = useCallback(async () => {
		var { data, error } = await getFilesInfo("TEMPLATE");
		if (error) {
			handleError(error);
			return;
		}
		setUploadedFileList(data.data);
	}, [getFilesInfo, handleError]);

	const loadServices = useCallback(async () => {
		var { data, error } = await getServices();

		if (error) {
			handleError(error);
			return;
		}
		setServices(data.data);
		if (selectedServiceId) {
			var service = data.data.find(x => x._id === selectedServiceId);
			setSelectedService(service);
		} else {
			setSelectedService(null);
		}
	}, [selectedServiceId, getServices, handleError]);

	const init = useCallback(() => {
		loadServices();
		loadTemplates();
	}, [loadServices, loadTemplates]);

	const isLoaded = () => {
		return services && servicesOptions && configurations && uploadedFileList;
	}


	useEffect(() => {
		init();
	}, [init]);

	useEffect(() => {
		if (services) {
			const options = services.map(x => {
				return { label: x.displayName, value: x._id }
			});
			setServicesOptions(options);
		}
	}, [services]);

	const doOpenUploadFileModal = async (templateType) => {
		const callbackFunc = async (myFile, filename, expDate) => {
			var { error } = await uploadServiceTemplate(selectedService._id, templateType, myFile, filename);
			if (error) {
				handleError(error);
				return;
			}

			showSuccessToast("Servizio aggiornato correttamente!");
			init();
		}

		openUploadFileModal("Carica il template", true, false, callbackFunc, ".doc,.docx");
	}

	const doOpenAssociateTemplateModal = async (templateType) => {
		const callbackFunc = async (files) => {
			var { error } = await associateServiceTemplate(selectedService._id, files[0]._id);
			if (error) {
				handleError(error);
				return;
			}

			showSuccessToast("Servizio aggiornato correttamente!");
			init();
		}

		var visibleFiles = uploadedFileList.filter(f => {
			var serviceTemplate = selectedService.templates.find(x => x.id === f._id);
			return !serviceTemplate;
		});
		var customColumns = [];
		customColumns.push({ label: "Stato", value: "status" });
		openDocumentListModal(visibleFiles, templateType, false, true, callbackFunc, doDownloadFile, null, "Seleziona il template da associare al servizio", null, true, customColumns, null);
	}

	const doOpenAddTemplateModal = (type) => {
		const addFileCallback = (t, isNew) => {
			if (isNew) {
				doOpenUploadFileModal(t);
			} else {
				doOpenAssociateTemplateModal(type);
			}
		}

		var fileTypes = [{ value: type, label: type }];
		openChooseNewFileModeModal(fileTypes, addFileCallback, type);
	}

	const doDownloadFile = async (f) => {
		const { error } = await downloadFile(f._id);
		if (error) {
			showErrorToast("Errore durante il download del file!");
			return;
		}
		// startDownload(data);
	}

	const doOpenTemplateListModal = (type) => {
		var visibleFiles = uploadedFileList.filter(f => {
			var serviceTemplate = selectedService.templates.find(x => x.id === f._id);
			return serviceTemplate;
		})
		for (var i = 0; i < visibleFiles.length; i++) {
			const item = visibleFiles[i];
			var t = selectedService.templates.find(x => x.id === item._id);
			item.status = t.status;
		}
		openServiceTemplatesModal(visibleFiles, type, doDownloadFile, doArchiveTemplate);
	}

	const doArchiveTemplate = async (f) => {
		var srvCopy = { ...selectedService };

		for (var i = 0; i < srvCopy.templates.length; i++) {
			if (srvCopy.templates[i].id === f._id) {
				srvCopy.templates[i].status = "ARCHIVED";
				break;
			}
		}

		var { error } = await updateService(srvCopy);
		if (error) {
			handleError(error);
			return;
		}

		showSuccessToast("Servizio aggiornato correttamente!");
		init();
	}



	return (
		<>
			{isLoaded() ?
				<div>
					<div className="row">
						<div className="col-12 text-center">
							<h3>Pagina di configurazione dei servizi</h3>
						</div>
						<div className="col-12">
							<div className="row">
								<div className="col-8 offset-2">
									<InputSelect label="Seleziona il servizio da configurare" name="service" data={servicesOptions} onChange={setSelectedServiceId} />
								</div>
							</div>
						</div>
						{selectedService ?
							<div className="col-12 mt-3">
								<div className="row">
									<h3 className="text-center">Configurazione Templates</h3>
									{
										(templatesInnerList && templatesInnerList.length) ?
											<div className="col-6 offset-3">
												<table className="table table-striped">
													<tbody>
														{templatesInnerList.map(function (t, tIndex) {
															return (
																<tr key={"template_type_" + (tIndex)}>
																	<td>{t.templateTypeLabel} ({t.templates.length})</td>
																	<td className="text-right">
																		<div className="btn-group" role="group">
																			<button onClick={() => doOpenAddTemplateModal(t.templateType)} className="btn btn-primary">
																				Carica nuovo
																			</button>
																			{t.templates.length ?
																				<button onClick={() => doOpenTemplateListModal(t.templateType)} className="btn btn-secondary">
																					Vedi templates
																				</button> :
																				<button disabled className="btn btn-secondary">
																					Vedi templates
																				</button>
																			}
																		</div>
																	</td>
																</tr>
															)
														})}
													</tbody>
												</table>
											</div>
											: <h4 className="text-center">Nessun template configurabile</h4>

									}
								</div>
							</div> : <h4 className="text-center mt-5">Seleziona il servizio da configurare</h4>
						}
					</div>
				</div> :
				<div>
					<h3 className="text-center">Caricamento in corso...</h3>
				</div>
			}
		</>
	)
}

export default ServicesConfiguration;