import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import moment from 'moment';

import { InputText, InputSelect, InputEmail, InputTextArea, InputDate, InputNumber } from '../../forms';
import { useBank } from '../../../contexts/banks-context';
import { useUser } from '../../../contexts/user-context';
import { useCustomer } from '../../../contexts/customer-context';
import { useEthosNotification } from '../../../contexts/ethos-notification-context';

const StepForm = styled.section``;

const FormPracticeDebts = ({ role = undefined, isDisable = false, practice = null, handleSave, handleResetExpire, handleGenerateFile = () => { } }) => {
	const { getCustomersForPractice } = useCustomer();
	const { getBanksForPractice } = useBank();
	const { getUsersPractice } = useUser();
	const { handleError } = useEthosNotification();
	const [agents, setAgents] = useState(null);
	const [stepOpened, setStepOpened] = useState(0);
	const [customers, setCustomers] = useState([]);
	const [banks, setBanks] = useState([]);
	const [sconto, setSconto] = useState(practice !== null ? practice.sconto : 0);
	const [importDebit, setImportDebit] = useState(practice !== null ? practice.importDebit : 0);
	const [spesaFissa, setSpesaFissa] = useState(practice !== null ? practice.spesaFissa : 427);
	const [percScontoScad, setPercScontoScad] = useState(practice !== null ? practice.percScontoScad : 0);
	const [importSingleInstallment, setImportSingleInstallment] = useState(0);
	const [expireDateInstallment, setExpireDateInstallment] = useState(moment().format('YYYY-MM-DD'));
	const [nInstallmentsTable, setNInstallmentsTable] = useState(practice !== null ? practice.nInstallmentsTable : []);
	const [statusDebt, setStatusDebt] = useState(practice !== null ? practice.status : null);
	const [btnGenerationTxt, setBtnGenerationTxt] = useState('Genera');
	const [dateStartTime, setDateStartTime] = useState(practice === null ? null : practice.dateStartTime)

	const [importoPrestito, setImportoPrestito] = useState(practice !== null ? practice.importLoan : 0.00);
	const [nrRate, setNrRate] = useState(practice !== null ? practice.nInstallments : 0.00);
	const [setSingleImportPrestito] = useState(practice !== null ? practice.singleInstallmentImport : 0.00);

	const [currentPecList,setCurrentPecList] = useState([])


	const { register, handleSubmit } = useForm({});

	const getCustomersPractice = useCallback(async () => {
		const { data, error } = await getCustomersForPractice();

		if (error !== null) {
			handleError(error);
			return;
		}

		const dataToObj = data.map((item, index) => {
			return {
				index: index,
				value: item._id,
				label: `${item.first_name} ${item.last_name}`.toUpperCase()
			}
		});

		setCustomers(dataToObj);
	}, [getCustomersForPractice, handleError]);

	const getBanks = useCallback(async () => {
		const { data, error } = await getBanksForPractice();

		if (error !== null) {
			handleError(error);
			return;
		};

		const dataToObj = data.map((item, index) => {
			return {
				index: index,
				pec: item.pec.map((pecItem,pecIndex)=>{return {index:pecIndex,pec:pecItem,value:pecItem,label:pecItem}}),
				value: item.name,
				label: item.name
			}
		});

		setBanks(dataToObj);
	}, [getBanksForPractice, handleError]);

	const initUser = useCallback(async () => {
		const { data } = await getUsersPractice('AGENTE');

		const dataToObj = data.map((item, index) => {
			return {
				index: index,
				value: item._id,
				label: item.username
			}
		});

		setAgents(dataToObj);
	}, [getUsersPractice]);

	useEffect(() => {
		initUser();
		getCustomersPractice();
		getBanks();
	}, [getBanks, getCustomersPractice, initUser])

	const onSubmit = async (data) => {
		let obj = data;

		// fix 
		for (var p in obj) {
			if (obj[p] === '') {
				obj[p] = undefined;
			}
		}

		await handleSave({ ...obj, nInstallmentsTable });
	}

	const selectStatus = [
		{ value: 'BOZZA', label: 'BOZZA' },
		{ value: 'ISTRUTTORIA', label: 'ISTRUTTORIA' },
		{ value: 'INVIO RICHIESTA', label: 'INVIO RICHIESTA' },
		{ value: 'ANALISI CONTRATTO', label: 'ANALISI CONTRATTO' },
		{ value: 'INVIO OFFERTA SALDOESTRALCIO', label: 'INVIO OFFERTA SALDOESTRALCIO' },
		{ value: 'DEBITO CHIUSO', label: 'DEBITO CHIUSO' }
	]

	const handleOnChangeBank = async (value) => {
		const pec = banks.filter(item => item.label === value)[0].pec;
		setCurrentPecList(pec)
 	}

	const handleAddInstallment = async () => {
		const tot = parseFloat((importDebit * 0.10 * 1.22) - ((importDebit * 0.10 * 1.22) * sconto) / 100).toFixed(2);
		const single = tot / importSingleInstallment;

		let data = [];
		for (let i = 0; i < importSingleInstallment; i++) {
			data.push({
				importSingleInstallment: single,
				expireDateInstallment: moment(expireDateInstallment).add('month', i + 1),
				payed: false,
				datePayed: null
			});
		}

		setNInstallmentsTable(data);
		return;
	}

	const handleDeleteInstallments = async () => {
		const confirmDelete = window.confirm('Sei sicuro di voler continuare?');

		if (!confirmDelete) {
			return;
		}

		setNInstallmentsTable([]);
	}

	// const handleDeleteInstallment = async (index) => {
	// 	const confirmDelete = window.confirm('Sei sicuro di voler continuare?');

	// 	if (!confirmDelete) {
	// 		return;
	// 	}

	// 	var nINstallmentsToRemove = nInstallmentsTable;
	// 	nINstallmentsToRemove.splice(index, 1);
	// 	setNInstallmentsTable(nINstallmentsToRemove);
	// }

	const handleGenerateFileLocal = async () => {
		setBtnGenerationTxt('Genero...');
		await handleGenerateFile('Gestione-Debito/Proposta-Saldo-Stralcio-Base.docx');
		setBtnGenerationTxt('Genera');
	}

	const handleChangeCheckboxPayed = async (checked, index) => {
		let old = nInstallmentsTable;
		old[index].payed = checked;
		setNInstallmentsTable(old);
	}

	const handleChangeDatePayed = async (value, index) => {
		let old = nInstallmentsTable;
		console.log(old)
		old[index].datePayed = value;
		console.log(old)
		setNInstallmentsTable(old);
	}

	useEffect(() => {
		if (practice !== null) {
		  if (banks.length > 0 && practice.bank !== null) {
			const filterBanksArray = banks.filter((item) => item.label === practice.bank);
			if (filterBanksArray.length > 0) {
			  const currentPecList = filterBanksArray[0];
			  setCurrentPecList(currentPecList.pec);
			}
		  }
		}
	  }, [banks, practice]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>

			<div className="alert alert-info">
				<strong>TUTTI I CAMPI SEGUITI DA * SONO OBBLIGATORI</strong>
			</div>

			<StepForm style={{ display: stepOpened === 0 ? 'block' : 'none' }}>
				<div className="row">
					<div className="col-md-4">
						{customers.length > 0 && !(role === 'AVVOCATO' && practice.legal) ?
							<InputSelect defaultValue={practice !== null ? practice.clientId : null} name="clientId" data={practice !== null ? [...customers.filter(item => item.value === practice.clientId)] : customers} label="Cliente" isRequired={true} register={register} />
							: null}
						{role === 'AVVOCATO' && practice.legal ?
							<InputSelect defaultValue={practice !== null ? practice.clientId : null} disabled={isDisable} name="clientId" data={[
								{ value: practice.clientId, label: `${practice.customerdata[0].first_name} ${practice.customerdata[0].last_name}` }
							]} label="Cliente" register={register} />
							: null}
					</div>

					<div className="col-md-4">
						<InputText defaultValue={practice !== null ? practice.nContract : null} disabled={isDisable} name="nContract" label="N. Contratto" isRequired={true} register={register} />
					</div>

					<div className="col-md-4">
						{banks.length > 0 ?
							<InputSelect defaultValue={practice !== null ? practice.bank : null} disabled={isDisable} name="bank" data={banks} label="Finanziaria/Banca" isRequired={true} onChange={handleOnChangeBank} register={register} />
							: null}
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<InputText defaultValue={practice !== null ? practice.branch : null} disabled={isDisable} name="branch" label="Filiale" isRequired={false} register={register} />
					</div>

					<div className="col-md-4">
						<InputDate disabled={isDisable} defaultValue={practice !== null ? practice.dateStart : null} name="dateStart" label="Data inizio Contratto" isRequired={false} register={register} />
					</div>

					<div className="col-md-4">
						<InputDate disabled={isDisable} defaultValue={practice !== null ? practice.dateEnd : null} name="dateEnd" label="Data fine Contratto" isRequired={false} register={register} />
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<InputDate disabled={isDisable} defaultValue={practice !== null ? practice.dateClose : null} name="dateClose" label="Data chiusura Contratto" isRequired={false} register={register} />
					</div>

					<div className="col-md-4">
						<InputSelect defaultValue={practice !== null ? practice.pecBank : null} disabled={isDisable} data={currentPecList} name="pecBank" label="PEC Banca" register={register} />
					</div>

					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="0.01" isRequired={false} price={true} defaultValue={importDebit} onChange={setImportDebit} disabled={isDisable} name="importDebit" label="Importo del Debito" register={register} />
					</div>

					{agents !== null && (role === 'ADMIN' || role === 'AGENTE') ?
						<div className="col-md-4">
							<InputSelect defaultValue={practice !== null ? practice.agentId : null} disabled={isDisable} name="agentId" data={agents} label="Agente" isRequired={false} register={register} />
						</div>
						: null}

					<div className="col-md-12 mt-2">
						<InputTextArea defaultValue={practice !== null ? practice.notes : null} disabled={isDisable} name="notes" label="Note" register={register} />
					</div>
				</div>

				<hr />

				<h5 className="fw-bold">Dati Prestito Personale</h5>
				<div className="row">
					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="0.01" isRequired={false} price={true} defaultValue={practice !== null ? practice.importLoan : null} disabled={isDisable} name="importLoan" label="Importo Prestito" register={register} onChange={setImportoPrestito} />
					</div>
					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="1" isRequired={false} defaultValue={practice !== null ? practice.nInstallments : null} disabled={isDisable} name="nInstallments" label="Nr. Rate" register={register} onChange={setNrRate} />
					</div>

					<div className="col-md-4">
						{ /*defaultValue={practice !== null ? practice.singleInstallmentImport : null} */}
						<InputNumber validation={null} type="number" step="0.01" isRequired={false} price={true} disabled={isDisable} onChange={setSingleImportPrestito} name="singleInstallmentImport" label="Importo Singola Rata" register={register} value={parseFloat(importoPrestito / nrRate).toFixed(2)} />
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="0.01" isRequired={false} perc={true} defaultValue={practice !== null ? practice.tan : null} disabled={isDisable} name="tan" label="TAN" register={register} />
					</div>

					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="0.01" isRequired={false} perc={true} defaultValue={practice !== null ? practice.taeg : null} disabled={isDisable} name="taeg" label="TAEG" register={register} />
					</div>
				</div>


				{
					role === 'ADMIN' ?
						<>
							<hr />

							<div className="top-page">
								<h3 className="top-page-title">Impostazioni ADMIN</h3>
							</div>

							<div className="row mt-2">
								<div className="col-md-8">
									<div className="row">
										<div className="col-md-6">
											<InputSelect defaultValue={practice !== null ? practice.status : null} name="status" label="Stato Pratica" onChange={setStatusDebt} data={selectStatus} register={register} />
										</div>
										<div className="col-md-6">
											<InputDate disabled={isDisable} onChange={setDateStartTime} defaultValue={practice !== null ? practice.dateStartTime : null} name="dateStartTime" label="Data inizio timer" isRequired={false} register={register} />
										</div>
									</div>

									{practice.expired_at !== null ? <p style={{ marginBottom: 0, marginTop: 15 }}>Data Scadenza: <strong>{moment(practice.expired_at).format('DD/MM/YYYY')}</strong></p> : null}
									<br />
									<label htmlFor="legal"><input disabled={isDisable} type="checkbox" defaultChecked={practice !== null ? practice.legal : false} name="legal" id="legal" ref={register} /> Pratiche Legali?</label>
								</div>
								<div className="col-md-4">
									<label className="d-block">&nbsp;</label>
									<button type="button" className="btn btn-primary" onClick={(e) => handleResetExpire(e, dateStartTime)}>Reset Timer</button>
								</div>
							</div>

							<hr />

							{
								statusDebt === 'INVIO OFFERTA SALDOESTRALCIO' ?
									<>
										<div className="row">
											<div className="col-md-4">
												<InputNumber price={true} validation={null} type="number" step="0.01" label="Importo Offerta" name="importOfferta" defaultValue={practice !== null ? practice.importOfferta : null} register={register} />
											</div>
											<div className="col-md-4">
												<InputText label="Importo Offerta Lettere" name="importOffertaLetter" defaultValue={practice !== null ? practice.importOffertaLetter : null} register={register} />
											</div>
											<div className="col-md-3">
												<label htmlFor="" className="d-block">&nbsp;</label>
												<button type="button" className="btn btn-primary btn-block w-100" onClick={handleGenerateFileLocal}>{btnGenerationTxt}</button>
											</div>
										</div>

										<hr />
									</>
									: null
							}

							{
								statusDebt === 'ANALISI CONTRATTO' ?
									<>
										<div className="row">
											<div className="col-md-4">
												<InputText label="Nome Recuperatore del Credito" name="nomeRecCred" defaultValue={practice !== null ? practice.nomeRecCred : null} register={register} />
											</div>
											<div className="col-md-4">
												<InputText label="Numero di Telefono" name="numberPhoneCred" defaultValue={practice !== null ? practice.numberPhoneCred : null} register={register} />
											</div>
											<div className="col-md-4">
												<InputEmail label="Indirizzo Email" name="emailCred" defaultValue={practice !== null ? practice.emailCred : null} register={register} />
											</div>
										</div>

										<hr />
									</>
									: null
							}

							{
								statusDebt === 'DEBITO CHIUSO' ?
									<>
										<div className="row">
											<div className="col-md-4">
												<InputNumber price={true} validation={null} step="0.01" type="number" defaultValue={practice !== null ? practice.importClosed : 0} name="importClosed" label="Importo Chiusura" register={register} />
											</div>
											<div className="col-md-4">
												<InputDate disabled={isDisable} defaultValue={practice !== null ? practice.datePayedEnd : null} name="datePayedEnd" label="Data Pagamento" isRequired={false} register={register} />
											</div>
										</div>


										<hr />
									</>
									: null
							}

							<h5 className="fw-bold">Scadenza Pagamenti</h5>
							<div className="row">
								<div className="col-md-4">
									<InputNumber price={true} validation={null} step="0.01" type="number" onChange={setSpesaFissa} defaultValue={spesaFissa} name="spesaFissa" label="Spesa Fissa" register={register} />
								</div>
								<div className="col-md-4">
									<InputNumber validation={null} step="0.01" type="number" onChange={setPercScontoScad} defaultValue={percScontoScad} perc={true} name="percScontoScad" label="Percentuale Sconto" register={register} />
								</div>
								<div className="col-md-4">
									<label htmlFor="" className="d-block">&nbsp;</label>
									<label htmlFor="payedSpesaFissa" className="d-block"><input type="checkbox" name="payedSpesaFissa" id="payedSpesaFissa" ref={register} defaultChecked={practice !== null ? practice.payedSpesaFissa : false} /> Pagata?</label>
								</div>
							</div>
							<p className="mt-2">Importo Finale: <strong>&euro; {parseFloat(spesaFissa - ((spesaFissa * percScontoScad) / 100)).toFixed(2)}</strong></p>

							<hr />

							<h5 className="fw-bold">Importo Gestione Debitoria</h5>
							<div className="row">
								<div className="col-md-4">
									<InputNumber validation={null} step="0.01" type="number" onChange={setSconto} perc={true} defaultValue={sconto} name="sconto" label="Percentuale Sconto" register={register} />
								</div>
							</div>
							<p className="mt-2">Importo Gestione Debitoria: <strong>&euro; {parseFloat(importDebit * 0.10 * 1.22).toFixed(2)}</strong></p>
							<p className="mt-2">Importo Finale: <strong>&euro; {parseFloat((importDebit * 0.10 * 1.22) - ((importDebit * 0.10 * 1.22) * sconto) / 100).toFixed(2)}</strong></p>

							<hr />

							<h5 className="fw-bold">N. Rate (Max. 20)</h5>
							<div className="row">
								<div className="col-md-2">
									<label htmlFor={"importSingleInstallment"}>N. Rate (0 / 20)</label>
									<input className={'form-control'} value={importSingleInstallment} name={'importSingleInstallment'} id={'importSingleInstallment'} type={'number'} step={'1'} max="20" min="0" onChange={(e) => setImportSingleInstallment(e.target.value > 20 ? 20 : e.target.value)} />
								</div>
								<div className="col-md-3">
									<>
										<label htmlFor={'expireDateInstallment'}>Data Scadenza Rata Iniziale</label>
										<input className={'form-control'} value={expireDateInstallment} name={'expireDateInstallment'} id={'expireDateInstallment'} type="date" onChange={(e) => setExpireDateInstallment(e.target.value)} />
									</>
								</div>
								<div className="col-md-2">
									<label htmlFor="" className="d-block">&nbsp;</label>
									<button type="button" className="btn btn-primary btn-block w-100" onClick={handleAddInstallment}>Genera</button>
								</div>
								<div className="col-md-2">
									<label htmlFor="" className="d-block">&nbsp;</label>
									<button type="button" className="btn btn-danger btn-block w-100" onClick={handleDeleteInstallments}>Rimuovi Tutto</button>
								</div>
							</div>

							<table className="table table-striped mt-3 w-75">
								<thead>
									<tr>
										<th>Importo Singola Rata</th>
										<th>Scadenza Singola Rata</th>
										<th>N. Riga</th>
										<th></th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{
										nInstallmentsTable.map((item, index) => {
											return (
												<tr key={index}>
													<td>&euro; {parseFloat(item.importSingleInstallment).toFixed(2)}</td>
													<td>{moment(item.expireDateInstallment).format("DD/MM/YYYY")}</td>
													<td>{index + 1}</td>
													<td><input defaultChecked={item.payed} type="checkbox" name="payed" id="payed" onChange={(e) => handleChangeCheckboxPayed(e.target.checked, index)} /> Pagato?</td>
													<td><input defaultValue={item.datePayed} type="date" name="datePayed" id="datePayed" onChange={(e) => handleChangeDatePayed(e.target.value, index)} className="form-control" /></td>
												</tr>
											)
										})
									}
								</tbody>
							</table>
						</>
						: null
				}
			</StepForm>
			<hr />
			{/*<LegacyServiceReport practiceId={practice._id} practiceType="debts" ></LegacyServiceReport>
			<hr />*/}


			{stepOpened !== 0 ? <input type="button" className="btn btn-dark" value="Precedente" disabled={stepOpened < 1} onClick={() => setStepOpened(stepOpened - 1)} /> : null}
			{stepOpened !== 0 ? <input type="button" className="btn btn-primary mx-2" value="Prossimo" onClick={() => setStepOpened(stepOpened + 1)} /> : null}
			{stepOpened === 0 ? <input type="submit" className={stepOpened === 0 ? 'btn btn-primary mx-2' : 'btn btn-primary'} value="Conferma" /> : null}

		</form>
	)
}

export default FormPracticeDebts;
