import React, { useEffect, useRef, useState } from 'react';

import { useBank } from '../../../contexts/banks-context';
import FormBank from '../../../ui-components/forms-components/bank/form-bank';
import { useEthosNotification } from '../../../contexts/ethos-notification-context';

const EditBank = (props) => {
	const { id } = props.match.params;
	const { editBank, getSingleBank } = useBank();

	const componentRef = useRef()
	const [bank, setBank] = useState(null);
	const [isDisable, setIsDisable] = useState(false);
	const { handleError } = useEthosNotification();

	useEffect(() => {
		const getBank = async () => {
			const { data, error } = await getSingleBank(id);

			if (error !== null) {
				return window.location.href = '/banks';
			}

			setBank(data);
			setIsDisable(data.isDisable);
		}

		getBank();
	}, [id, getSingleBank])

	const onSubmit = async (data) => {
		const { error } = await editBank(id, data);

		if (error !== null) {
			handleError(error);
			return;
		}
		window.location.reload();
	}

	return (
		<div className="edit-Bank-page px-3 py-3" ref={componentRef}>
			<div className="top-page">
				<h3 className="top-page-title">Modifica Banca</h3>
			</div>

			<hr />

			{bank ? <FormBank isDisable={isDisable} bank={bank} handleSave={onSubmit} /> : null}
		</div>
	)
}

export default EditBank;