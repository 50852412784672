import { useEffect, useState } from 'react';
import "./ethos-stepper.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';

const EthosStepper = ({ steps = [], currentStep, setCurrentStep = () => { } }) => {
  const [orderedSteps, setOrderedSteps] = useState([]);

  useEffect(() => {
    setOrderedSteps(steps.sort((a, b) => { return a.order > b.order }));
  }, [steps]);

  const getStepClass = (step) => {
    var prefix = "";
    if (step.order === currentStep.order) {
      prefix = " current ";
    }
    if (step.stepState === "ACTIVE") {
      return prefix + "active"
    }
    if (step.stepState === "COMPLETED") {
      return prefix + "completed"
    }
    return "blocked"
  }

  const getStepIcon = (step) => {
    if (step.stepState === "ACTIVE") {
      return faUnlock;
    }
    if (step.stepState === "COMPLETED") {
      return faCheck;
    }
    return faLock;
  }

  const doSetCurrentStep = (step) => {
    if (["ACTIVE", "COMPLETED"].includes(step.stepState)) {
      setCurrentStep(step);
    }
  }


  return (
    <div className="wrapper option-1 option-1-1">
      <ol className="c-stepper">
        {orderedSteps.map(function (step, index) {
          return (
            <li key={step.key} onClick={() => { doSetCurrentStep(step) }} className={"c-stepper__item " + getStepClass(step)}>
              <span title={step.label} className={"c-stepper__circle " + getStepClass(step)}>
                <FontAwesomeIcon icon={getStepIcon(step)} className="c-stepper__circle-icon" />
              </span>
              <p className={"c-stepper__desc " + getStepClass(step)}>{step.label}</p>
            </li>
          )
        })
        }
      </ol>
      <h4 className="text-center">{currentStep.label}</h4>
    </div>
  )
}

export default EthosStepper;