import { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import PaginationBar from '../pagination-bar';
import { useConfigurations } from '../../contexts/configurations-context';
import { useEthosModal } from '../../contexts/ethos-modal-context';
import { getActivityStatusLabel, getActivityTypeLabel } from '../../utils/functions';
import { useAuthenticator } from "@aws-amplify/ui-react";

const CustomerActivities = ({ customer, activities = [], users = [], addActivity = () => { }, doUpdateActivity = () => { }, doDeleteActivity = () => { },sendResumeAndUpdate = ()=>{} }) => {
    const [visibleActivities, setVisibleActivities] = useState([]);
    const { configurations } = useConfigurations();
    const { openActivityModal, openNotesModal } = useEthosModal();
    const { user } = useAuthenticator();
    const role = useRef("CLIENTE");

    useEffect(() => {
        role.current = !!user &&
            !!user.signInUserSession &&
            !!user.signInUserSession.accessToken &&
            !!user.signInUserSession.accessToken.payload &&
            !!user.signInUserSession.accessToken.payload["cognito:groups"] &&
            !!user.signInUserSession.accessToken.payload["cognito:groups"].length
            ? user.signInUserSession.accessToken.payload["cognito:groups"][0]
            : "CLIENTE";
    }, [user]);

    const saveActivity = async (newActivity) => {
        if (newActivity._id) {
            doUpdateActivity(newActivity);
        } else {
            addActivity(newActivity);
        }
    }

    const sendAndUpdateResume = async (id,activity) =>{
        sendResumeAndUpdate(id,activity)
    }

    const getUserFullname = (id) => {
        var s = users.find(e => e._id === id);
        if (s) {
            return s.first_name + " " + s.last_name;
        }
        else {
            return "N.D."
        }
    }

    const getUsername = (id) => {
        var s = users.find(e => e._id === id);
        if (s) {
            return s.username;
        }
        else {
            return "N.D."
        }
    }


    const canDeleteActivity = (activity) => {
        if (role.current === "ADMIN") return true;
        const actUsername = getUsername(activity.createdBy);
        return actUsername === user.username;
    };

    const openActivityNotesModal = (note) => {
        openNotesModal(note, "Note dell'attività");
    }

    const deleteActivity = (actId) => {
        doDeleteActivity(actId);
    }

    const doOpenActivityModal = (act) => {
        openActivityModal(saveActivity, configurations, customer, act, customer._id, customer.assignedAt, users,sendAndUpdateResume);
    }

    return (
        <>
            <div className="row">
                <div className="col-12 text-center">
                    <div className="row mb-5 justify-content-center">
                        <div className="col-12 text-center">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th className="text-center">Tipo</th>
                                        <th className="text-center">Creata da</th>
                                        <th className="text-center">Assegnata a</th>
                                        <th className="text-center">Data</th>
                                        <th className="text-center">Luogo</th>
                                        <th className="text-center">Durata</th>
                                        <th className="text-center">Stato</th>
                                        <th className="text-center">Azioni</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        visibleActivities && visibleActivities.length ?
                                            visibleActivities.map(function (act, actIndex) {
                                                return (
                                                    <tr key={"activity_row_" + (actIndex)}>
                                                        <td className="text-center" >
                                                            {getActivityTypeLabel(act, configurations)}
                                                        </td>
                                                        <td className="text-center" >
                                                            {getUserFullname(act.createdBy)}
                                                        </td>
                                                        <td className="text-center" >
                                                            {getUserFullname(act.activityOwner)}
                                                        </td>
                                                        <td className="text-center" >
                                                            {moment(act.activityDate).format("DD/MM/yyyy HH:mm")}
                                                        </td>
                                                        <td className="text-center" >
                                                            {act.location}
                                                        </td>
                                                        <td className="text-center" >
                                                            {act.duration ? (act.duration + " minuti") : ""}
                                                        </td>
                                                        <td className="text-center" >
                                                            {getActivityStatusLabel(act, configurations)}
                                                        </td>
                                                        <td className="text-center" >
                                                            <div className="btn-group" role="group">
                                                                <button id="btnGroupDrop1" type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    Azioni
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                                    <li><button onClick={() => doOpenActivityModal(act)} className="dropdown-item">Modifica</button></li>
                                                                    <li><button onClick={() => openActivityNotesModal(act.note)} className="dropdown-item">Vedi note</button></li>
                                                                    {canDeleteActivity(act) && <li><button onClick={() => deleteActivity(act._id)} className="dropdown-item text-danger">Elimina</button></li>}
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            ) : null
                                    }
                                </tbody>
                            </table>
                            <PaginationBar fullList={activities} setVisibleList={setVisibleActivities}></PaginationBar>
                        </div>
                    </div>
                    <hr></hr>
                </div>
            </div>
        </>
    )
}

export default CustomerActivities;