import { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import ReactToPrint from 'react-to-print';
import moment from 'moment';

import FormPracticeDebts from '../../../ui-components/forms-components/practice-debts/form-practice-debts';
import { InputTextArea } from '../../../ui-components/forms';
import { usePracticesDebts } from '../../../contexts/practices-debts-context';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useComments } from '../../../contexts/comments-context';
import CustomerPdf from './customer-pdf';
import { useEthosNotification } from '../../../contexts/ethos-notification-context';

const UploadComponentDebts = ({ defaultValue, isDisable = false, handleUpload, textBtn }) => {
	return (
		<form onSubmit={handleUpload}>
			<select value={defaultValue} className="form-select mb-2" name="typefile" id="typefile">
				{defaultValue === 'mandato' ? <option value="mandato">Mandato</option> : null}
				{defaultValue === 'privacy' ? <option value="privacy">Privacy</option> : null}
				{defaultValue === 'procuraethos' ? <option value="procuraethos">Procura Ethos</option> : null}
				{defaultValue === 'procuraavv' ? <option value="procuraavv">Procura Avv.</option> : null}
				{defaultValue === 'richiesta119' ? <option value="richiesta119">Richiesta 119</option> : null}
				{defaultValue === 'richiestacrif' ? <option value="richiestacrif">Richiesta CRIF</option> : null}
				{defaultValue === 'richiestacr' ? <option value="richiestacr">Richiesta CR</option> : null}
			</select>

			{isDisable ? null : <input type="file" className="form-control mb-2" name="file" id="file" />}
			{isDisable ? null : <input type="submit" value={textBtn} className="btn btn-primary" />}

			<hr />
		</form>
	)
}

const UploadCustomComponentDebts = ({ isDisable = false, handleUpload, textBtn }) => {
	return (
		<form onSubmit={handleUpload}>
			<label htmlFor="filename">Nome File</label>
			<input type="text" className="form-control mb-2" name="filename" id="filename" />

			<input type="file" className="form-control mb-2" name="file" id="file" />
			<input type="submit" value={textBtn} className="btn btn-primary" />

			<hr />
		</form>
	)
}

const UploadCustomComponentDebtsPec = ({ isDisable = false, handleUpload, textBtn }) => {
	return (
		<form onSubmit={handleUpload}>
			<label htmlFor="filename">Nome File</label>
			<input type="text" className="form-control mb-2" name="filename" id="filename" />

			<input type="file" className="form-control mb-2" name="file" id="file" />
			<input type="submit" value={textBtn} className="btn btn-primary" />

			<hr />
		</form>
	)
}


const EditPracticeDebts = (props) => {
	const { id } = props.match.params;
	const { user } = useAuthenticator();
	const { generateFileByType, editPractice, getSinglePractice, downloadFileDebts, uploadFileDebts, uploadCustomFileDebts, deleteFileDebts, downloadCustomFileDebts, deleteCustomFileDebts, resetExpireTimer, logs, uploadCustomFileDebtsPec, downloadCustomFileDebtsPec, deleteCustomFileDebtsPec } = usePracticesDebts();
	const { getComments, newComment } = useComments();

	const componentRef = useRef()
	const [practice, setPractice] = useState(null);
	const [comments, setComments] = useState([]);
	const [commentText, setCommentText] = useState('');
	const [isDisable, setIsDisable] = useState(false);
	const [showUpload, setShowUpload] = useState(false);
	const [defaultUploadValue, setDefaultUploadValue] = useState(null);
	const [showCustomUpload, setShowCustomUpload] = useState(false);
	const [showCustomUploadPec, setShowCustomUploadPec] = useState(false);
	const [textBtn, setTextBtn] = useState("Carica");
	const { handleError, showErrorToast } = useEthosNotification();
	const role = user && user.signInUserSession && user.signInUserSession.accessToken && user.signInUserSession.accessToken.payload &&
		user.signInUserSession.accessToken.payload["cognito:groups"] && user.signInUserSession.accessToken.payload["cognito:groups"].length
		? user.signInUserSession.accessToken.payload["cognito:groups"][0] : "CLIENTE";

	useEffect(() => {
		const getPractice = async () => {
			const { data, error } = await getSinglePractice(id);
			if (error !== null) {
				handleError(error);
				return;
			}
			setPractice(data.data);
			setIsDisable(data.data.isDisable);

			// load comments
			const comments = await getComments('debts', id);

			if (comments.error !== null) {
				handleError(comments.error);
				return;
			}

			setComments(comments.data);
		}

		getPractice();
	}, [id, getComments, getSinglePractice, handleError])

	const onSubmit = async (data) => {
		const { error } = await editPractice(id, data);

		if (error !== null) {
			handleError(error);
			return;
		}

		window.location.reload();
	}

	const uploadFile = async (e) => {
		e.preventDefault();

		setTextBtn("Caricamento file...");

		const type = e.target[0].value;
		const file = e.target[1].files[0];

		if (file.size >= 4194304) {
			setTextBtn("Carica");
			return alert('Il file supera i 4MB.');
		}

		const body = {
			file: file.name
		};

		const { error } = await uploadFileDebts(id, type, body, file);
		if (error !== null) {
			setTextBtn("Carica");
			handleError(error);
			return;
		}
		window.location.reload();
	}

	const downloadFile = async (type) => {
		const { error } = await downloadFileDebts(id, type);

		if (error !== null) {
			handleError(error);
			return
		}
	}

	const deleteFile = async (type) => {
		const confirmDelete = window.confirm('Sei sicuro di voler continuare?');

		if (!confirmDelete) {
			return;
		}

		const { error } = await deleteFileDebts(id, type);
		if (error !== null) {
			handleError(error);
			return;
		}
		window.location.reload();
	}

	const uploadCustomFile = async (e) => {
		e.preventDefault();

		setTextBtn("Caricamento file...");

		const filename = e.target[0].value;
		const file = e.target[1].files[0];

		if (file.size >= 4194304) {
			setTextBtn("Carica");
			showErrorToast('Il file supera i 4MB.');
			return;
		}

		const body = {
			file: file.name
		};

		const { error } = await uploadCustomFileDebts(id, filename, body, file);
		if (error !== null) {
			setTextBtn("Carica");
			handleError(error);
			return;
		}

		window.location.reload();
	}

	const previewCustomFile = async (type, download = true) => {
		const { error } = await downloadCustomFileDebts(id, type);

		if (error !== null) {
			handleError(error);
			return;
		}
	}

	const deleteCustomFile = async (type) => {
		const confirmDelete = window.confirm('Sei sicuro di voler continuare?');

		if (!confirmDelete) {
			return;
		}

		const { error } = await deleteCustomFileDebts(id, type);
		if (error !== null) {
			handleError(error);
			return;
		}
		window.location.reload();
	}

	const uploadCustomFilePec = async (e) => {
		e.preventDefault();

		setTextBtn("Caricamento file...");

		const filename = e.target[0].value;
		const file = e.target[1].files[0];

		if (file.size >= 4194304) {
			setTextBtn("Carica");
			showErrorToast('Il file supera i 4MB.');
			return;
		}

		const body = {
			file: file.name
		};

		const { error } = await uploadCustomFileDebtsPec(id, filename, body, file);
		if (error !== null) {
			setTextBtn("Carica");
			handleError(error);
			return;
		}

		window.location.reload();
	}

	const previewCustomFilePec = async (type, download = true) => {
		const { error } = await downloadCustomFileDebtsPec(id, type);

		if (error !== null) {
			handleError(error);
			return;
		}
	}

	const deleteCustomFilePec = async (type) => {
		const confirmDelete = window.confirm('Sei sicuro di voler continuare?');

		if (!confirmDelete) {
			return;
		}

		const { error } = await deleteCustomFileDebtsPec(id, type);
		if (error !== null) {
			handleError(error);
			return;
		}
		window.location.reload();
	}

	const handleResetExpire = async (e, date) => {
		e.preventDefault();

		await resetExpireTimer(id, date);
		window.location.reload();
	}

	const handleAddComment = async (e) => {
		e.preventDefault();

		const result = await newComment('Debts', id, { comment: commentText });

		if (result.error !== null) {
			handleError(result.error);
			return;
		}

		window.location.reload();
	}

	const handleGetTotalIncomes = (customer) => {
		let total = 0;

		switch (customer.profession.type_prof) {
			case 'pensionato':
			case 'lavoratore-dipendente':
				total += (parseFloat(customer.profession.monthlyIncomeNet) * 12) + (parseFloat(customer.incomes.incomesRent) * 12) + (parseFloat(customer.incomes.incomesPension) * 12) + (parseFloat(customer.incomes.incomesOthers) * 12);
				break;
			case 'lavoratore-autonomo':
				total += parseFloat(customer.incomes.incomesRent) + (parseFloat(customer.incomes.incomesPension) * 12) + (parseFloat(customer.incomes.incomesOthers) * 12);
				break;
			case 'disoccupato':
				total += (parseFloat(customer.profession.RDC) * 12) + (parseFloat(customer.profession.othersIncome) * 12) + (parseFloat(customer.incomes.incomesRent) * 12) + (parseFloat(customer.incomes.incomesPension) * 12) + (parseFloat(customer.incomes.incomesOthers) * 12);
				break;
			default:
				total += (parseFloat(customer.incomes.incomesRent) * 12) + (parseFloat(customer.incomes.incomesPension) * 12) + (parseFloat(customer.incomes.incomesOthers) * 12);
				break;
		}

		return total;
	}

	const handleGetTotalPatrimonial = (customer) => {
		var total = 0;

		for (let i = 0; i < customer.house.items.length; i++) {
			total += parseFloat(customer.house.items[i].value)
		}

		for (let i = 0; i < customer.property.items.length; i++) {
			total += parseFloat(customer.property.items[i].value)
		}

		for (let i = 0; i < customer.stock.items.length; i++) {
			total += parseFloat(customer.stock.items[i].value)
		}
		return total;
	}

	const handleGetTotalOthersIncomes = (customer) => {
		var total = 0;

		total += parseFloat(customer.thirdForeclosureImport * 12);
		total += parseFloat(customer.spouseForeclosureImport * 12);
		total += parseFloat(customer.monthlySavingsImport * 12);
		total += parseFloat(customer.othersYearlyIncome * 12);

		for (let i = 0; i < customer.monthlyIncome.items.length; i++) {
			total += parseFloat(customer.monthlyIncome.items[i].value * 12)
		}

		return total;
	}

	const handleGenerateFile = async (generateFile) => {
		if (generateFile === null) return alert('Seleziona il modello da generare');
		const { data, error } = await generateFileByType(id, 'SALDO', generateFile);

		if (error !== null) {
			handleError(error);
			return;
		}

		const buffer = new Uint8Array(data.buffer);
		const filename = data.filename;

		const blob = new Blob([buffer], {
			type: 'application/octet-stream'
		});

		const url = window.URL.createObjectURL(blob);
		const element = document.createElement('a');

		element.href = url;
		element.download = filename;

		document.body.appendChild(element);

		element.click();
		element.remove();
	}

	return (
		<div className="edit-customer-page px-3 py-3">
			{
				practice ?
					<div className="row">
						<div className="col-md-8">
							<div className="top-page">
								<h3 className="top-page-title">Modifica Pratica</h3>
							</div>

							<hr />

							{
								practice !== null ?
									<div>
										<p style={{ marginBottom: 10 }}>Totale Posizione Reddituale Annua: <strong>&euro; {parseFloat(handleGetTotalIncomes(practice.customerdata[0])).toFixed(2)}</strong></p>
										<p style={{ marginBottom: 10 }}>Totale Posizione Patrimoniale: <strong>&euro; {parseFloat(handleGetTotalPatrimonial(practice.customerdata[0])).toFixed(2)}</strong></p>
										<p style={{ marginBottom: 10 }}>Totale Altri Impegni Annui: <strong>&euro; {parseFloat(handleGetTotalOthersIncomes(practice.customerdata[0])).toFixed(2)}</strong></p>
										<hr />
									</div>
									:
									null
							}



							<FormPracticeDebts handleGenerateFile={handleGenerateFile} handleResetExpire={handleResetExpire} role={role} isDisable={isDisable} practice={practice} handleSave={onSubmit} />
						</div>
						<div className="col-md-4">
							<div className="top-page">
								<h3 className="top-page-title">Documenti Pratica</h3>
							</div>

							<hr />

							{showUpload ? <UploadComponentDebts textBtn={textBtn} defaultValue={defaultUploadValue} isDisable={isDisable} handleUpload={uploadFile} /> : null}
							{showCustomUpload ? <UploadCustomComponentDebts textBtn={textBtn} isDisable={isDisable} handleUpload={uploadCustomFile} /> : null}
							{showCustomUploadPec ? <UploadCustomComponentDebtsPec textBtn={textBtn} isDisable={isDisable} handleUpload={uploadCustomFilePec} /> : null}

							<table className="table table-striped">
								<thead>
									<tr>
										<th>Tipo Documento</th>
										<th>Stato</th>
										<th>Azioni</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Mandato</td>
										<td>{practice.documents.mandato === null ? 'n/a' : 'ok'}</td>
										<td>
											{practice.documents.mandato === null ?
												<button disabled={isDisable} onClick={() => { setShowUpload(true); setDefaultUploadValue('mandato') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
												:
												<>
													&nbsp;<button onClick={(e) => { e.preventDefault(); downloadFile('mandato') }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
													{!isDisable ?
														<>&nbsp;<button onClick={(e) => { e.preventDefault(); deleteFile('mandato') }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button></>
														: null}
												</>
											}
										</td>
									</tr>
									<tr>
										<td>Privacy</td>
										<td>{practice.documents.privacy === null ? 'n/a' : 'ok'}</td>
										<td>
											{practice.documents.privacy === null ?
												<button disabled={isDisable} onClick={() => { setShowUpload(true); setDefaultUploadValue('privacy') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
												:
												<>
													&nbsp;<button onClick={(e) => { e.preventDefault(); downloadFile('privacy') }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
													{!isDisable ?
														<>&nbsp;<button onClick={(e) => { e.preventDefault(); deleteFile('privacy') }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button></>
														: null}
												</>
											}
										</td>
									</tr>
									<tr>
										<td>Procura Ethos</td>
										<td>{practice.documents.procuraethos === null ? 'n/a' : 'ok'}</td>
										<td>
											{practice.documents.procuraethos === null ?
												<button disabled={isDisable} onClick={() => { setShowUpload(true); setDefaultUploadValue('procuraethos') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
												:
												<>
													&nbsp;<button onClick={(e) => { e.preventDefault(); downloadFile('procuraethos') }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
													{!isDisable ?
														<>&nbsp;<button onClick={(e) => { e.preventDefault(); deleteFile('procuraethos') }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button></>
														: null}
												</>
											}
										</td>
									</tr>
									<tr>
										<td>Procura Avv.</td>
										<td>{practice.documents.procuraavv === null ? 'n/a' : 'ok'}</td>
										<td>
											{practice.documents.procuraavv === null ?
												<button disabled={isDisable} onClick={() => { setShowUpload(true); setDefaultUploadValue('procuraavv') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
												:
												<>
													&nbsp;<button onClick={(e) => { e.preventDefault(); downloadFile('procuraavv') }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
													{!isDisable ?
														<>&nbsp;<button onClick={(e) => { e.preventDefault(); deleteFile('procuraavv') }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button></>
														: null}
												</>
											}
										</td>
									</tr>
									<tr>
										<td>Richiesta 119</td>
										<td>{practice.documents.richiesta119 === null ? 'n/a' : 'ok'}</td>
										<td>
											{practice.documents.richiesta119 === null ?
												<button disabled={isDisable} onClick={() => { setShowUpload(true); setDefaultUploadValue('richiesta119') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
												:
												<>
													&nbsp;<button onClick={(e) => { e.preventDefault(); downloadFile('richiesta119') }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
													{!isDisable ?
														<>&nbsp;<button onClick={(e) => { e.preventDefault(); deleteFile('richiesta119') }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button></>
														: null}
												</>
											}
										</td>
									</tr>
									<tr>
										<td>Richiesta CRIF</td>
										<td>{practice.documents.richiestacrif === null ? 'n/a' : 'ok'}</td>
										<td>
											{practice.documents.richiestacrif === null ?
												<button disabled={isDisable} onClick={() => { setShowUpload(true); setDefaultUploadValue('richiestacrif') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
												:
												<>
													&nbsp;<button onClick={(e) => { e.preventDefault(); downloadFile('richiestacrif') }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
													{!isDisable ?
														<>&nbsp;<button onClick={(e) => { e.preventDefault(); deleteFile('richiestacrif') }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button></>
														: null}
												</>
											}
										</td>
									</tr>
									<tr>
										<td>Richiesta CR</td>
										<td>{practice.documents.richiestacr === null ? 'n/a' : 'ok'}</td>
										<td>
											{practice.documents.richiestacr === null ?
												<button disabled={isDisable} onClick={() => { setShowUpload(true); setDefaultUploadValue('richiestacr') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
												:
												<>
													&nbsp;<button onClick={(e) => { e.preventDefault(); downloadFile('richiestacr') }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
													{!isDisable ?
														<>&nbsp;<button onClick={(e) => { e.preventDefault(); deleteFile('richiestacr') }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button></>
														: null}
												</>
											}
										</td>
									</tr>
								</tbody>
							</table>

							{
								practice.othersDocuments.length > 0 ?
									<>
										<div className="top-page mt-4">
											<h3 className="top-page-title">Documenti Personalizzati Pratica</h3>
										</div>

										<hr />

										<table className="table table-striped">
											<thead>
												<tr>
													<th>Nome Documento</th>
													<th>Azioni</th>
												</tr>
											</thead>
											<tbody>
												{
													practice.othersDocuments.map((item, index) => {
														const filename = item.filename || item;
														return (
															<tr key={index}>
																<td>{String(filename).replace(`${id}-`, ``).replace(`-Custom`, ``)}</td>
																<td>
																	<button onClick={(e) => { e.preventDefault(); previewCustomFile(item, true) }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																	<button onClick={(e) => { e.preventDefault(); deleteCustomFile(item) }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
																</td>
															</tr>
														)
													})
												}
											</tbody>
										</table>
									</>
									: null
							}

							<button className="btn btn-primary mb-3" onClick={() => setShowCustomUpload(!showCustomUpload)}>Aggiungi File Personalizzato</button>

							{
								practice.pecDocuments.length > 0 ?
									<>
										<div className="top-page mt-4">
											<h3 className="top-page-title">Documenti PEC Pratica</h3>
										</div>

										<hr />

										<table className="table table-striped">
											<thead>
												<tr>
													<th>Nome Documento</th>
													<th>Azioni</th>
												</tr>
											</thead>
											<tbody>
												{
													practice.pecDocuments.map((item, index) => {
														const filename = item.filename || item;
														return (
															<tr key={index}>
																<td>{String(filename).replace(`${id}-`, ``).replace(`-Pec`, ``)}</td>
																<td>
																	<button onClick={(e) => { e.preventDefault(); previewCustomFilePec(item, true) }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																	<button onClick={(e) => { e.preventDefault(); deleteCustomFilePec(item) }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
																</td>
															</tr>
														)
													})
												}
											</tbody>
										</table>
									</>
									: null
							}

							<button className="btn btn-primary mx-1 mb-3" onClick={() => setShowCustomUploadPec(!showCustomUploadPec)}>Aggiungi File PEC</button>


							{
								practice !== null ?
									<div>
										<hr />

										<ReactToPrint
											trigger={() => (
												<button className="btn btn-primary">Genera PDF</button>
											)}
											content={() => componentRef.current}
										/>

										<div className="d-none">
											<CustomerPdf practice={practice} customer={practice.customerdata[0]} />
										</div>
									</div>
									:
									null
							}

							<hr />

							<div className="comments-area mt-4">
								<div className="top-page">
									<h3 className="top-page-title">Area Commenti</h3>
								</div>

								<hr />

								{
									comments && comments.length > 0 ?
										<>
											<div className="list-comments">
												<ul>
													{
														comments.map((item, index) => {
															return (
																<li className="comment-item" key={index}>
																	<p className="comment-author">{item.userdata[0]?.username} - <span className="comment-date">{moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}</span></p>
																	<p className="comment-text">{item.comment}</p>
																</li>
															)
														})
													}
												</ul>
											</div>

											<hr />
										</>
										: null
								}

								<div className="add-comment">
									<form onSubmit={handleAddComment}>
										<InputTextArea name="comment-text" label="Scrivi un commento:" onChange={setCommentText} />
										<input type="submit" className="btn btn-primary mt-1" value="Commenta" />
									</form>
								</div>
							</div>

							{
								role === 'ADMIN' ?
									<>
										<div className="top-page mt-4">
											<h3 className="top-page-title">Logs</h3>
										</div>

										<hr />

										<div className="wrapper-logs">

											<table className="table table-striped">
												<thead>
													<tr>
														<th>Utente</th>
														<th>Azione</th>
														<th>Data</th>
													</tr>
												</thead>
												<tbody>
													{
														logs.map((item, index) => {
															return (
																<tr key={index}>
																	<td>{item.userdata[0]?.username}</td>
																	<td>{item.description}</td>
																	<td>{moment(item.created_at).format('DD/MM/YYYY')}</td>
																</tr>
															)
														})
													}
												</tbody>
											</table>
										</div>

									</>
									: null
							}

						</div>
					</div>
					: null}
		</div >
	)
}

export default EditPracticeDebts;