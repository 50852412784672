import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Sidebar from "../sidebar/sidebar";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { GoogleProvider } from "../../contexts/google-context";
import { WrapperDashboard } from "../custom-components";

// pages
import Home from "../../pages/Home";
import Customers from "../../pages/Customers";
import Screenings from "../../pages/Screenings";
import PracticesCqs from "../../pages/PracticesCqs";
import PracticesLoans from "../../pages/PracticesLoans";
import PracticesDebts from "../../pages/PracticesDebts";
import Database from "../../pages/Database";
import Tickets from "../../pages/Tickets";
import Archives from "../../pages/Archives";
import Calendar from "../../pages/Calendar";
import Banks from "../../pages/Banks";
import ServiceScreeningDetailsPage from "../../pages/ServiceDetailsScreening";
import ServicesConfigurationPage from "../../pages/ServicesConfigurationPage";
import "./dashboard.css";
import Activities from "../../pages/Activities";
import Users from "../../pages/Users";
import DeleteReports from "../../pages/DeleteReports";
import ServiceCustomerDetailsPage from "../../pages/ServiceCustomerDetails";

const Dashboard = ({ signOut }) => {
  const { user } = useAuthenticator();
  const role =
    user &&
    user.signInUserSession &&
    user.signInUserSession.accessToken &&
    user.signInUserSession.accessToken.payload &&
    user.signInUserSession.accessToken.payload["cognito:groups"] &&
    user.signInUserSession.accessToken.payload["cognito:groups"].length
      ? user.signInUserSession.accessToken.payload["cognito:groups"][0]
      : "CLIENTE";

  /*
		Soluzione temporanea per avere un redirect automatico alla home nel cao in cui la route corrente non sia valida.
		Le varie guide suggeriscono di aggiungere alla fine dello switch un blocco di questo tipo:
		<Route path="*">
			<Redirect to="/home" />
		</Route>
		Ma per qualche motivo questa cosa non funziona! Da approfondire
	*/
  var routes = [
    "/home",
    "/customers",
    "/practices-cqs",
    "/practices-loans",
    "/practices-debts",
    "/database",
    "/tickets",
    "/archives",
    "/calendar",
    "/banks",
    "/users",
    "/service-screening",
    "/service-details",
    "/screenings",
    "/delete-report",
    "/services-configuration",
    "/activities",
  ];
  var pathValid = false;
  for (var i = 0; i < routes.length; i++) {
    if (window.location.pathname === routes[i] || window.location.pathname.startsWith(routes[i] + "/")) {
      pathValid = true;
    }
  }
  if (!pathValid) {
    window.location.pathname = "/home";
  }

  return (
    <BrowserRouter>
      <WrapperDashboard>
        <Sidebar role={role} signOut={signOut} />
        <div className="wrapper-pages">
          <GoogleProvider>
            <Switch>
              <Route path="/customers" component={Customers} />
              <Route path="/practices-cqs" component={PracticesCqs} />
              <Route path="/screenings" component={Screenings} />
              <Route path="/delete-report" component={DeleteReports} />
              <Route path="/practices-loans" component={PracticesLoans} />
              <Route path="/practices-debts" component={PracticesDebts} />
              <Route path="/database" component={Database} />
              <Route path="/tickets" component={Tickets} />
              <Route path="/archives" component={Archives} />
              <Route path="/calendar" component={Calendar} />
              <Route path="/banks" component={Banks} />
              <Route path="/users" component={Users} />
              <Route path="/service-screening" component={ServiceScreeningDetailsPage} />
              <Route path="/service-details" component={ServiceCustomerDetailsPage} />
              <Route path="/home" component={Home} />
              {role === "ADMIN" ? (
                <>
                  <Route path="/services-configuration" component={ServicesConfigurationPage} />
                  <Route path="/activities" component={Activities} />
                </>
              ) : null}
              <Route path="*">
                <Redirect to="/home" />
              </Route>
            </Switch>
          </GoogleProvider>
        </div>
      </WrapperDashboard>
    </BrowserRouter>
  );
};

export default Dashboard;
