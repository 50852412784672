import { useAuthenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";

import AuthenticatedApp from './components/authenticated-app/authenticated-app'
import UnauthenticatedApp from './components/unathenticated-app/UnauthenticatedApp'
import { useEffect, useState } from 'react';
import './app.scss';
import AppProviders from './contexts/app-providers'

import awsconfig from "./aws-config";
Amplify.configure(awsconfig);

export default function App() {
	const [logged, setLogged] = useState(false);
	const { user, signOut } = useAuthenticator();

	const doSignOut = () => {
		signOut();
		setLogged(false);
	}

	useEffect(() => {
		if (!!user && !user.challengeName) {
			setLogged(true);
		} else {
			setLogged(false);
		}
	}, [user]);

	return logged
		? <AppProviders>
			<AuthenticatedApp signOut={doSignOut} />
		</AppProviders>
		: <UnauthenticatedApp />;
}
