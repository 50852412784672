import React, { useEffect, useState } from 'react';

import { NewPageWrapper, NewPageWrapperCopy } from '../../../../../custom-components';
import { InputNumber, InputTextArea } from '../../../../../forms';

const AddMonthlyIncome = ({ setOpenAddMonthlyIncome, handleSaveItems }) => {
	const [value, setValue] = useState(0);
	const [note, setNote] = useState('');

	const handleAddItem = async () => {
		const data = {
			value,
			note
		}

		await handleSaveItems(data);
		setOpenAddMonthlyIncome(false);
	}

	return (
		<NewPageWrapper>
			<NewPageWrapperCopy>
				<div className="top-page">
					<h3 className="top-page-title">Nuovo Impegno Mensile</h3>
					<button className="close-button" onClick={() => setOpenAddMonthlyIncome(false)}>&times;</button>
				</div>

				<hr />

				<div className="row">
					<div className="col-md-3">
						<InputNumber price={true} validation={null} type="number" label="Importo" name="othersMonlthlyIncomeValue" step="0.01" onChange={setValue} />
					</div>
					<div className="col-md-3">
						<InputTextArea name="othersMonthlyIncomeNote" label="Note" onChange={setNote} />
					</div>
				</div>

				<hr />

				<input onClick={handleAddItem} type="button" value="Aggiungi" className="btn btn-primary" />

			</NewPageWrapperCopy>
		</NewPageWrapper>
	)
}

const MonthlyIncomes = ({ disabled = false, monthlyIncomeItems = [], haveMonthlyIncome = false, setHaveMonthlyIncome = () => { }, setMonthlyIncomeItems = () => { }, handleGetTotalOthersIncomes = () => { } }) => {
	const [openAddMonthlyIncome, setOpenAddMonthlyIncome] = useState(false);
	const [viewedItems, setViewdItems] = useState(null);

	const handleSaveItems = async (data) => {
		setMonthlyIncomeItems([...monthlyIncomeItems, data]);
		handleGetTotalOthersIncomes();
	}

	useEffect(() => {
		const handleRemove = (item) => {
			const index = monthlyIncomeItems.indexOf(item);
			setMonthlyIncomeItems(items => items.filter((_val, i) => i !== index));
		}

		let data = [];

		monthlyIncomeItems.map((item, i) => {
			data.push(
				<tr key={i}>
					<td>&euro; {parseFloat(item.value).toFixed(2)}</td>
					<td>{item.note}</td>
					<td><button disabled={disabled} className="close-button d-block ms-auto" type="button" onClick={() => handleRemove(item)}>Rimuovi</button></td>
				</tr>
			)

			return {}
		})

		setViewdItems(data);
	}, [monthlyIncomeItems, disabled, setMonthlyIncomeItems]);

	return (
		<>
			<div className="d-flex flex-columns align-items-center">
				<label htmlFor="MonthlyIncome" className="mb-2"><input disabled={disabled} onChange={() => setHaveMonthlyIncome(!haveMonthlyIncome)} type="checkbox" defaultChecked={haveMonthlyIncome} name="MonthlyIncome" id="MonthlyIncome" /> <span className="mx-2"> Altri Impegni Mensili?</span></label>
				{haveMonthlyIncome && monthlyIncomeItems.length < 10 && !disabled ? <button onClick={() => setOpenAddMonthlyIncome(!openAddMonthlyIncome)} type="button" className="btn btn-primary ms-auto">Aggiungi Impegno Mensile</button> : null}
			</div>

			{ openAddMonthlyIncome ? <AddMonthlyIncome setOpenAddMonthlyIncome={setOpenAddMonthlyIncome} handleSaveItems={handleSaveItems} /> : null}

			{ monthlyIncomeItems.length > 0 ?
				<div className="MonthlyIncome-items">
					<hr />

					<table className="table">
						<thead>
							<tr>
								<th>Importo</th>
								<th>Note</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{
								viewedItems
							}
						</tbody>
					</table>
				</div>
				: null}
		</>
	)
}

export default MonthlyIncomes;