import React from 'react'
import { Auth } from 'aws-amplify';
import { httpPost, httpGet } from '../http'

const CommentsContext = React.createContext()

function CommentsProvider(props) {
	const getComments = async (practiceUri = 'cqs', id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${practiceUri}/comments/${id}`, jwtToken, {});

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const newComment = async (practiceUri = 'cqs', id, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPost(`${practiceUri}/comments/${id}`, jwtToken, data);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	return (
		<CommentsContext.Provider
			value={{
				getComments,
				newComment,
			}}
			{...props}
		/>
	)
}

const useComments = () => React.useContext(CommentsContext)

export { CommentsProvider, useComments }