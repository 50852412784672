import moment from "moment";

export const DeleteReportName = {
  SIC: "CancellazioneSegnalazioniSic",
  PROTESTI: "CancellazioneSegnalazioniProtesti",
};

export const renderPaginator = (length, limit, edit, save, currentOffset) => {
  let items = [];
  // let x = Math.ceil(length / limit);

  items.push(
    <li key={"prev_page"} className="page-item">
      <button
        className="page-link"
        onClick={() => {
          if (currentOffset > 0) edit(currentOffset - limit);
        }}
        aria-label="Previous"
      >
        <span aria-hidden="true">&laquo;</span>
      </button>
    </li>
  );
  if (length <= limit) {
    items.push(
      <li key={"page_1"} className="page-item">
        <button onClick={() => {}} className="page-link">
          1
        </button>
      </li>
    );
  } else {
    let dotsPrinted = false;
    var numPages = Math.ceil(length / limit);
    var currentPage = Math.round(currentOffset / limit);
    for (let i = 0; i < numPages; i++) {
      if (
        i === 0 || //first
        (currentPage - 3 < i && currentPage + 3 > i) ||
        (numPages / 2 - 4 < i && numPages / 2 + 4 > i) ||
        i === numPages - 1 //last
      ) {
        dotsPrinted = false;
        items.push(
          <li key={i} className="page-item">
            <button onClick={() => edit(i * limit)} className={"page-link " + (i === currentPage ? "btn-primary" : "btn-outline-secondary")}>
              {i + 1}
            </button>
          </li>
        );
      } else if (!dotsPrinted) {
        dotsPrinted = true;
        items.push(
          <li key={i} className="page-item px-4">
            ...
          </li>
        );
      }
    }
  }

  items.push(
    <li key={"next_page"} className="page-item">
      <button
        className="page-link"
        onClick={() => {
          if (currentOffset + limit < length) edit(currentOffset + limit);
        }}
        aria-label="Next"
      >
        <span aria-hidden="true">&raquo;</span>
      </button>
    </li>
  );
  save(items);
};

export const isProspectWorked = (activities) => {
  if (!activities || activities.length === 0) {
    return false;
  }
  const types = ["PHONE CALL", "VIDEO CALL", "APPOINTMENT"];

  return activities.some((a) => {
    return (
      a.activityStatus === "PLANNED" &&
      types.indexOf(a.activityType) > -1 &&
      !!a.activityDate &&
      new Date(a.activityDate).getTime() > new Date().getTime()
    );
  });
};

export const getServiceCustomerName = (services, serviceCustomer) => {
  var s = services.find((e) => e._id === serviceCustomer.serviceId);
  if (s) {
    return `${s.displayName} creato il ${new Date(serviceCustomer.createdAt).toLocaleDateString()} alle ${new Date(
      serviceCustomer.createdAt
    ).toLocaleTimeString()}`;
  } else {
    return "Servizio #" + serviceCustomer.serviceId;
  }
};

export const isServiceScreening = (services, serviceCustomer) => {
  if (services && serviceCustomer) {
    const findName = services.find((e) => {
      return e._id === serviceCustomer.serviceId;
    }).internalName;
    return findName === "Screening";
  }
};

export const isServiceDeleteReport = (services, serviceCustomer) => {
  const internal = services.find((e) => e._id === serviceCustomer.serviceId).internalName;
  return internal === DeleteReportName.SIC || internal === DeleteReportName.PROTESTI;
};

export const isExpired = (date) => {
  const today = moment().startOf("day");
  if (date === "N.D." || date === null) return "";
  const parsedDate = moment(date, "DD/MM/YYYY");
  return parsedDate.isBefore(today) ? "expired" : "";
};

export const startDownload = (data, customFileName) => {
  const buffer = new Uint8Array(data.buffer.Body.data);
  const filename = data.filename;
  const blob = new Blob([buffer], {
    type: data.buffer.ContentType,
  });

  const url = window.URL.createObjectURL(blob);
  const element = document.createElement("a");
  element.href = url;
  if (customFileName && customFileName !== "") {
    element.download = customFileName;
  } else {
    element.download = filename;
  }

  document.body.appendChild(element);

  element.click();
  element.remove();
};

export const getRandomString = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const getActivityStatusLabel = (activity, configurations) => {
  if (configurations) {
    var conf = configurations["activityStatus"];
    var obj = conf.find((x) => x.value === activity.activityStatus);
    if (obj) {
      return obj.label;
    }
  }
  return activity.activityStatus;
};

export const getActivityTypeLabel = (activity, configurations) => {
  if (configurations) {
    var ret = "";
    var conf = configurations["typeActivity"];
    var obj = conf.find((x) => x.value === activity.activityType);
    if (obj) {
      ret = obj.label;
      if (activity.callOutcome) {
        ret = ret + " (" + activity.callOutcome + ")";
      }
      return ret;
    }
  }
  return activity.activityType;
};

export const buildAvailableTemplatesList = (configurations, service, templatesInfo) => {
  if (service && service.templates && templatesInfo && configurations && configurations.typeTemplate && configurations.typeTemplate.length) {
    var lista = [];
    for (var i = 0; i < configurations.typeTemplate.length; i++) {
      const obj = {};
      obj.templateType = configurations.typeTemplate[i]?.value || null;
      obj.templateTypeLabel = configurations.typeTemplate[i].label;
      obj.template = service.templates.find((x) => x.type === obj.templateType && x.status === "ACTIVE");
      if (obj.template) {
        obj.id = obj.template.id;
        var templateInfo = templatesInfo.find((x) => x._id === obj.template.id);
        if (templateInfo) {
          obj.filename = templateInfo.filename;
          obj.updatedAt = templateInfo.updatedAt;
          obj.expirationDate = templateInfo.expirationDate;
          lista.push(obj);
        }
      }
    }
    return lista;
  }
  return null;
};

export const isTemplate = (type, configurations) => {
  if (configurations) {
    return configurations.typeTemplate.some((x) => x.value === type);
  }

  return false;
};

export const getFrequencyBetweenDate = (date2, date1) => {
  return Math.floor(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24));
};
