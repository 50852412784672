import { useEffect, useState, useRef } from 'react';

import House from './house-section';
import Property from './property-section';
import Stocks from './stock-section';

import { InputNumber, InputText, InputTextArea } from '../../../../forms';

const PatrimonialSection = ({ dataToSubmit = {}, setDataToSubmit = () => { }, customer = null, isDisable = false }) => {
	const [house, setHouse] = useState(customer !== null ? customer.house.haveHouse : false);
	const [houseItems, setHouseItems] = useState(customer !== null ? customer.house.items : []);
	const [property, setProperty] = useState(customer !== null ? customer.property.haveProperty : false);
	const [propertyItems, setPropertyItems] = useState(customer !== null ? customer.property.items : []);
	const [stock, setStock] = useState(customer !== null ? customer.stock.haveStock : false);
	const [stockItems, setStockItems] = useState(customer !== null ? customer.stock.items : []);
	const [society, setSociety] = useState(customer !== null ? customer.society.haveSociety : false);
	const [societyRole, setSocietyRole] = useState(customer !== null ? customer.society.societyRole : '');
	const [societyName, setSocietyName] = useState(customer !== null ? customer.society.societyName : '');
	const [societyPiva, setSocietyPiva] = useState(customer !== null ? customer.society.societyPiva : '');
	const [societyPerc, setSocietyPerc] = useState(customer !== null ? customer.society.societyPerc : 0);
	const [notesPatri, setNotesPatri] = useState(customer !== null ? customer.notesPatri : '');
	const didMount = useRef(false);

	useEffect(() => {
		didMount.current = false;
	}, [customer]);

	useEffect(() => {
		if (!didMount.current) {
			return didMount.current = true;
		}
		const data = {
			...dataToSubmit,
			house: {
				haveHouse: house,
				items: houseItems
			},
			property: {
				haveProperty: property,
				items: propertyItems
			},
			stock: {
				haveStock: stock,
				items: stockItems
			},
			society: {
				haveSociety: society,
				societyRole: societyRole,
				societyName: societyName,
				societyPiva: societyPiva,
				societyPerc: societyPerc
			},
			notesPatri: notesPatri
		};
		setDataToSubmit(data);

		// eslint-disable-next-line
	}, [house, houseItems, property, propertyItems, stock, stockItems, society, societyRole, societyName, societyPiva, societyPerc, notesPatri]);

	const handleGetTotalPatrimonial = () => {
		var total = 0;

		for (let i = 0; i < houseItems.length; i++) {
			const item = houseItems[i];
			total += parseFloat(item.value);
		}

		for (let i = 0; i < propertyItems.length; i++) {
			const item = propertyItems[i];
			total += parseFloat(item.value);
		}

		for (let i = 0; i < stockItems.length; i++) {
			const item = stockItems[i];
			total += parseFloat(item.value);
		}
		return total;
	}

	return (
		<div className="step-patrimonial">
			<House
				disabled={isDisable}
				setHaveHouse={setHouse}
				setHouseItems={setHouseItems}
				haveHouse={house}
				houseItems={houseItems}
				handleGetTotalPatrimonial={handleGetTotalPatrimonial} />

			<hr />

			<Property
				disabled={isDisable}
				setHaveProperty={setProperty}
				setPropertyItems={setPropertyItems}
				haveProperty={property}
				propertyItems={propertyItems}
				handleGetTotalPatrimonial={handleGetTotalPatrimonial} />

			<hr />

			<Stocks
				disabled={isDisable}
				setHaveStock={setStock}
				setStockItems={setStockItems}
				haveStock={stock}
				stockItems={stockItems}
				handleGetTotalPatrimonial={handleGetTotalPatrimonial} />

			<hr />

			<div className="society">
				<label htmlFor="society" className="mb-2"><input disabled={isDisable} onChange={() => setSociety(!society)} type="checkbox" defaultChecked={society} name="society" id="society" /> <span className="mx-2"> Partecipazioni Societarie?</span></label>
				<br />
				{society ?
					<>
						<div className="row">
							<div className="col-md-3">
								<InputText label="Ruolo" defaultValue={societyRole} name="societyRole" onChange={setSocietyRole} />
							</div>
							<div className="col-md-3">
								<InputText label="Denominazione Società" defaultValue={societyName} name="societyName" onChange={setSocietyName} />
							</div>
							<div className="col-md-3">
								<InputText label="P. Iva" defaultValue={societyPiva} name="societyPiva" onChange={setSocietyPiva} />
							</div>
							<div className="col-md-3">
								<InputNumber label="Percentuale" step="0.01" validation={null} type="number" defaultValue={societyPerc} name="societyPerc" onChange={setSocietyPerc} />
							</div>
						</div>
					</>
					: null}
			</div>

			<hr />

			<InputTextArea defaultValue={notesPatri} name="notesPatri" label="Note" onChange={setNotesPatri} />

			<hr />

			<p style={{ fontSize: 18, fontWeight: 'bold' }}>Totale Posizione Patrimoniale: &euro; {parseFloat(handleGetTotalPatrimonial()).toFixed(2)}</p>
		</div>
	)
}

export default PatrimonialSection;