import React from 'react';

const NotesModalContent = ({ confirm = () => {}, closeFunction = () => {}, customParams={message:null}}) => {
    return(
        <>
            <div className="modal-body">
                <div className="text-center">
                    <p style={{fontStyle: 'italic'}}className="memo-content text-center">"{customParams.message ? customParams.message : ""}"</p>
                </div>
            </div>
            <div className="modal-footer">
                <button onClick={closeFunction} type="button" className="btn btn-primary" data-bs-dismiss="modal">CHIUDI</button>
            </div>
        </>
    )
}

export default NotesModalContent;