import React, { useState, useEffect } from 'react';
import { InputTextArea } from '../../forms';

const LostReasonModalContent = ({ confirm = () => { }, closeFunction = () => { }, customParams = {} }) => {
    const [canConfirm, setCanConfirm] = useState(false);
    const [lostReason, setLostReason] = useState('');

    useEffect(() => {
        if (!!lostReason) {
            setCanConfirm(true);
        } else {
            setCanConfirm(false);
        }
    }, [lostReason]);


    const doConfirm = () => {
        if (!canConfirm) {
            return;
        }
        confirm(lostReason);
        closeFunction();
    }

    return (
        <>
            <div className="modal-body">
                <InputTextArea onChange={setLostReason} defaultValue={''} name="lost_reason" label="Motivazione" />
            </div>
            <div className="modal-footer">
                <button onClick={closeFunction} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annulla</button>
                <button disabled={!canConfirm} onClick={() => doConfirm()} data-bs-dismiss="modal" type="button" className="btn btn-primary">Salva</button>
            </div>
        </>
    )
}

export default LostReasonModalContent;