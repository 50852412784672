import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { InputText, InputTextArea, InputSelect } from '../../forms';
import { useUser } from '../../../contexts/user-context';
import { useTickets } from '../../../contexts/tickets-context';

const FormTicket = ({ handleSave }) => {
	const { register, handleSubmit } = useForm({});
	const { getUsersPractice } = useUser();
	const { getAllPractices } = useTickets();

	const [users, setUsers] = useState(null);
	const [typePractice, setTypePractice] = useState(null);
	const [cqs, setCqs] = useState(null);
	const [loan, setLoan] = useState(null);
	const [debt, setDebt] = useState(null);

	useEffect(() => {
		const getUsers = async () => {
			const { data } = await getUsersPractice('AGENTE-ADMIN');

			const dataToObj = data.map((item, index) => {
				return {
					index: index,
					value: item._id,
					label: item.username
				}
			});

			setUsers(dataToObj);
		}

		const getPractices = async () => {
			const { data } = await getAllPractices();

			const dataToObjCqs = data.cqsPractices.map((item, index) => {
				const value = `${item.nContract} - ${String(item.customerdata[0].first_name).toUpperCase()} ${String(item.customerdata[0].last_name).toUpperCase()}`;
				return {
					index: index,
					value: value,
					label: value
				}
			});

			const dataToObjLoans = data.loanPractices.map((item, index) => {
				const value = `${item.nContract} - ${String(item.customerdata[0].first_name).toUpperCase()} ${String(item.customerdata[0].last_name).toUpperCase()}`;
				return {
					index: index,
					value: value,
					label: value
				}
			});

			const dataToObjDebts = data.debtPractices.map((item, index) => {
				const value = `${item.nContract} - ${String(item.customerdata[0].first_name).toUpperCase()} ${String(item.customerdata[0].last_name).toUpperCase()}`;
				return {
					index: index,
					value: value,
					label: value
				}
			});

			setCqs(dataToObjCqs);
			setLoan(dataToObjLoans);
			setDebt(dataToObjDebts);
		}

		getUsers();
		getPractices();
	}, [getAllPractices, getUsersPractice])

	const onSubmit = async (data) => {
		await handleSave(data);
	}

	const typePracticeSelect = [
		{ value: 'CQS', label: 'CQS' },
		{ value: 'LOAN', label: 'PRESTITO PERSONALE' },
		{ value: 'DEBT', label: 'GESTIONE DEBITO' },
	]

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{
				users !== null ?
					<InputSelect label="Utente" name="connectedId" className="mb-2" register={register} data={users} /> : null
			}
			<InputSelect label="Tipo Pratica" name="type" className="mb-2" register={register} onChange={setTypePractice} data={typePracticeSelect} />

			{typePractice === 'CQS' && cqs !== null ? <InputSelect label="Pratica" name="practice" className="mb-2" register={register} data={cqs} /> : null}
			{typePractice === 'LOAN' && loan !== null ? <InputSelect label="Pratica" name="practice" className="mb-2" register={register} data={loan} /> : null}
			{typePractice === 'DEBT' && debt !== null ? <InputSelect label="Pratica" name="practice" className="mb-2" register={register} data={debt} /> : null}

			<InputText label="Titolo Ticket" name="title" className="mb-2" register={register} />
			<InputTextArea label="Descrizione Ticket" name="description" register={register} />

			<hr />

			<input type="submit" className={'btn btn-primary'} value="Conferma" />
		</form>
	)
}

export default FormTicket;