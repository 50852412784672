import React from 'react';
import moment from 'moment';

import EthosLogo from '../../../media/logo-ethos-pdf.jpg';
import NoteImage from './foglionote.png';
import EndPdf from './end-pdf.png';

const CustomerPdf = ({ practice = null, customer = null, refComponentPdf }) => {
	const handleGetTotalIncomes = () => {
		let total = 0;

		switch (customer.profession.type_prof) {
			case 'pensionato':
			case 'lavoratore-dipendente':
				total += (parseFloat(customer.profession.monthlyIncomeNet) * 12) + (parseFloat(customer.incomes.incomesRent) * 12) + (parseFloat(customer.incomes.incomesPension) * 12) + (parseFloat(customer.incomes.incomesOthers) * 12);
				break;
			case 'lavoratore-autonomo':
				total += parseFloat(customer.incomes.incomesRent) + (parseFloat(customer.incomes.incomesPension) * 12) + (parseFloat(customer.incomes.incomesOthers) * 12);
				break;
			case 'disoccupato':
				total += (parseFloat(customer.profession.RDC) * 12) + (parseFloat(customer.profession.othersIncome) * 12) + (parseFloat(customer.incomes.incomesRent) * 12) + (parseFloat(customer.incomes.incomesPension) * 12) + (parseFloat(customer.incomes.incomesOthers) * 12);
				break;
			default:
				total += (parseFloat(customer.incomes.incomesRent) * 12) + (parseFloat(customer.incomes.incomesPension) * 12) + (parseFloat(customer.incomes.incomesOthers) * 12);
				break;
		}

		return total;
	}

	const handleGetTotalPatrimonial = () => {
		var total = 0;

		for (let i = 0; i < customer.house.items.length; i++) {
			total += parseFloat(customer.house.items[i].value)
		}

		for (let i = 0; i < customer.property.items.length; i++) {
			total += parseFloat(customer.property.items[i].value)
		}

		for (let i = 0; i < customer.stock.items.length; i++) {
			total += parseFloat(customer.stock.items[i].value)
		}

		console.log(total);

		return total;
	}

	const handleGetTotalOthersIncomes = () => {
		var total = 0;

		total += parseFloat(customer.thirdForeclosureImport * 12);
		total += parseFloat(customer.spouseForeclosureImport * 12);
		total += parseFloat(customer.monthlySavingsImport * 12);
		total += parseFloat(customer.othersYearlyIncome * 12);

		for (let i = 0; i < customer.monthlyIncome.items.length; i++) {
			total += parseFloat(customer.monthlyIncome.items[i].value * 12)
		}

		return total;
	}

	const number_format = (number, decimals, dec_point, thousands_sep) => {
		// Strip all characters but numerical ones.
		number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
		var n = !isFinite(+number) ? 0 : +number,
			prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
			sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
			dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
			s = '',
			toFixedFix = function (n, prec) {
				var k = Math.pow(10, prec);
				return '' + Math.round(n * k) / k;
			};
		// Fix for IE parseFloat(0.55).toFixed(0) = 0;
		s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
		if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
		}
		if ((s[1] || '').length < prec) {
			s[1] = s[1] || '';
			s[1] += new Array(prec - s[1].length + 1).join('0');
		}
		return s.join(dec);
	}

	return (
		<div ref={refComponentPdf} style={{ width: '75%', margin: '20px auto' }}>
			<div style={{
				width: 256,
				height: 256,
				margin: '50px auto'
			}}>
				<img src={EthosLogo} alt="Logo" style={{ width: '100%', height: '100%' }} />
			</div>

			<div style={{ textAlign: 'center', marginBottom: 40 }}>
				<p style={{ marginBottom: 0, textTransform: 'uppercase' }}>{`${customer.first_name} ${customer.last_name}`}</p>
				<p style={{ marginBottom: 0, textTransform: 'uppercase' }}>{`${customer.cf}`}</p>
				<p style={{ marginBottom: 0, textTransform: 'uppercase' }}>{`${customer.address}, ${customer.postcode} (${customer.prov})`}</p>
			</div>

			<div style={{ marginBottom: 40 }}>
				{
					practice !== null ?
						<p style={{ marginBottom: 10 }}>Totale Esposizione Debitoria: <strong>&euro; {number_format(parseFloat(practice.importDebit), 2, ',', '.')}</strong></p>
						: null
				}
				<p style={{ marginBottom: 10 }}>Totale Posizione Reddituale Annua: <strong>&euro; {number_format(parseFloat(handleGetTotalIncomes()), 2, ',', '.')}</strong></p>
				<p style={{ marginBottom: 10 }}>Totale Posizione Patrimoniale: <strong>&euro; {number_format(parseFloat(handleGetTotalPatrimonial()), 2, ',', '.')}</strong></p>
				<p style={{ marginBottom: 10 }}>Totale Altri Impegni Annui: <strong>&euro; {number_format(parseFloat(handleGetTotalOthersIncomes()), 2, ',', '.')}</strong></p>
			</div>

			<div style={{ marginBottom: 40 }}>
				<p style={{ marginBottom: 10 }}>Data la presente situazione, gli obiettivi da raggiungere sono:</p>
				<p style={{ marginBottom: 10 }}><input type="checkbox" /> Recuperare gli importi dagli istituti</p>
				<p style={{ marginBottom: 10 }}><input type="checkbox" /> Risolvere le problematiche legate ai rapporti finanziari</p>
				<p style={{ marginBottom: 10 }}><input type="checkbox" /> Non perdere la casa</p>
				<p style={{ marginBottom: 10 }}><input type="checkbox" /> Chiudere i propri debiti al minor importo possibile</p>
				<p style={{ marginBottom: 10 }}><input type="checkbox" /> Ampliare la propria consapevolezza finanziaria</p>
				<p style={{ marginBottom: 10 }}><input type="checkbox" /> Collaborare con Ethos</p>
			</div>

			<div style={{ marginBottom: 20 }}>
				<p style={{ marginBottom: 10 }}>Data</p>
				<p style={{ marginBottom: 10 }}>{moment().format('DD/MM/YYYY')}</p>
			</div>

			<div style={{ float: 'right', textAlign: 'right' }}>
				<p style={{ fontStyle: 'italic', marginBottom: 5, fontSize: 12 }}>ethos@pec.postaimprese.it</p>
				<p style={{ fontStyle: 'italic', marginBottom: 5, fontSize: 12 }}>Tel: +39 327 8664756</p>
				<p style={{ fontStyle: 'italic', marginBottom: 5, fontSize: 12 }}>www.ethoscoop.it</p>
			</div>

			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />

			<img src={NoteImage} alt="imae note" style={{ width: '100%', height: '1000px' }} />

			<br />
			<br />
			<br />
			<br />

			<div style={{
				width: 128,
				height: 128,
				margin: '50px auto'
			}}>
				<img src={EthosLogo} alt="Logo" style={{ width: '100%', height: '100%' }} />
			</div>

			<p style={{ fontSize: 22 }}>Beneficiario: <strong>Ethos Soc. Coop. a R.L.</strong></p>
			<p style={{ fontSize: 22 }}>IBAN: <strong>IT 48 I 02008 04610 000105533818</strong></p>
			<p style={{ fontSize: 22 }}>Banca: <strong>Unicredit S.p.A.</strong></p>

			<p style={{ fontSize: 22 }}>Filiale n.10 - Palermo - Via Libertà, 46</p>

			<p style={{ fontSize: 22 }}>Causale: <strong>Gestione debitoria {String(customer.first_name).toUpperCase()} {String(customer.last_name).toUpperCase()}</strong></p>

			<center style={{ margin: '20px 0' }}>
				<p style={{ fontSize: 22 }}><strong>Importo gestione debitoria &euro; {number_format((practice.importDebit * 0.10 * 1.22) - ((practice.importDebit * 0.10 * 1.22) * practice.sconto) / 100, 2, ',', '.')}</strong></p>
			</center>
			<table className="table table-striped mt-1 w-100">
				<thead style={{ border: '0' }}>
					<tr>
						<th></th>
						<th>IMPORTO RATA IVA INCLUSA</th>
						<th>DATA SCADENZA PAGAMENTI</th>
					</tr>
				</thead>
				<tbody>
					{
						practice !== null ?
							practice.nInstallmentsTable.length > 0 ?
								<tr>
									<td>0</td>
									<td>&euro; {number_format(practice.spesaFissa - ((practice.spesaFissa * practice.percScontoScad) / 100), 2, ',', '.')}</td>
									<td>{moment(practice.nInstallmentsTable[0].expireDateInstallment).format("DD/MM/YYYY")}</td>
								</tr>
								: null
							: null}
					{
						practice !== null ?
							practice.nInstallmentsTable.map((item, index) => {
								return (
									<tr key={index}>
										<td>{index + 1}</td>
										<td>&euro; {number_format(parseFloat(item.importSingleInstallment), 2, ',', '.')}</td>
										<td>{moment(item.expireDateInstallment).format("DD/MM/YYYY")}</td>
									</tr>
								)
							})
							: null
					}
					{/* {
						practice !== null ?
							lastResult().map((item, index) => {
								return (
									<tr key={index}>
										<td>{item+1}</td>
										<td></td>
										<td></td>
									</tr>
								)
							})
						: null
					} */}
				</tbody>
			</table>

			<img src={EndPdf} alt="imae note" style={{ marginTop: 50, width: '100%' }} />

			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />

			<p style={{ margin: '0 0', fontSize: 10, textAlign: 'center' }}>Ethos Soc. Coop. A R.L. – Palermo 90141 - Via Notarbartolo, 23 – P.IVA: 06735340827 – <a href="mailto:ethos@pec.postaimpresa.it">ethos@pec.postaimpresa.it</a> –</p>
			<p style={{ margin: '0 0', fontSize: 10, textAlign: 'center' }}><a href="mailto:amministrazione@ethoscoop.it">amministrazione@ethoscoop.it</a> tel 095 434439 int.2</p>

		</div>
	)
}

export default CustomerPdf;