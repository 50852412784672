
import moment from 'moment';
import { useEthosModal } from '../../contexts/ethos-modal-context';
import { useEthosNotification } from '../../contexts/ethos-notification-context';
import { useConfigurations } from '../../contexts/configurations-context';
import { useCustomer } from '../../contexts/customer-context';
import { useFiles } from '../../contexts/files-context';
import { useCallback, useEffect, useState } from 'react';

const CustomerDocuments = ({ customerId }) => {

    const { openUploadFileModal, openChooseNewFileModeModal, openEthosConfirmModal } = useEthosModal();
    const { uploadFileCustomer, getCustomerFiles } = useCustomer();
    const { showErrorToast, showSuccessToast, handleError } = useEthosNotification();
    const { configurations } = useConfigurations();
    const { downloadFile, deleteFile } = useFiles();
    const [customerFiles, setCustomerFiles] = useState([]);

    const loadDocuments = useCallback(async (customerId) => {
        const { data, error } = await getCustomerFiles(customerId);
        if (error) {
            handleError(error);
            return;
        }
        setCustomerFiles(data);
    }, [getCustomerFiles, handleError])


    useEffect(() => {
        if (!!customerId) {
            loadDocuments(customerId);
        }
    }, [customerId, loadDocuments]);


    const doUploadAndAssociateFile = async (file, filename, expirationDate, type) => {
        var { error } = await uploadFileCustomer(customerId, type, file, filename, expirationDate);
        if (error) {
            handleError(error);
            return;
        }
        showSuccessToast("Documento caricato!");
        loadDocuments(customerId);
    }


    const doOpenUploadFileModal = (type) => {
        var callback = (file, filename, expirationDate) => {
            doUploadAndAssociateFile(file, filename, expirationDate, type);
        }
        openUploadFileModal(null, true, true, callback, null);
    }

    const doOpenAddServiceCustomerFileModal = () => {
        const addFileCallback = (type) => {
            doOpenUploadFileModal(type);
        }
        openChooseNewFileModeModal(configurations["typeFile"], addFileCallback, null, false);
    }

    const getFileTypeLabel = (docType) => {
        if (!!configurations && !!configurations.typeFile && !!configurations.typeFile.length > 0) {
            const item = configurations.typeFile.find(i => i.value === docType);
            if (!!item) {
                return item.label;
            }
        }
        return docType;
    }

    const doDeleteFile = async (fileId) => {
        const deleteFileCallback = async (f) => {
            const { error } = await deleteFile(f);
            if (error !== null) {
                showErrorToast("Errore durante il download del file!");
                return;
            }
            showSuccessToast("Documento eliminato");
            loadDocuments(customerId);
        }
        openEthosConfirmModal("Sicuro di voler eliminare il file?", deleteFileCallback, fileId, "Conferma eliminazione");
    }

    const doDownloadFile = async (fileId) => {
        const { error } = await downloadFile(fileId);
        if (error !== null) {
            showErrorToast("Errore durante il download del file!");
            return;
        }
        // startDownload(data);
    };


    let content;
    if (!!customerFiles && customerFiles.length > 0) {
        content = <table className="table table-striped">
            <thead>
                <tr>
                    <th className="text-center">Tipo documento</th>
                    <th className="text-center">Nome file</th>
                    <th className="text-center">Data modifica</th>
                    <th className="text-center">Data scadenza</th>
                    <th className="text-center">Azioni</th>
                </tr>
            </thead>
            <tbody>
                {!!customerFiles && !!customerFiles.length &&
                    customerFiles.map(function (doc, docIndex) {
                        return (
                            <tr key={"documents_row_" + (docIndex)}>
                                <td className="text-center" >
                                    {getFileTypeLabel(doc.fileType)}
                                </td>
                                <td className="text-center" >
                                    {doc.filename}
                                </td>
                                <td className="text-center" >
                                    {!!doc.updatedAt ? moment(doc.updatedAt).format("DD/MM/yyyy HH:mm") : '-'}
                                </td>
                                <td className="text-center" >
                                    {!!doc.expirationDate ? moment(doc.expirationDate).format("DD/MM/yyyy HH:mm") : '-'}
                                </td>
                                <td className="text-center" >
                                    <div className="btn-group">
                                        <button onClick={() => doDownloadFile(doc._id)} type="button" className="btn btn-primary">Download</button>
                                        <button onClick={() => doDeleteFile(doc._id)} type="button" className="btn btn-danger">Elimina</button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>;
    } else {
        content = <div className="text-center mt-5">
            <h6>Nessun documento</h6>
        </div>;
    }

    return (
        <div className="row">
            <div className="col-12">
                <button onClick={() => doOpenAddServiceCustomerFileModal()} className="btn btn-primary w-100">
                    Aggiungi documento
                </button>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-12 mt-2">
                        {content}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerDocuments;