import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { useCustomer } from '../../contexts/customer-context';

import './database-app.scss';
import { useEthosNotification } from '../../contexts/ethos-notification-context';

const DatabaseAppList = ({ customers = [] }) => {
	return (
		<>
			{
				customers.map((item, index) => {
					return (
						<NavLink className="database-item" to={`/database/${item._id}`} key={index}>
							<div className="database-item-data">
								{`ID: ${item._id} | ${item.first_name} ${item.last_name} | Email: ${item.email} | Telefono: ${item.phone}`}
							</div>
						</NavLink>
					)
				})
			}
		</>
	)
}

const DatabaseApp = () => {
	const { getCustomers } = useCustomer();

	const [customers, setCustomers] = useState(null);
	const { handleError } = useEthosNotification();

	useEffect(() => {
		const getCustomersData = async () => {
			const { data, error } = await getCustomers();

			if (error != null) {
				handleError(error);
				return;
			}

			setCustomers(data.data);
		}

		getCustomersData();
	}, [getCustomers, handleError]);

	return (
		<div className="database-section">
			<div className="top-page">
				<h3 className="top-page-title">Database | Lista Clienti</h3>
			</div>

			<hr />

			<div className="database-list">
				{
					customers == null ? <p>Caricamento risorse in corso...</p> : <DatabaseAppList customers={customers} />
				}
			</div>
		</div>
	)
}

export default DatabaseApp;