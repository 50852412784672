import { useEffect, useState, useRef } from 'react';
import { InputNumber, InputTextArea } from '../../../../forms';
import { BottomPreview } from '../../../../custom-components';

const IncomesSection = ({ dataToSubmit = {}, setDataToSubmit = () => { }, customer = null, isDisable = false, handleUpdate, professionType = undefined, monthlyIncomeNet = undefined, RDC = undefined, othersIncome = undefined }) => {
	const [incomes, setIncomes] = useState(customer !== null ? customer.incomes.incomes : false);
	const [incomesRent, setIncomesRent] = useState(customer !== null ? customer.incomes.incomesRent : 0);
	const [incomesPension, setIncomesPension] = useState(customer !== null ? customer.incomes.incomesPension : 0);
	const [incomesOthers, setIncomesOthers] = useState(customer !== null ? customer.incomes.incomesOthers : 0);
	const [incomesNote, setIncomesNote] = useState(customer !== null ? customer.incomes.incomesNote : undefined);
	const didMount = useRef(false);

	useEffect(() => {
		didMount.current = false;
	}, [customer]);

	useEffect(() => {
		if (!didMount.current) {
			return didMount.current = true;
		}
		const data = {
			...dataToSubmit,
			incomes: {
				incomes: incomes,
				incomesRent: incomesRent,
				incomesPension: incomesPension,
				incomesOthers: incomesOthers,
				incomesNote: incomesNote
			},
		}
		setDataToSubmit(data);

		// eslint-disable-next-line
	}, [incomes, incomesRent, incomesPension, incomesOthers, incomesNote]);

	const handleGetTotalIncomes = () => {
		let total = 0;

		switch (professionType) {
			case 'pensionato':
			case 'lavoratore-dipendente':
				total += (parseFloat(monthlyIncomeNet) * 12) + (parseFloat(incomesRent) * 12) + (parseFloat(incomesPension) * 12) + (parseFloat(incomesOthers) * 12);
				break;
			case 'lavoratore-autonomo':
				total += parseFloat(incomesRent) + (parseFloat(incomesPension) * 12) + (parseFloat(incomesOthers) * 12);
				break;
			case 'disoccupato':
				total += (parseFloat(RDC) * 12) + (parseFloat(othersIncome) * 12) + (parseFloat(incomesRent) * 12) + (parseFloat(incomesPension) * 12) + (parseFloat(incomesOthers) * 12);
				break;
			default:
				total += (parseFloat(incomesRent) * 12) + (parseFloat(incomesPension) * 12) + (parseFloat(incomesOthers) * 12);
				break;
		}

		return total;
	}

	return (
		<div className="step-incomes">
			<div className="incomes">
				<label htmlFor="incomes" className="mb-2"><input disabled={isDisable} onChange={() => setIncomes(!incomes)} type="checkbox" defaultChecked={incomes} name="incomes" id="incomes" /> <span className="mx-2"> Altri Redditi?</span></label>
				<br />
				{incomes ?
					<>
						<div className="row">
							<div className="col-md-4">
								<InputNumber price={true} validation={null} defaultValue={incomesRent} type="number" step="0.01" label="Affitti Attivi" name="incomesRent" onChange={setIncomesRent} />
								<BottomPreview>Anno: &euro; {parseFloat(incomesRent * 12).toFixed(2)}</BottomPreview>
							</div>
							<div className="col-md-4">
								<InputNumber price={true} validation={null} defaultValue={incomesPension} type="number" step="0.01" label="Pensioni Integrative" name="incomesPension" onChange={setIncomesPension} />
								<BottomPreview>Anno: &euro; {parseFloat(incomesPension * 12).toFixed(2)}</BottomPreview>
							</div>
							<div className="col-md-4">
								<InputNumber price={true} validation={null} defaultValue={incomesOthers} type="number" step="0.01" label="Altre Rendite" name="incomesOthers" onChange={setIncomesOthers} />
								<BottomPreview>Anno: &euro; {parseFloat(incomesOthers * 12).toFixed(2)}</BottomPreview>
							</div>
							<div className="col-md-12 mt-2">
								<InputTextArea defaultValue={incomesNote} name="incomesNote" label="Note" onChange={setIncomesNote} />
							</div>
						</div>
					</>
					: null}
			</div>

			<hr />

			<p style={{ fontSize: 18, fontWeight: 'bold' }}>Totale Posizione Reddituale Annua: &euro; {parseFloat(handleGetTotalIncomes()).toFixed(2)}</p>

		</div>
	)
}

export default IncomesSection;