import { useEffect, useState } from 'react';
import "./customer-status-stepper.css";
import { useEthosModal } from '../../contexts/ethos-modal-context';

const CustomerStatusStepper = ({ customer = null, serviceCustomerList = [], activities = [], setNewState = () => { } }) => {
    const [canPromoteToCustomer, setCanPromoteToCustomer] = useState(false);
    const [canPromoteToLead, setCanPromoteToLead] = useState(false);
    const [canSetToLost, setCanSetToLost] = useState(false);
    const [canSetToDiscarded, setCanSetToDiscarded] = useState(false);
    const { openLostReasonModal } = useEthosModal();

    useEffect(() => {
        if (!customer) {
            setCanPromoteToCustomer(false);
            setCanSetToLost(false)
            setCanSetToDiscarded(false);
            setCanPromoteToLead(false);
            return;
        }
        switch (customer.status) {
            case "LEAD":
                setCanPromoteToCustomer(serviceCustomerList.filter(sc => sc.arrayTranche.filter(t => t.paid).length > 0).length > 0);
                setCanSetToLost(serviceCustomerList.filter(sc => sc.arrayTranche.filter(t => t.paid).length > 0).length === 0)
                setCanSetToDiscarded(false);
                setCanPromoteToLead(false);
                break;
            case "PROSPECT":
                setCanPromoteToCustomer(false);
                setCanSetToLost(false)
                setCanSetToDiscarded(true);
                setCanPromoteToLead(true);
                break;
            default:
                setCanPromoteToCustomer(false);
                setCanSetToLost(false)
                setCanSetToDiscarded(false);
                setCanPromoteToLead(false);
                break;
        }
    }, [customer, activities, serviceCustomerList]);

    const promoteToCustomer = () => {
        if (canPromoteToCustomer) {
            setNewState("CUSTOMER", null);
        }
    }
    const promoteToLead = () => {
        if (canPromoteToLead) {
            setNewState("LEAD", null);
        }
    }

    const setToDiscarded = () => {
        if (!canSetToDiscarded) {
            return;
        }

        const setToDiscardedCallback = async (reason) => {
            setNewState("DISCARDED", reason);
        }
        openLostReasonModal(null, setToDiscardedCallback);
    }

    const setToLost = () => {
        if (!canSetToLost) {
            return;
        }

        const setToLostCallback = async (reason) => {
            setNewState("LOST", reason);
        }
        openLostReasonModal(null, setToLostCallback);
    }

    const order = {
        "PROSPECT": 0,
        "DISCARDED": 1,
        "LEAD": 2,
        "LOST": 3,
        "CUSTOMER": 3,
        "ARCHIVED": 3,
        "CLOSED": 4
    }

    const getClassName = (status, currentStatus) => {
        if (status === currentStatus) {
            return "badge bg-primary";
        } else if (order[status] < order[currentStatus]) {
            return "badge bg-success";
        } else {
            return "badge bg-dark low-opacity";
        }
    }

    const isVisible = (status, currentStatus) => {
        if (status === currentStatus) {
            return true;
        }
        if (order[status] < order[currentStatus]) {
            return !["LOST", "ARCHIVED", "DISCARDED"].includes(status);
        }
        if (order[status] > order[currentStatus]) {
            return !["LOST", "ARCHIVED", "DISCARDED"].includes(status) &&
                !["LOST", "ARCHIVED", "DISCARDED"].includes(currentStatus);
        }
        return false;
    }

    return (
        <>
            <div className="d-flex flex-row justify-content-between align-items-center bg-light p-1">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb m-0">
                            {isVisible('PROSPECT', customer.status) && <span className="breadcrumb-item">
                                <span className={getClassName('PROSPECT', customer.status)}>PROSPECT</span>
                            </span>}
                            {isVisible('DISCARDED', customer.status) && <span className="breadcrumb-item">
                                <span className={getClassName('DISCARDED', customer.status)}>DISCARDED</span>
                            </span>}
                            {isVisible('LEAD', customer.status) && <span className="breadcrumb-item">
                                <span className={getClassName('LEAD', customer.status)}>LEAD</span>
                            </span>}
                            {isVisible('LOST', customer.status) && <span className="breadcrumb-item">
                                <span className={getClassName('LOST', customer.status)}>LOST</span>
                            </span>}
                            {isVisible('CUSTOMER', customer.status) && <span className="breadcrumb-item">
                                <span className={getClassName('CUSTOMER', customer.status)}>CUSTOMER</span>
                            </span>}
                            {isVisible('ARCHIVED', customer.status) && <span className="breadcrumb-item">
                                <span className={getClassName('ARCHIVED', customer.status)}>ARCHIVED</span>
                            </span>}
                            {isVisible('CLOSED', customer.status) && <span className="breadcrumb-item">
                                <span className={getClassName('CLOSED', customer.status)}>CLOSED</span>
                            </span>}
                        </ol>
                    </nav>
                </nav>
                <div>
                    {(canPromoteToCustomer || canPromoteToLead || canSetToLost || canSetToDiscarded) && <span className='p-2'>Cambia stato</span>}
                    <div className="btn-group pl-2" role="group" aria-label="Basic example">
                        {canPromoteToCustomer && <button type="button" onClick={() => promoteToCustomer()} className="btn btn-primary">CUSTOMER</button>}
                        {canPromoteToLead && <button type="button" onClick={() => promoteToLead()} className="btn btn-primary">LEAD</button>}
                        {canSetToLost && <button type="button" onClick={() => setToLost()} className="btn btn-primary">LOST</button>}
                        {canSetToDiscarded && <button type="button" onClick={() => setToDiscarded()} className="btn btn-primary">DISCARDED</button>}
                    </div>
                </div>
            </div >
        </>
    )
}

export default CustomerStatusStepper;