import { useEffect, useState } from "react";
import { useEthosModal } from "../../../../../contexts/ethos-modal-context";

const DocumentStepElement = ({
  readOnly = false,
  keyName,
  fileTypes,
  requiredDocs,
  customerDocuments,
  serviceFiles = [],
  uploadAndAssociateFile,
  associateFiles,
  downloadFile,
  doRemoveFileFromService,
  docOption = null,
  customerScreeningInfo = null,
}) => {
  const [internalList, setInternallList] = useState([]);
  const [fullServiceDocumentsList, setFullServiceDocumentsList] = useState([]);
  const { openUploadFileModal, openDocumentListModal, openChooseNewFileModeModal } = useEthosModal();

  const doRemoveFileFromServiceInner = (file) => {
    doRemoveFileFromService(file._id);
  };

  const doDownloadFile = (file) => {
    downloadFile(file._id);
  };
  useEffect(() => {
    var lista = [];
    var fullLista = [];
    for (var i = 0; i < fileTypes.length; i++) {
      const item = fileTypes[i];
      var obj = {};
      obj.type = item.value;
      obj.typeLabel = item.label;
      obj.required = requiredDocs.includes(item.value);
      obj.associatedDocs = customerDocuments.filter(
        (x) => x.fileType === item.value && serviceFiles && serviceFiles.map((x) => x.id).includes(x._id)
      );
      lista.push(obj);
      fullLista = fullLista.concat(obj.associatedDocs);
    }

    setFullServiceDocumentsList(fullLista);
    setInternallList(lista);
  }, [serviceFiles, customerDocuments, requiredDocs, fileTypes]);

  const doOpenUploadFileModal = (type) => {
    var callback = (file, filename, expirationDate, selectedCustomer) => {
      uploadAndAssociateFile(file, filename, expirationDate, type, selectedCustomer);
    };

    openUploadFileModal(null, true, true, callback, null, !!docOption ? docOption : null);
  };

  const doOpenSelectCustomerFilesModal = (type) => {
    var callback = (files) => {
      associateFiles(files, type);
    };
    var visibleDocuments = customerDocuments.filter((x) => {
      return !serviceFiles || !serviceFiles.map((y) => y.id).includes(x._id);
    });
    openDocumentListModal(
      visibleDocuments,
      type,
      false,
      !readOnly,
      callback,
      doDownloadFile,
      null,
      null,
      null,
      false,
      null,
      null,
      customerScreeningInfo
    );
  };

  const doOpenUploadedFilesModal = (type) => {
    openDocumentListModal(
      fullServiceDocumentsList,
      type,
      !readOnly,
      false,
      null,
      doDownloadFile,
      doRemoveFileFromServiceInner,
      null,
      "Sicuro di voler rimuovere il documento dal servizio?",
      false,
      null,
      null,
      customerScreeningInfo
    );
  };

  const doOpenAddServiceCustomerFileModal = () => {
    const addFileCallback = (type, isNew) => {
      if (isNew) {
        doOpenUploadFileModal(type);
      } else {
        doOpenSelectCustomerFilesModal(type);
      }
    };

    openChooseNewFileModeModal(fileTypes, addFileCallback, null);
  };

  return (
    <>
      <div className="row">
        {
          <>
            <div className="col-8 offset-2">
              <table className="table table-striped">
                <tbody>
                  {internalList.map(function (el, tIndex) {
                    return el.associatedDocs.length || el.required ? (
                      <tr key={keyName + tIndex}>
                        <td>
                          {el.typeLabel} ({el.associatedDocs.length}) {el.required && <span className="fw-bold text-danger">*</span>}
                        </td>
                        <td style={{ width: "40%" }}>
                          <div className="btn-group w-100" role="group">
                            {!readOnly && (
                              <div className="btn-group" role="group">
                                <button
                                  id="btnGroupDrop1"
                                  type="button"
                                  className="btn btn-secondary dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  Aggiungi
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                  <li>
                                    <button onClick={() => doOpenUploadFileModal(el.type)} className="dropdown-item">
                                      Carica
                                    </button>
                                  </li>
                                  <li>
                                    <button onClick={() => doOpenSelectCustomerFilesModal(el.type)} className="dropdown-item">
                                      {customerScreeningInfo && customerScreeningInfo.length > 1
                                        ? "Scegli da archivio clienti"
                                        : "Scegli da archivio cliente"}
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            )}
                            {el.associatedDocs.length ? (
                              <button onClick={() => doOpenUploadedFilesModal(el.type)} className="btn btn-sm btn-primary">
                                Vedi documenti
                              </button>
                            ) : (
                              <button disabled className="btn btn-sm btn-primary">
                                Vedi documenti
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ) : null;
                  })}
                </tbody>
              </table>
            </div>
            {!readOnly && (
              <div className="col-4 offset-4">
                <button onClick={() => doOpenAddServiceCustomerFileModal()} className="btn btn-primary w-100">
                  Aggiungi documento
                </button>
              </div>
            )}
          </>
        }
      </div>
    </>
  );
};

export default DocumentStepElement;
