import React, { useCallback } from 'react'
import { Auth } from 'aws-amplify';
import { httpPost, httpDelete } from '../http'
import { MEMO_ENDPOINT } from '../constants/API_ENDPOINT'
import { useEthosNotification } from './ethos-notification-context';

const MemosContext = React.createContext()

function MemosProvider(props) {
	const { registerPendingRequest, unregisterPendingRequest } = useEthosNotification();

	const newMemo = useCallback(async (data) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPost(`${MEMO_ENDPOINT}`, jwtToken, data);
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const deleteMemo = useCallback(async (id) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDelete(`${MEMO_ENDPOINT}`, jwtToken, id);
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	return (
		<MemosContext.Provider
			value={{
				newMemo,
				deleteMemo
			}}
			{...props}
		/>
	)
}
const useMemos = () => React.useContext(MemosContext)

export { MemosProvider, useMemos }