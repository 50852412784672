const ShowUploadProspectsResultModal = ({ confirm = () => { }, closeFunction = () => { }, customParams = { data: {} } }) => {

    // const handleUploadProspectsResult = (data) => {

    //     // if (error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.length) {
    //     //     message = "";
    //     //     for (var i = 0; i < error.response.data.errors.length; i++) {
    //     //         var err = error.response.data.errors[i];
    //     //         message = message + "Errore alla riga #" + err.row + "\n";
    //     //         if (err.result && err.result.description && err.result.description != "") {
    //     //             message = message + err.result.description;
    //     //         }
    //     //         if (err.result.exposeMeta.validationError && err.result.exposeMeta.validationError.errors && err.result.exposeMeta.validationError.errors.length) {
    //     //             message = message + ":";
    //     //             for (var i = 0; i < err.result.exposeMeta.validationError.errors.length; i++) {
    //     //                 message = message + "\n" + err.result.exposeMeta.validationError.errors[i];
    //     //             }
    //     //         }
    //     //         toast.error(message, {
    //     //             position: "top-center",
    //     //             autoClose: 5000,
    //     //             hideProgressBar: true,
    //     //             closeOnClick: true,
    //     //             pauseOnHover: false,
    //     //             draggable: true,
    //     //             theme: "colored",
    //     //         });
    //     //     }
    //     //     if (error.response.data.success && error.response.data.success.length) {
    //     //         message = "Righe inserite correttamente:";
    //     //         for (var j = 0; j < error.response.data.success.length; j++) {
    //     //             message = message + "\n#" + error.response.data.success[j].row + ": " + error.response.data.success[j].result.first_name + " " + error.response.data.success[j].result.last_name;
    //     //         }
    //     //         showSuccessToast(message);
    //     //     }
    //     // }
    //     

    //     var message = `Caricamento completato.
    //     Elementi processati: ${getCount(data.errors) + getCount(data.success) + getCount(data.skipped)}
    //     Elementi con errore: ${getCount(data.errors)}
    //     Elementi skippati: ${getCount(data.skipped)}
    //     Elementi importati: ${getCount(data.success)}
    //     `;


    //     toast(message, {
    //         position: "top-center",
    //         autoClose: 5000,
    //         hideProgressBar: true,
    //         closeOnClick: true,
    //         pauseOnHover: false,
    //         draggable: true,
    //         theme: "colored",
    //     });
    // }

    const getCount = (items) => {
        if (!!items) {
            return items.length || 0;
        }
        return 0;
    }

    return (
        <>
            <div className="modal-body">
                <p>
                    <strong>Totale elementi processati:</strong> {getCount(customParams.data.success) + getCount(customParams.data.skipped) + getCount(customParams.data.errors)}<br />
                    <strong>Elementi importati con successo:</strong> {getCount(customParams.data.success)}<br />
                    <strong>Elementi ignorati:</strong> {getCount(customParams.data.skipped)}<br />
                    <strong>Elementi con errore:</strong> {getCount(customParams.data.errors)}
                </p>
                <div style={{ overflowX: 'hidden', maxHeight: 250 }}>
                    {!!customParams.data.errors.length && customParams.data.errors.map((er, i) => { return (<p key={i}>Errore in riga {er.row}: {er.result}</p>) })}
                    {!!customParams.data.skipped.length && customParams.data.skipped.map((er, i) => { return (<p key={i}>Ignorata riga {er.row}: {er.result}</p>) })}
                </div>
            </div >
            <div className="modal-footer">
                <button onClick={closeFunction} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
            </div>
        </>
    )
}

export default ShowUploadProspectsResultModal;