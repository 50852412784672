import React from 'react';
import "./ethos-loader.css";
import Spinner from 'react-bootstrap/Spinner';

const EthosLoader = () => {
    return(
        <div className="ethos-loader-bg">
            <Spinner className="ethos-loader-spinner" animation="border" variant="primary" />
        </div>
    )
}

export default EthosLoader;