import { Switch, Route } from 'react-router-dom';
import ListScreenings from '../components/screenings/list-screenings';
import { CustomerProvider } from '../contexts/customer-context';
import { BanksProvider } from '../contexts/banks-context';
import { CommentsProvider } from '../contexts/comments-context';
import { ArchivesProvider } from '../contexts/archives-context';
import { UserProvider } from '../contexts/user-context';
import { ServiceCustomerProvider } from '../contexts/service-customer-context';
import { ConfigurationsContextProvider } from '../contexts/configurations-context';

const Screenings = ({ match }) => {
	return (
		<div className="screenings-page">
			<CustomerProvider>
				<CommentsProvider>
					<BanksProvider>
						<ArchivesProvider>
							<UserProvider>
								<ServiceCustomerProvider>
									<ConfigurationsContextProvider>
										<Switch>
											<Route exact path={`${match.path}`} component={ListScreenings} />
										</Switch>
									</ConfigurationsContextProvider>
								</ServiceCustomerProvider>
							</UserProvider>
						</ArchivesProvider>
					</BanksProvider>
				</CommentsProvider>
			</CustomerProvider>
		</div>
	)
}

export default Screenings;