import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortNumericUp } from '@fortawesome/free-solid-svg-icons';

export const InputText = ({ style = {}, className = '', disabled = false, defaultValue = undefined, name, label, placeholder = '', register = () => { }, isRequired = false, validation = null, onChange = null, type = 'text', externalSetError = (callbackSetError) => { } }) => {
	const [error, setError] = useState(false);
	const [value, setValue] = useState(defaultValue || '');

	useEffect(() => {
		externalSetError(error);
	}, [error, externalSetError]);

	const handleValidation = (e) => {
		let value = String(e.target.value);

		setValue(value);

		if (onChange !== null) {
			onChange(e.target.value);
		}

		if (validation === null) {
			return
		}

		if (value.match(validation) || value.length < 1) {
			setError(false);
			return;
		}

		setError(true);
	}

	useEffect(() => {
		let tmp = defaultValue;
		if (tmp === null || tmp === undefined) {
			tmp = '';
		}
		setValue(tmp);
	}, [defaultValue]);


	return (
		<>
			<label htmlFor={name}>{label} {isRequired ? <span className="required">*</span> : null}</label>
			<input style={style} value={value} disabled={disabled} className={error ? 'form-control form-error-input ' + className : 'form-control ' + className} name={name} id={name} ref={register({ required: isRequired })} placeholder={placeholder} type={type} onChange={handleValidation} />
		</>
	)
};

export const InputEmail = ({ externalSetError = (callbackSetError) => { }, style = {}, disabled = false, defaultValue = undefined, name, label, placeholder = '', register = () => { }, isRequired = false, validation = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i, onChange = null, hideLabel = false }) => {
	const [error, setError] = useState(false);

	useEffect(() => {
		externalSetError(error);
	}, [error, externalSetError]);

	const handleValidation = (e) => {
		let value = String(e.target.value);

		if (onChange !== null) {
			onChange(e.target.value);
		}

		if (validation === null) {
			return;
		}

		if (value.match(validation) || value.length < 1) {
			setError(false);
			return;
		}

		setError(true);
	}

	return (
		<>
			{hideLabel === true ? null : (<label htmlFor={name}>{label} {isRequired ? <span className="required">*</span> : null}</label>)}
			<input style={style} defaultValue={defaultValue} disabled={disabled} className={error ? 'form-control form-error-input' : 'form-control'} name={name} id={name} ref={register({ required: isRequired })} placeholder={placeholder} type="email" onChange={handleValidation} />
		</>
	)
};

export const InputNumber = ({ externalSetError = (callbackSetError) => { }, price = false, perc = false, style = {}, disabled = false, value = undefined, defaultValue = undefined, name, step = '1', type = 'text', label, placeholder = '', register = () => { }, isRequired = false, validation = null, onChange = null, min = 0, count = false }) => {
	const [error, setError] = useState(false);
	const [innerValidation, setInnerValidation] = useState(null);

	useEffect(() => {
		externalSetError(error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);

	const handleValidation = (e) => {
		let value = String(e.target.value);

		if (onChange !== null) {
			onChange(e.target.value);
		}

		if (innerValidation === null) {
			return;
		}

		if (value.match(innerValidation) || value.length < 1) {
			setError(false);
			return;
		}

		setError(true);
	}

	useEffect(() => {
		if (validation != null) {
			setInnerValidation(validation);
		} else {
			if (price || count) {
				setInnerValidation(/^\d+([,.]\d{1,2})?$/);
			} else {
				setInnerValidation(/^[0-9]+$/);
			}
		}
	}, [validation, price, count]);

	var inpuElement = null;
	if (!perc && !price && !count) {
		inpuElement = <>
			{!!label && <label htmlFor={name}>{label} {isRequired && <span className="required">*</span>}</label>}
			<input style={style} defaultValue={defaultValue} disabled={disabled} className={error ? 'form-control form-error-input' : 'form-control'} name={name} id={name} ref={register({ required: isRequired })} placeholder={placeholder} type={type} step={type !== 'number' ? null : step} onChange={handleValidation} />
		</>;
	} else if (perc && !price) {
		inpuElement = <>
			{!!label && <label htmlFor={name}>{label} {isRequired && <span className="required">*</span>}</label>}
			<input style={style} defaultValue={defaultValue} disabled={disabled} className={error ? 'form-control form-error-input' : 'form-control'} name={name} id={name} ref={register({ required: isRequired })} placeholder={placeholder} type={type} step={type !== 'number' ? null : step} onChange={handleValidation} />
		</>;

	} else if (!perc && !price && count) {
		inpuElement = <>
			{!!label && <label htmlFor={name}>{label} {isRequired && <span className="required">*</span>}</label>}
			<div className="input-group flex-nowrap">
				<span className="input-group-text"><FontAwesomeIcon icon={faSortNumericUp} /></span>
				<input style={style} defaultValue={defaultValue} value={value} disabled={disabled} className={error ? 'form-control form-error-input' : 'form-control'} name={name} id={name} ref={register({ required: isRequired })} placeholder={placeholder} type={type} step={type !== 'number' ? null : step} min={min} onChange={handleValidation} />
			</div>
		</>
	}
	else {
		inpuElement = <>
			{!!label && <label htmlFor={name}>{label} {isRequired && <span className="required">*</span>}</label>}
			<div className="input-group flex-nowrap">
				<span className="input-group-text">&euro;</span>
				<input style={style} defaultValue={defaultValue} value={value} disabled={disabled} className={error ? 'form-control form-error-input' : 'form-control'} name={name} id={name} ref={register({ required: isRequired })} placeholder={placeholder} type={type} step={type !== 'number' ? null : step} min={min} onChange={handleValidation} />
			</div>
		</>
	}

	return inpuElement;
};

export const InputTextArea = ({
	externalSetError = (callbackSetError) => { },
	style = {},
	className = '',
	disabled = false,
	defaultValue = undefined,
	name,
	label,
	description = '',
	placeholder = '',
	register = () => { },
	isRequired = false,
	validation = null,
	onChange = null
}) => {
	const [error, setError] = useState(false);

	useEffect(() => {
		externalSetError(error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);

	const handleValidation = (e) => {
		let value = String(e.target.value);

		if (onChange !== null) {
			onChange(e.target.value);
		}

		if (validation === null) {
			return;
		}

		if (value.match(validation) || value.length < 1) {
			setError(false);
			return;
		}

		setError(true);
	}

	return (
		<>
			{!!label && <label htmlFor={name}>{label} {isRequired && <span className="required">*</span>}</label>}
			{!!description && <p style={{ margin: 0, padding: 0, fontSize: '90%' }}>{description}</p>}
			<textarea style={style} defaultValue={defaultValue} disabled={disabled} className={error ? 'form-control form-error-input ' + className : 'form-control ' + className} name={name} id={name} ref={register({ required: isRequired })} placeholder={placeholder} onChange={handleValidation}></textarea>
		</>
	)
};


export const InputDate = ({ style = {}, disabled = false, defaultValue = undefined, name, label, placeholder = '', register = () => { }, isRequired = false, onChange = null, readOnly = false }) => {

	const [value, setValue] = useState(defaultValue || '');

	const handleValidation = (e) => {
		if (onChange !== null) {
			onChange(e.target.value);
		}
		setValue(e.target.value);
	}

	useEffect(() => {
		let tmp = defaultValue;
		if (tmp !== null) {
			setValue(tmp);
		}
	}, [defaultValue]);

	const formattedValue = useCallback(() => {
		if (!!value) {
			return moment(value).format('YYYY-MM-DD');
		} else {
			return "";
		}
	}, [value]);

	return (
		<>
			{!!label && <label htmlFor={name}>{label} {isRequired && <span className="required">*</span>}</label>}
			<input style={style} value={formattedValue()} disabled={disabled} className={'form-control'} name={name} id={name} placeholder={placeholder} type="date" ref={register({ required: isRequired })} onChange={handleValidation} readOnly={readOnly} />
		</>
	)
};


export const InputHour = ({ validation = /^\d{2}[:]\d{2}$/, externalSetError = () => { }, style = {}, disabled = false, defaultValue = undefined, name, label, placeholder = '', register = () => { }, value = undefined, isRequired = false, onChange = null }) => {

	const [error, setError] = useState(false);

	useEffect(() => {
		externalSetError(error);
	}, [error, externalSetError]);

	const handleValidation = (e) => {
		let value = String(e.target.value);

		if (onChange !== null) {
			onChange(e.target.value);
		}

		if (validation === null) {
			return;
		}

		if (value.match(validation) || value.length < 1) {
			setError(false);
			return;
		}

		setError(true);
	}


	return (
		<>
			<label htmlFor={name}>{label} {isRequired ? <span className="required">*</span> : null}</label>
			<input style={style} value={defaultValue} disabled={disabled} className={'form-control'} name={name} id={name} placeholder={placeholder} type="time" ref={register({ required: isRequired })} onChange={handleValidation} />
		</>
	)
};

export const InputSelect = ({
	style = {},
	className = '',
	disabled = false,
	defaultValue = undefined,
	name,
	label,
	placeholder = 'Seleziona un\'opzione',
	register = () => { },
	isRequired = false,
	data = [],
	onChange = null
}) => {
	const [value, setValue] = useState(defaultValue || '');

	const handleValidation = (e) => {
		setValue(e.target.value);
		if (onChange !== null) {
			onChange(e.target.value === '' ? undefined : e.target.value);
		}
	};

	useEffect(() => {
		let tmp = defaultValue;
		if (tmp === null) {
			tmp = '';
		}
		setValue(tmp);
	}, [name, defaultValue]);

	return (
		<>
			{!!label && <label htmlFor={name}>{label} {isRequired && <span className="required">*</span>}</label>}
			<select style={style} value={value} disabled={disabled} name={name} id={name} ref={register({ required: isRequired })} className={'form-select ' + className} onChange={handleValidation}>
				<option value="">{placeholder}</option>
				{
					data.map((item, index) => {
						return <option value={item.value} key={index}>{item.label}</option>
					})
				}
			</select>
		</>
	)
};