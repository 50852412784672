import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { useFiles } from '../../contexts/files-context';
import { useEthosNotification } from '../../contexts/ethos-notification-context'

const ArchivesApp = (props) => {
	const [allFiles, setAllFiles] = useState(null);
	const { handleError, showErrorToast } = useEthosNotification();
	const { downloadFile, getFilesInfo } = useFiles();

	useEffect(() => {
		const loadFiles = async () => {
			const { data, error } = await getFilesInfo("DOCUMENTS");
			if (error !== null) {
				handleError(error);
				return;
			}
			setAllFiles(data.data);
		}

		loadFiles();
	}, [getFilesInfo, handleError]);

	const downloadFileArchive = async (file) => {
		const { error } = await downloadFile(file._id);
		if (error !== null) {
			showErrorToast("Errore durante il download del file!");
			return;
		}
		// startDownload(data, file.filename);
	}

	return (
		<div className="archive-main">
			<div className="top-bar d-flex align-items-center top-page">
				<h3 className="top-page-title">Archivio</h3>
			</div>
			<hr />
			<table className="table table-striped">
				<thead className="thead-dark">
					<tr>
						<th>Nome File</th>
						<th>Ultima Modifica</th>
					</tr>
				</thead>
				<tbody>

					{
						allFiles !== null ?
							allFiles.map((item, index) => {
								return (
									<tr key={index}>
										<td><FontAwesomeIcon icon={faFile} style={{ marginRight: 10 }} /> <button className="fw-bold" onClick={() => downloadFileArchive(item)}>{item.filename}</button></td>
										<td>{moment(item.updatedAt).format('DD/MM/YYYY hh:mm:ss')}</td>
									</tr>
								)
							})
							: null
					}
				</tbody>
			</table>
		</div>
	)
}

export default ArchivesApp;