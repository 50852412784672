import React from 'react';
import { Switch, Route } from 'react-router-dom';

import DatabaseApp from '../components/database-app';
import DatabaseAppPractices from '../components/database-app/list-practices';

import { CustomerProvider } from '../contexts/customer-context';
import { PracticesCqsProvider } from '../contexts/practices-cqs-context';
import { PracticesDebtsProvider } from '../contexts/practices-debts-context';
import { PracticesLoansProvider } from '../contexts/practices-loans-context';

const Customers = ({ match }) => {
	return (
		<div className="customers-page">
			<CustomerProvider>
				<PracticesCqsProvider>
					<PracticesDebtsProvider>
						<PracticesLoansProvider>
							<Switch>
								<Route exact path={`${match.path}`} component={DatabaseApp} />
								<Route exact path={`${match.path}/:id`} component={DatabaseAppPractices} />
							</Switch>
						</PracticesLoansProvider>
					</PracticesDebtsProvider>
				</PracticesCqsProvider>
			</CustomerProvider>
		</div>
	)
}

export default Customers;