import React from 'react'
import { Auth } from 'aws-amplify';
import { httpDownload, httpGet, httpDelete, httpUpload } from '../http';
import { ARCHIVES_ENDPOINT } from '../constants/API_ENDPOINT';
import { useEthosNotification } from './ethos-notification-context';

const ArchivesContext = React.createContext()

function ArchivesProvider(props) {
	const { registerPendingRequest, unregisterPendingRequest } = useEthosNotification();

	const getObjects = async (path) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${ARCHIVES_ENDPOINT}/list`, jwtToken, { path });

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}

	const uploadFile = async (data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpUpload(`${ARCHIVES_ENDPOINT}/upload`, jwtToken, data);

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}

	const downloadFile = async (path) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDownload(`${ARCHIVES_ENDPOINT}/download/url`, jwtToken, { path });

			const link = document.createElement('a');
			link.href = result.data.url;
			link.setAttribute('download', `${result.data.filename}`);
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}

	const deleteFile = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDelete(`${ARCHIVES_ENDPOINT}/delete`, jwtToken, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}


	return (
		<ArchivesContext.Provider
			value={{
				getObjects,
				uploadFile,
				downloadFile,
				deleteFile
			}}
			{...props}
		/>
	)
}

const useArchives = () => React.useContext(ArchivesContext)

export { ArchivesProvider, useArchives }