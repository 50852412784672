import React, { useCallback } from 'react'

import { Auth } from 'aws-amplify';
import { httpPost, httpDelete, httpPatch, httpGet } from '../http'
import { USERS_ENDPOINT, ACTIVITIES_ENDPOINT } from '../constants/API_ENDPOINT'
import { useEthosNotification } from './ethos-notification-context';

const UserContext = React.createContext()

function UserProvider(props) {
	const { registerPendingRequest, unregisterPendingRequest } = useEthosNotification();

	const getUsers = useCallback(async (search = null, filter = null, limit = null, offset = 0) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${USERS_ENDPOINT}	`, jwtToken, {
				filter: filter,
				search: search,
				limit: limit,
				offset: offset
			});

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getUsersPractice = useCallback(async (filter = null) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${USERS_ENDPOINT}/practices`, jwtToken, { filter });

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getSingleUser = useCallback(async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${USERS_ENDPOINT}/${id}`, jwtToken);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);

	const newUser = useCallback(async (data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPost(`${USERS_ENDPOINT}`, jwtToken, data);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest])

	const editUser = useCallback(async (id, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPatch(`${USERS_ENDPOINT}`, jwtToken, data, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);

	const deleteUser = useCallback(async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDelete(`${USERS_ENDPOINT}`, jwtToken, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getCompsAgents = useCallback(async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${USERS_ENDPOINT}/agent-info/${id}`, jwtToken, {});

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getUserActivities = useCallback(async (id) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${USERS_ENDPOINT}/${id}/activities`, jwtToken, {});

			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getPlannedUserActivities = useCallback(async (id, date) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const dateParameter = !!date ? `&dateFrom=${date}` : ''
			const result = await httpGet(`${ACTIVITIES_ENDPOINT}/?state=PLANNED${dateParameter}&owner=${id}&sort=activityDate:1`, jwtToken);

			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	return (
		<UserContext.Provider
			value={{
				getUsers,
				getSingleUser,
				newUser,
				editUser,
				deleteUser,
				getCompsAgents,
				getUsersPractice,
				getUserActivities,
				getPlannedUserActivities
			}}
			{...props}
		/>
	)
}
const useUser = () => React.useContext(UserContext)

export { UserProvider, useUser }