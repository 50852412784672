import { useCallback, useEffect, useState } from 'react';

import { useUser } from '../../../contexts/user-context';
import FormUser from '../../../ui-components/forms-components/user';
import { useEthosNotification } from '../../../contexts/ethos-notification-context';

const EditUser = (props) => {
	const { id } = props.match.params;
	const { editUser, getSingleUser, getCompsAgents } = useUser();
	const { handleError } = useEthosNotification();
	const [user, setUser] = useState(null);
	const [practicesUser, setPracticesUser] = useState(null);

	const getUser = useCallback(async () => {
		const { data, error } = await getSingleUser(id);
		if (error) {
			handleError(error);
			return
		}
		setUser(data);

		const datauser = await getCompsAgents(id);
		setPracticesUser(datauser.data);
	}, [id, getCompsAgents, getSingleUser, handleError]);

	useEffect(() => {
		getUser();
	}, [id, getUser])

	const onSubmit = async (data) => {
		const { error } = await editUser(id, data);
		if (error !== null) {
			handleError(error);
			return;
		}
		window.location.reload();
	}

	return (
		<div className="edit-user-page px-3 py-3">
			{
				user ?
					<>
						<div className="row">
							<div className="col-md-7">
								<div className="top-page">
									<h3 className="top-page-title">Modifica Utente</h3>
								</div>

								<hr />

								<FormUser user={user} practicesUser={practicesUser} handleSave={onSubmit} />
							</div>
						</div>
					</>
					: null}
		</div>
	)
}

export default EditUser;