import React from 'react';

const EthosConfirmModalContent = ({ confirm = () => {}, closeFunction = () => {}, customParams={text:"Sei sicuro di voler procedere?"}}) => {
    const doConfirm = () => {
        if(confirm && confirm != null){
            confirm(customParams.callbackInput);
        }
        closeFunction();
    }

    return(
        <>
            <div className="modal-body text-center">
                <p>{customParams.text}</p>
            </div>
            <div className="modal-footer">
                <button onClick={closeFunction} type="button" data-bs-dismiss="modal" className="btn btn-warning">ANNULLA</button>
                <button onClick={doConfirm} type="button" data-bs-dismiss="modal" className="btn btn-primary">CONFERMA</button>
            </div>
        </>
    )
}

export default EthosConfirmModalContent;