import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { renderPaginator } from "../../../utils/functions";

interface SearchCustomerResponse {
  data: any[];
  length: number;
}

export interface SelectedCustomer {
  _id: string;
  first_name: string;
  last_name: string;
}

const SelectCustomerModalContent = ({
  confirm = (data: any) => {},
  closeFunction = () => {},
  customParams = {
    searchCustomer: async (search: string, customerID: string[], limit: number, offset: number): Promise<SearchCustomerResponse> => {
      return {
        data: [],
        length: 0,
      };
    },
    existingRelation: [],
    currentCustomerID: "",
  },
}) => {
  const [customers, setCustomer] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [selectedRows, setSelectedRows] = useState(new Map<string, { relativeId: string; relation: string }>());
  const [isFirstRender, setIsFirstRender] = useState(true);

  /* SEARCH FILTER */
  const LIMIT = 8;
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState(null);
  const [paginator, setPaginator] = useState(null);
  const searchRef = useRef();

  const onSearchChange = debounce((value: any) => {
    setSearch(value);
  }, 500);

  const loadCustomers = useCallback(async () => {
    const response = await customParams.searchCustomer(search, [customParams.currentCustomerID], LIMIT, offset);
    setCustomer(response.data);
    setTotalCustomers(response.length);
  }, [customParams, offset, search]);

  /* Create paginator */
  useEffect(() => {
    renderPaginator(totalCustomers, LIMIT, setOffset, setPaginator, offset);
  }, [offset, totalCustomers]);

  /* Clear offset */
  useEffect(() => {
    setOffset(0);
  }, [search]);

  /* Populate list */
  useEffect(() => {
    loadCustomers();
  }, [loadCustomers, offset]);

  const doConfirm = () => {
    const selectedCustomers = Array.from(selectedRows.values());
    confirm(selectedCustomers);
    closeFunction();
  };

  useEffect(() => {
    if (customParams.existingRelation.length > 0 && isFirstRender) {
      customParams.existingRelation.forEach((item) => {
        selectedRows.set(item.relativeId, { relativeId: item.relativeId, relation: item.relation });
      });
      setSelectedRows(selectedRows);
      setIsFirstRender(false);
    }
  }, [customParams.existingRelation, isFirstRender, selectedRows]);

  const isIdSelected = (id: string) => {
    return customParams.existingRelation.includes(id);
  };

  return (
    <>
      <div className="modal-body">
        <div className="wrapper-input-group w-auto d-inline-block">
          {/* SEARCH INPUT */}
          <div className="input-group">
            <input ref={searchRef} className="form-control" placeholder="Cerca un cliente" onChange={(e) => onSearchChange(e.target.value)} />
            <input className="btn btn-primary" type="submit" value="Cerca" />
          </div>
        </div>
        {/* PAGINATOR */}
        <nav className="pagination-section my-3">
          <ul className="pagination justify-content-center">{paginator}</ul>
        </nav>
        {/* TABLE */}
        <table className="table table-striped">
          <thead className="thead-dark">
            <tr>
              <th>Seleziona</th>
              <th>Nome</th>
              <th>Cognome</th>
              <th>Email</th>
              <th>Numero di Telefono</th>
            </tr>
          </thead>
          <tbody>
            {customers
              ? customers.map((item) => {
                  const isSelected = isIdSelected(item._id);

                  return (
                    <tr key={item._id}>
                      <td>
                        <input
                          type="checkbox"
                          name="select_customer"
                          id={item._id}
                          title="Select customer"
                          defaultChecked={selectedRows.has(item._id) || isSelected}
                          onChange={() => {
                            const newSelectedRows = new Map<string, { relativeId: string; relation: string }>(selectedRows);
                            if (newSelectedRows.has(item._id)) {
                              newSelectedRows.delete(item._id);
                            } else {
                              newSelectedRows.set(item._id, { relativeId: item._id, relation: "" });
                            }
                            setSelectedRows(newSelectedRows);
                          }}
                        />
                      </td>
                      <td>{item.first_name}</td>
                      <td>{item.last_name}</td>
                      <td>{item.email ? item.email : "N.D"}</td>
                      <td>{item.phone ? item.phone : "N.D"}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <div className="modal-footer">
        <button
          onClick={() => {
            doConfirm();
          }}
          type="button"
          className="btn btn-primary"
          data-bs-dismiss="modal"
        >
          Conferma
        </button>
        <button onClick={closeFunction} type="button" className="btn btn-secondary" data-bs-dismiss="modal">
          Annulla
        </button>
      </div>
    </>
  );
};

export default SelectCustomerModalContent;
