import { useState, useEffect, useCallback } from "react";
import { InputSelect, InputText, InputTextArea, InputDate, InputNumber, InputHour } from "../../forms";

const ActivityModalContent = ({
  confirm = () => {},
  closeFunction = undefined,
  customParams = {
    configurations: null,
    customer: null,
    activity: null,
    customerId: null,
    ownerId: null,
    users: [],
    sendMailFunc: async (id, newActivity) => {},
  },
  isModal = true,
}) => {
  const [canConfirm, setCanConfirm] = useState(false);
  const [type, setType] = useState(customParams.activity == null ? "" : customParams.activity.activityType);
  const [status, setStatus] = useState(customParams.activity == null ? "" : customParams.activity.activityStatus);
  const [location, setLocation] = useState(customParams.activity == null ? "" : customParams.activity.location);
  const [duration, setDuration] = useState(customParams.activity == null ? null : customParams.activity.duration);
  const [note, setNote] = useState(customParams.activity == null ? "" : customParams.activity.note);
  const [callOutcome, setCallOutcome] = useState(customParams.activity == null ? "" : customParams.activity.callOutcome);
  const [owner, setOwner] = useState(customParams.activity == null ? customParams.ownerId : customParams.activity.activityOwner);

  const types = customParams.configurations?.typeActivity;
  const callOutcomes = customParams.configurations?.activityCallOutcomes;
  const statuses = customParams.configurations?.activityStatus;

  var currentDate = new Date();
  var currentHour = new Date().getHours() + ":" + new Date().getMinutes();
  if (!!customParams.activity && !!customParams.activity.activityDate) {
    currentDate = new Date(customParams.activity.activityDate);
    currentHour = new Date(customParams.activity.activityDate).getHours() + ":" + new Date(customParams.activity.activityDate).getMinutes();
  }
  const [date, setDate] = useState(currentDate);
  const [hour, setHour] = useState(currentHour);

  const [isNew, setIsNew] = useState(customParams.activity == null);

  useEffect(() => {
    setIsNew(customParams.activity == null);
    setType(customParams.activity?.activityType || "");
    setStatus(customParams.activity?.activityStatus || "");
    setLocation(customParams.activity?.location || "");
    setDuration(customParams.activity == null ? null : customParams.activity.duration);
    setNote(customParams.activity == null ? "" : customParams.activity.note);
    setCallOutcome(customParams.activity == null ? "" : customParams.activity.callOutcome);
    setOwner(customParams.activity == null ? customParams.ownerId : customParams.activity.activityOwner);
    var currentDate = new Date();
    var currentHour = new Date().getHours() + ":" + new Date().getMinutes();
    if (customParams.activity && !!customParams.activity.activityDate) {
      currentDate = new Date(customParams.activity.activityDate);
      currentHour =
        new Date(customParams.activity.activityDate).getHours() +
        ":" +
        (new Date(customParams.activity.activityDate).getMinutes() + "").padStart(2, "0");
    }
    setDate(currentDate);
    setHour(currentHour);
    setCanConfirm(false);
  }, [customParams]);

  const resetForm = () => {
    setIsNew(true);
    setType("");
    setStatus("");
    setLocation("");
    setDuration("");
    setDate("");
    setCallOutcome("");
    setOwner("");
    setNote("");
  };

  if (!closeFunction) {
    closeFunction = resetForm;
  }

  useEffect(() => {
    if (!note || !type || !status || !date || !owner) {
      setCanConfirm(false);
      return;
    }
    if (["APPOINTMENT"].includes(type)) {
      if (!location) {
        setCanConfirm(false);
        return;
      }
    }

    if (["PHONE CALL"].includes(type)) {
      if (!!date && new Date(date).getTime() <= new Date().getTime() && !callOutcome) {
        setCanConfirm(false);
        return;
      }
    }

    setCanConfirm(true);
  }, [type, status, owner, date, hour, location, duration, note, callOutcome]);

  const doConfirm = () => {
    if (!canConfirm) {
      return;
    }
    var newActivity = {};
    if (!isNew) {
      newActivity._id = customParams.activity._id;
    }
    newActivity.customerId = customParams.customerId;
    newActivity.activityOwner = owner;
    newActivity.activityType = type;
    newActivity.activityStatus = status;
    if (!!callOutcome) {
      newActivity.callOutcome = callOutcome;
    }
    if (!!date) {
      var hourStr = hour;
      if (!hour) {
        hourStr = "00:00";
      }
      var myDate = new Date(date);
      var splits = hourStr.split(":");
      myDate.setHours(splits[0], splits[1]);
      newActivity.activityDate = myDate;
    }
    if (["APPOINTMENT"].includes(type)) {
      newActivity.location = location;
    }
    if (duration) {
      newActivity.duration = duration;
    }
    newActivity.note = note;
    confirm(newActivity);
    closeFunction();
  };

  const sendResumeByEmail = useCallback(async () => {
    if (customParams.sendMailFunc) {
      var newActivity = {};
      if (!isNew) {
        newActivity._id = customParams.activity._id;
      }
      newActivity.customerId = customParams.customerId;
      newActivity.activityOwner = owner;
      newActivity.activityType = type;
      newActivity.activityStatus = status;
      if (!!callOutcome) {
        newActivity.callOutcome = callOutcome;
      }
      if (!!date) {
        var hourStr = hour;
        if (!hour) {
          hourStr = "00:00";
        }
        var myDate = new Date(date);
        var splits = hourStr.split(":");
        myDate.setHours(splits[0], splits[1]);
        newActivity.activityDate = myDate;
      }
      if (["APPOINTMENT"].includes(type)) {
        newActivity.location = location;
      }
      if (duration) {
        newActivity.duration = duration;
      }
      newActivity.note = note;
      await customParams.sendMailFunc(customParams.activity._id, newActivity);
      closeFunction();
    }
  }, [callOutcome, closeFunction, customParams, date, duration, hour, isNew, location, note, owner, status, type]);


  const getContentButtonByTypeActivities = (status) => {
    switch (status) {
      case "APPOINTMENT":
        return "Invia Promemoria Appuntamento";
      default:
        return "Invia Riepilogo via Email";
    }
  };

  const mappedUsers = customParams.users
    .filter((u) => ["ADMIN", "AGENTE", "SALES"].indexOf(u.role) >= 0)
    .map((s) => {
      s.label = s.first_name + " " + s.last_name;
      s.value = s._id;
      return s;
    });

  return (
    <>
      <div className="modal-body">
        <div className="row">
          {isModal && !!customParams.customer && (
            <div className="col-12 text-center mb-2">
              Cliente: <span className="fw-bold text-primary">{customParams.customer.first_name + " " + customParams.customer.last_name}</span>
            </div>
          )}
          {!isModal && <h5 className="col-12 mb-2">Aggiungi una nuova attività</h5>}
          <div className="col-md-12">
            <InputSelect isRequired={true} defaultValue={type} label="Tipo" name="type" data={types} onChange={setType} />
          </div>
          <div className="col-md-12">
            <InputSelect isRequired={true} defaultValue={status} label="Stato" name="status" data={statuses} onChange={setStatus} />
          </div>
          <div className="col-md-12">
            <InputSelect isRequired={true} defaultValue={owner} label="Owner" name="owner" data={mappedUsers} onChange={setOwner} />
          </div>
          <div className="col-md-6">
            <InputDate isRequired={true} onChange={setDate} defaultValue={date} name="date" label="Data" />
          </div>
          <div className="col-md-6">
            <InputHour onChange={setHour} defaultValue={hour} name="hour" label="Ora" />
          </div>
          {["APPOINTMENT"].includes(type) ? (
            <div className="col-md-6">
              <InputText isRequired={true} onChange={setLocation} defaultValue={location} name="location" label="Luogo" />
            </div>
          ) : null}
          {!["WHATSAPP", "EMAIL"].includes(type) ? (
            <div className="col-md-6">
              <InputNumber onChange={setDuration} defaultValue={duration} name="duration" label="Durata (in minuti)" />
            </div>
          ) : null}
          {["PHONE CALL", "VIDEO CALL", "APPOINTMENT"].includes(type) ? (
            <div className="col-md-6">
              <InputSelect
                isRequired={["DONE"].includes(status)}
                defaultValue={callOutcome}
                label="Esito"
                name="callOutcome"
                data={callOutcomes}
                onChange={setCallOutcome}
              />
            </div>
          ) : null}
          <div className="col-md-12">
            <InputTextArea isRequired={true} onChange={setNote} defaultValue={note} name="note" label="Note" />
          </div>
        </div>
      </div>
      <div className="modal-footer justify-content-between">
        {!isNew && (
          <div className="">
            <button
              type="button"
              onClick={() => {
                sendResumeByEmail();
              }}
              data-bs-dismiss="modal"
              className="btn btn-secondary"
              disabled={status === "PLANNED" || !canConfirm}
            >
              {getContentButtonByTypeActivities(type)}
            </button>
          </div>
        )}
        <div className="d-flex gap-3">
          <button onClick={closeFunction} type="button" className="btn btn-secondary" data-bs-dismiss="modal">
            {isModal ? "Annulla" : "Reset"}
          </button>
          <button
            disabled={!canConfirm}
            onClick={() => {
              doConfirm();
            }}
            data-bs-dismiss="modal"
            type="button"
            className="btn btn-primary"
          >
            Salva
          </button>
        </div>
      </div>
    </>
  );
};

export default ActivityModalContent;
