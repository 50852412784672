import React, { useState } from 'react';

import { InputNumber, InputDate } from '../../../forms';

const AbfDone = ({ handleGenerateFile = () => {}, generateFile }) => {
	const [importSpesa1, setImportSpesa1] = useState(0);
	const [importSpesa2, setImportSpesa2] = useState(0);
	const [importSpesa3, setImportSpesa3] = useState(0);
	const [dateReclamo, setDateReclamo] = useState(0);
	const [dateSeguito, setDateSeguito] = useState(0);
	const [btnGenerationTxt, setBtnGenerationTxt] = useState('Genera');

	const handleGenerateFileLocal = async () => {
		const data = {
			importSpesa1,
			importSpesa2,
			importSpesa3,
			dateReclamo,
			dateSeguito
		};

		setBtnGenerationTxt('Genero...');
		await handleGenerateFile(generateFile, 'ABF-Fatto', data);
		setBtnGenerationTxt('Genera');
	}

	return (
		<>
			<div className="row mt-4 mb-4">
				<div className="col-md-4">
					<InputNumber validation={null} type="number" price={true} step="0.01" name="importSpesa1" label="Importo spesa assicurativa 1" onChange={setImportSpesa1} />
				</div>
				<div className="col-md-4">
					<InputNumber validation={null} type="number" price={true} step="0.01" name="importSpesa2" label="Importo spesa assicurativa 2" onChange={setImportSpesa2} />
				</div>
				<div className="col-md-4">
					<InputNumber validation={null} type="number" price={true} step="0.01" name="importSpesa3" label="Importo spesa assicurativa 3" onChange={setImportSpesa3} />
				</div>
			</div>

			<div className="row mt-4 mb-4">
				<div className="col-md-4">
					<InputDate name="dateReclamo" label="Data Reclamo" onChange={setDateReclamo} />
				</div>
				<div className="col-md-4">
					<InputDate name="dateSeguito" label="Data Seguito" onChange={setDateSeguito} />
				</div>
			</div>

			<button type="button" onClick={handleGenerateFileLocal} className="btn btn-primary">{btnGenerationTxt}</button>
		</>
	)
}

export default AbfDone;