import { Route, Switch } from "react-router-dom";
import { ArchivesProvider } from "../contexts/archives-context";
import { BanksProvider } from "../contexts/banks-context";
import { CommentsProvider } from "../contexts/comments-context";
import { ConfigurationsContextProvider } from "../contexts/configurations-context";
import { CustomerProvider } from "../contexts/customer-context";
import { UserProvider } from "../contexts/user-context";
import { ServiceCustomerProvider } from "../contexts/service-customer-context";
import ListDeleteReport from "../components/delete-reports/list-delete-reports";
import { ServiceProvider } from "../contexts/service-context";

const DeleteReports = ({ match }) => {
  return (
    <div className="deleteReports-page">
      <CustomerProvider>
        <CommentsProvider>
          <BanksProvider>
            <ArchivesProvider>
              <UserProvider>
                <ServiceProvider>
                  <ServiceCustomerProvider>
                    <ConfigurationsContextProvider>
                      <Switch>
                        <Route exact path={`${match.path}`} component={ListDeleteReport} />
                      </Switch>
                    </ConfigurationsContextProvider>
                  </ServiceCustomerProvider>
                </ServiceProvider>
              </UserProvider>
            </ArchivesProvider>
          </BanksProvider>
        </CommentsProvider>
      </CustomerProvider>
    </div>
  );
};

export default DeleteReports;
