import React, { useState, useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { InputSelect } from '../../forms';

const SelectUserModalContent = ({ confirm = () => { }, closeFunction = () => { }, customParams = { current: 0, users: [], showAssignToMe: false, roleFilter: null, placeholder: undefined, allowBlank: false } }) => {
    const [canConfirm, setCanConfirm] = useState(customParams.allowBlank);
    const [selectedOwner, setSelectedOwner] = useState(customParams.current);
    const [selectables, setSelectables] = useState([]);
    const { user } = useAuthenticator();

    useEffect(() => {
        if (customParams.allowBlank || (!!selectedOwner)) {
            setCanConfirm(true);
        } else {
            setCanConfirm(false);
        }
    }, [selectedOwner, customParams.allowBlank]);

    useEffect(() => {
        var owners = customParams.users;
        if (customParams.roleFilter != null) {
            if (Array.isArray(customParams.roleFilter)) {
                owners = customParams.users.filter(u => customParams.roleFilter.indexOf(u.role) >= 0);
            } else {
                owners = customParams.users.filter(u => u.role === customParams.roleFilter);
            }
        }
        var options = owners.map(s => {
            s.label = s.first_name + " " + s.last_name;
            s.value = s._id;
            return s;
        });
        setSelectables(options);
    }, [customParams]);


    const doConfirm = () => {
        if (!canConfirm) {
            return;
        }
        confirm(selectedOwner);
        closeFunction();
    }

    const assignToMe = () => {
        var me = customParams.users.find(u => u.username === user.username);
        confirm(me?._id);
        closeFunction();
    }

    return (
        <>
            <div className="modal-body">
                <div className=" justify-content-center row">
                    {customParams.showAssignToMe ?
                        <><div className="col-12 text-center">
                            <span data-bs-dismiss="modal" className="btn btn-success text-center" onClick={assignToMe}>Assegna a me</span>
                        </div>
                            <div className="col-12 text-center">
                                <small>oppure</small>
                            </div></> : null
                    }
                    <div className="col-12">
                        <InputSelect defaultValue={customParams.current} name="owners" data={selectables} onChange={setSelectedOwner} placeholder={customParams.placeholder} />
                    </div>

                </div>
            </div>
            <div className="modal-footer">
                <button onClick={closeFunction} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annulla</button>
                <button disabled={!canConfirm} onClick={doConfirm} data-bs-dismiss="modal" type="button" className="btn btn-primary">Salva</button>
            </div>
        </>
    )
}

export default SelectUserModalContent;