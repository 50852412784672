import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import CreateTicket from './create-ticket';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useTickets } from '../../contexts/tickets-context';

import './tickets.scss';
import { useEthosNotification } from '../../contexts/ethos-notification-context';

const ListTickets = (props) => {
	const { user } = useAuthenticator();
	const { getTickets, deleteTicket } = useTickets();

	const [tickets, setTickets] = useState(null);
	const [showCreateTicket, setShowCreateTicket] = useState(false);
	const { handleError } = useEthosNotification();
	const role = user && user.signInUserSession && user.signInUserSession.accessToken && user.signInUserSession.accessToken.payload &&
		user.signInUserSession.accessToken.payload["cognito:groups"] && user.signInUserSession.accessToken.payload["cognito:groups"].length
		? user.signInUserSession.accessToken.payload["cognito:groups"][0] : "CLIENTE";

	useEffect(() => {
		const getTicketsFun = async () => {
			const { data, error } = await getTickets();

			if (error != null) {
				handleError(error);
				return;
			}
			setTickets(data);
		}

		getTicketsFun();
	}, [getTickets, handleError]);

	const handleDelete = async (id) => {
		const confirmDelete = window.confirm('Sei sicuro di voler continuare?');

		if (!confirmDelete) {
			return;
		}

		const { error } = await deleteTicket(id);
		if (error !== null) return alert("Impossibile eliminare il ticket");
		window.location.reload();
	}

	return (
		<div className="list-tickets-page">
			<div className="top-bar d-flex align-items-center top-page">
				<h3 className="top-page-title">Lista Tickets</h3>
				{
					(role === 'ADMIN' || role === 'AGENTE') ?
						<button className="btn btn-primary" style={{ marginLeft: 'auto' }} onClick={() => setShowCreateTicket(!showCreateTicket)}>Nuovo Ticket</button>
						: null
				}
			</div>

			<hr />

			<div className="list-ticktes row">
				{
					tickets != null ?
						<>
							{
								tickets.map((item, index) => {
									return (
										<div className="col-md-4 mt-2" key={index}>
											<div className="ticket-item">
												<h2 className="ticket-title">{item.title}</h2>
												<p className="ticket-description">{item.description}</p>

												<hr />

												<p className="ticket-description">Rif. Pratica: <strong>{item.practice}</strong></p>

												<hr />

												<div className="row">
													<div className="col-md-6">
														<NavLink to={`${props.match.path}/${item._id}`} className="btn btn-primary btn-block">Apri</NavLink>
													</div>
													<div className="col-md-6">
														<button type="button" onClick={() => handleDelete(item._id)} className="w-100 btn-block btn btn-danger">Elimina</button>
													</div>
												</div>
											</div>
										</div>
									)
								})
							}
						</>
						: null
				}
			</div>

			{(role === 'ADMIN' || role === 'AGENTE') && showCreateTicket ? <CreateTicket setTickets={setTickets} setShowCreateTicket={setShowCreateTicket} /> : null}
		</div>
	)
}

export default ListTickets;

