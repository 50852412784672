import { useState } from 'react';
import { InputDate, InputNumber, InputSelect } from '../forms';


const CreatePaymentsPlanModalContent = ({ confirm = () => { }, closeFunction = () => { }, customParams = { data: {} } }) => {
    const [tranchesNumber, setTranchesNumber] = useState(0);
    const [firstTrancheExpirationDate, setFirstTrancheExpirationDate] = useState(null);
    const [tranchesPeriod, setTranchesPeriod] = useState('monthly');
    const [customTranchesPeriod, setCustomTranchesPeriod] = useState(null);

    const tranchesPeriodOptions = [
        {
            "value": "monthly",
            "label": "Mensile"
        },
        {
            "value": "custom",
            "label": "Personalizzata"
        }
    ];

    const canConfirm = () => {
        return tranchesNumber > 0 && !!firstTrancheExpirationDate &&
            (tranchesPeriod === 'monthly' || customTranchesPeriod > 0);
    }

    const doConfirm = () => {
        confirm({ tranchesNumber, firstTrancheExpirationDate, tranchesPeriod, customTranchesPeriod });
        closeFunction();
    }


    return (
        <>
            <div className="modal-body">
                <p>
                    CreatePaymentsPlanModalContent
                </p>
                <div className='row'>
                    <div className="col-md-6">
                        <InputNumber validation={null} defaultValue={tranchesNumber} type="number" step="1" isRequired={true} price={false} name="tranches-number" label="Numero di rate" onChange={setTranchesNumber} />
                    </div>
                    <div className="col-md-6">
                        <InputDate name="first-tranche-expiration-date" onChange={setFirstTrancheExpirationDate} isRequired={true} defaultValue={firstTrancheExpirationDate} label={"Data Scadenza prima rata"} />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className="col-md-6">
                        <InputSelect label="Seleziona cadenza rate" name="tranches-period" data={tranchesPeriodOptions} onChange={setTranchesPeriod} defaultValue={tranchesPeriod} />
                    </div>
                    {tranchesPeriod === 'custom' && <div className="col-md-6">
                        <InputNumber validation={null} defaultValue={customTranchesPeriod} type="number" step="1" isRequired={tranchesPeriod === 'custom'} price={false} name="custom-tranches-period" label="Cadenza pagamento rate (giorni)" onChange={setCustomTranchesPeriod} />
                    </div>}
                </div>

            </div >
            <div className="modal-footer">
                <button onClick={closeFunction} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                <button disabled={!canConfirm()} onClick={() => doConfirm()} data-bs-dismiss="modal" type="button" className="btn btn-primary">Conferma</button>
            </div>
        </>
    )
}

export default CreatePaymentsPlanModalContent;