import { useState, useEffect, useRef, useCallback } from 'react';
import { useMemos } from '../../contexts/memo-context';
import { useCustomer } from '../../contexts/customer-context';
import { useEthosNotification } from "../../contexts/ethos-notification-context";
import { InputTextArea, InputSelect } from '../forms';
import "./customer-memos.css";
import moment from 'moment';
import { getServiceCustomerName } from '../../utils/functions';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useEthosModal } from '../../contexts/ethos-modal-context';

const CustomerMemos = ({ customer = null, users = [], services = [], serviceCustomerList = [] }) => {

    const [myMemo, setMyMemo] = useState('');
    const [memos, setMemos] = useState([]);
    const [selectableServices, setSelectableServices] = useState([]);
    const [selectedServiceCustomerId, setSelectedServiceCustomerId] = useState(null);
    const myMemoRef = useRef();
    const serviceRef = useRef();

    const { handleError, showSuccessToast } = useEthosNotification();

    const { newMemo, deleteMemo } = useMemos();
    const { getCustomerMemos } = useCustomer();
    const { user } = useAuthenticator();
    const { openEthosConfirmModal } = useEthosModal();

    const role = useRef("CLIENTE");

    useEffect(() => {
        role.current = !!user &&
            !!user.signInUserSession &&
            !!user.signInUserSession.accessToken &&
            !!user.signInUserSession.accessToken.payload &&
            !!user.signInUserSession.accessToken.payload["cognito:groups"] &&
            !!user.signInUserSession.accessToken.payload["cognito:groups"].length
            ? user.signInUserSession.accessToken.payload["cognito:groups"][0]
            : "CLIENTE";
    }, [user]);

    const getRefServiceName = useCallback((serviceCustId) => {
        if (!serviceCustId) {
            return null;
        }
        var serviceCust = serviceCustomerList.find(s => s._id === serviceCustId);
        if (serviceCust) {
            return getServiceCustomerName(services, serviceCust);
        } else {
            return null;
        }
    }, [serviceCustomerList, services]);


    const loadMemos = useCallback(async () => {
        var { data, error } = await getCustomerMemos(customer._id);
        if (error) {
            handleError(error);
            return;
        }
        for (var i = 0; i < data[0].MemoData.length; i++) {
            data[0].MemoData[i].refServiceName = getRefServiceName(data[0].MemoData[i].serviceCustomerId);
        }

        setMemos(data[0].MemoData);
    }, [customer._id, getCustomerMemos, getRefServiceName, handleError]);

    const getUserName = (id) => {
        var s = users.find(e => e._id === id);
        if (s) {
            return s.first_name + " " + s.last_name;
        } else {
            return "N.D.";
        }
    }

    const addMemo = async () => {
        var input = {};
        input.customerId = customer._id;
        input.memoStatus = "ACTIVE";
        input.text = myMemo;
        if (!!selectedServiceCustomerId) {
            input.serviceCustomerId = selectedServiceCustomerId;
        }
        const { error } = await newMemo(input);
        if (error) {
            handleError(error);
            return;
        }

        clearInputs();
        loadMemos();
    }

    useEffect(() => {
        if (!customer) {
            return
        }
        loadMemos();
    }, [customer, loadMemos]);

    useEffect(() => {
        if (!serviceCustomerList) {
            setSelectableServices([]);
        };

        var lista = [];
        for (var i = 0; i < serviceCustomerList.length; i++) {
            var obj = {
                label: getServiceCustomerName(services, serviceCustomerList[i]),
                value: serviceCustomerList[i]._id
            };
            lista.push(obj);
        }
        setSelectableServices(lista);
    }, [serviceCustomerList, services]);

    const clearInputs = () => {
        setMyMemo('')
        setSelectedServiceCustomerId(null);
        myMemoRef.current.value = '';
        if (serviceRef && serviceRef.current) {
            serviceRef.current.value = null;
        }
    }

    const onDeleteMemo = (memo) => {

        var deleteCallback = async (aId) => {
            const { error } = await deleteMemo(aId);
            if (error) {
                handleError(error);
                return;
            }
            loadMemos();
            showSuccessToast("Nota interna eliminata correttamente");
        }
        openEthosConfirmModal("Sicuro di voler eliminare la nota interna?", deleteCallback, memo._id, "Conferma eliminazione");
    };

    const getUsername = (id) => {
        var s = users.find(e => e._id === id);
        if (s) {
            return s.username;
        }
        else {
            return "N.D."
        }
    }

    const canDelete = (serviceCust) => {
        if (role.current === "ADMIN") return true;
        const username = getUsername(serviceCust.createdBy);
        return username === user.username;
    }


    let content;
    if (!!memos && memos.length > 0) {
        content = memos.map((item, index) => {
            return (
                <div key={"memo_" + index} className="col-12 memo-box">
                    <p className="memo-header m-0">
                        Creata da <span className="fw-bold">{getUserName(item.createdBy)}</span>  il <span className="fw-bold">{moment(item.createdAt).format("DD/MM/yyyy HH:mm")}</span>
                    </p>
                    <p className="memo-header m-0">
                        {item.refServiceName != null ? <span>Servizio di riferimento: <span className="fw-bold">{item.refServiceName}</span><br></br></span> : null}
                    </p>
                    {/* <p className="memo-header m-0">
                            Stato: <span className={item.memoStatus == 'ACTIVE' ? "fw-bold text-success" : "fw-bold"}>{item.memoStatus == 'ACTIVE' ? "Attiva" : "Non attiva"}</span><br></br>
                        </p> */}
                    <p className="memo-content">{'"' + item.text + '"'}</p>
                    {canDelete(item) && <p className='memo-footer text-right px-2 pb-2 m-0'>
                        <button onClick={() => onDeleteMemo(item)} className='btn btn-danger'>Elimina</button>
                    </p>}
                </div>
            )
        });
    } else {
        content = <div className="text-center">
            <h6>Nessuna nota da mostrare...</h6>
        </div>;
    }

    return (
        <div className="row">
            <div className="col-8 memos-container">
                <div className="row memos-inner-container">
                    {content}
                </div>
            </div>
            <div className="col-4">
                <div className="col-12 mb-2" style={{ marginTop: 13 }}>
                    <InputTextArea register={() => myMemoRef} label="" className="new-memo-input" onChange={setMyMemo} placeholder='Inserisci una nuova nota'></InputTextArea>
                </div>
                {!!selectableServices && selectableServices.length > 0 &&
                    <div className="col-12 mb-2">
                        <InputSelect label="Servizio di riferimento" register={() => serviceRef} name="services" data={selectableServices} onChange={setSelectedServiceCustomerId} />
                    </div>
                }
                <button onClick={addMemo} disabled={!myMemo} className="btn w-100 btn-primary">Salva</button>
            </div>

        </div>
    )
}

export default CustomerMemos;