import React from "react";
import { Switch, Route } from "react-router-dom";
import ServiceCustomerScreening from "../components/service-customer/service-customer-screening";
import { CustomerProvider } from "../contexts/customer-context";
import { FilesProvider } from "../contexts/files-context";
import { ServiceProvider } from "../contexts/service-context";
import { ServiceCustomerProvider } from "../contexts/service-customer-context";
import { UserProvider } from "../contexts/user-context";
import { BanksProvider } from "../contexts/banks-context";

const ServiceScreeningDetailsPage = ({ match }) => {
  return (
    <div>
      <BanksProvider>
        <CustomerProvider>
          <UserProvider>
            <ServiceProvider>
              <ServiceCustomerProvider>
                <FilesProvider>
                  <Switch>
                    <Route exact path={`${match.path}/:id`} component={ServiceCustomerScreening} />
                  </Switch>
                </FilesProvider>
              </ServiceCustomerProvider>
            </ServiceProvider>
          </UserProvider>
        </CustomerProvider>
      </BanksProvider>
    </div>
  );
};

export default ServiceScreeningDetailsPage;
