import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useEthosModal } from '../../../contexts/ethos-modal-context';

const ServiceTemplatesModalContent = ({ customParams = { fileList: [], typeFilter: null, doDownloadFile: () => { }, doArchiveTemplate: () => { } }, confirm = () => { }, closeFunction = () => { } }) => {
	const [visibleFiles, setVisibleFiles] = useState([]);
	const { openEthosConfirmModal } = useEthosModal();

	useEffect(() => {
		if (customParams && customParams.fileList) {
			var lista = [];
			for (var i = 0; i < customParams.fileList.length; i++) {
				if (!customParams.typeFilter || customParams.typeFilter === customParams.fileList[i].fileType) {
					lista.push({ ...customParams.fileList[i] });
				}
			}
		}
		setVisibleFiles(lista);
	}, [customParams]);

	const doArchiveTemplateInner = (f) => {
		const archiveFileCallback = async (f2) => {
			f.status = "ARCHIVED";
			customParams.doArchiveTemplate(f);
		}

		openEthosConfirmModal("Sicuro di voler archiviare il template?", archiveFileCallback, f, "Conferma archiviazione");
	}

	return (
		<>
			<div className="modal-body">
				<div className=" justify-content-center row">
					<div className="col-12">
						{
							visibleFiles && visibleFiles.length ?
								<table className="table table-striped">
									<tbody>
										<tr>
											{customParams.allowSelection ? <th className="text-center" style={{ width: '8%' }} ></th> : null}
											<th className="text-center">Nome File</th>
											<th className="text-center">Caricato il</th>
											<th className="text-center">Scadenza</th>
											<th className="text-center">Stato</th>
											<th className="text-center"></th>
										</tr>
										{visibleFiles.map(function (f, index) {
											return (
												<tr key={"doc_list_modal_trow_" + (index)}>
													<td className="text-center">
														<span>{f.filename}</span>
													</td>
													<td className="text-center">
														{f.updatedAt ?
															<span>{moment(f.updatedAt).format('DD/MM/YYYY hh:mm:ss')}</span>
															: <span>N.D.</span>
														}
													</td>
													<td className="text-center">
														{f.expirationDate ?
															<span>{moment(f.expirationDate).format('DD/MM/YYYY')}</span>
															: <span>N.D.</span>
														}
													</td>
													<td className="text-center">
														<span>{f.status}</span>
													</td>

													<td className="text-center">
														<div className="btn-group w-100" role="group">
															<span onClick={() => customParams.doDownloadFile(f)} className="btn btn-sm btn-primary">Scarica</span>
															{f.status !== "ARCHIVED" && <span onClick={() => doArchiveTemplateInner(f)} className="btn btn-sm btn-danger">Archivia</span>}
														</div>
													</td>
												</tr>
											)
										})
										}
									</tbody>
								</table> :
								<h6 className="text-center">Nessun file da mostrare...</h6>
						}
					</div>
				</div>
			</div>
			<div className="modal-footer">
				<button onClick={closeFunction} type="button" className="btn btn-primary" data-bs-dismiss="modal">Chiudi</button>
			</div>
		</>
	)
}

export default ServiceTemplatesModalContent;