import React from 'react'

import { useAuth } from './auth-context';
import { httpPost } from '../http'
import { GOOGLE_ENDPOINT, GCALENDAR_ENDPOINT } from '../constants/API_ENDPOINT'

const GoogleContext = React.createContext()

function GoogleProvider(props) {
	const { jwtToken } = useAuth();

	const checkIfIsAuthed = () => {
		return (localStorage.getItem('google_api_token') !== null);
	}

	const authGoogleAccount = async (data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${GOOGLE_ENDPOINT}/generate-token`, jwtToken, { googleData: data });
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		// save data to local storage
		localStorage.setItem('google_api_token', obj.data.token);
		return obj;
	}

	const getCalendarsIds = async () => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${GCALENDAR_ENDPOINT}/calendars`, jwtToken, { googleToken: localStorage.getItem('google_api_token') });
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const listEvents = async () => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${GCALENDAR_ENDPOINT}/events`, jwtToken, { googleToken: localStorage.getItem('google_api_token') });
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const createEvent = async () => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${GCALENDAR_ENDPOINT}/events/create`, jwtToken, { googleToken: localStorage.getItem('google_api_token') });
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	return (
		<GoogleContext.Provider
			value={{
				authGoogleAccount,
				listEvents,
				createEvent,
				checkIfIsAuthed,
				getCalendarsIds
			}}
			{...props}
		/>
	)
}
const useGoogle = () => React.useContext(GoogleContext)

export { GoogleProvider, useGoogle }