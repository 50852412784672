import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { TicketsProvider } from '../contexts/tickets-context';
import { UserProvider } from '../contexts/user-context';

import ListTickets from '../components/tickets/list-tickets';
import SingleTicket from '../components/tickets/single-ticket';

const Tickets = ({ match }) => {
	return (
		<div className="tickets-page">
			<TicketsProvider>
				<UserProvider>
					<Switch>
						<Route exact path={`${match.path}`} component={ListTickets} />
						<Route exact path={`${match.path}/:id`} component={SingleTicket} />
					</Switch>
				</UserProvider>
			</TicketsProvider>
		</div>
	)
}

export default Tickets;