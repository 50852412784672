import { useState, useEffect, useCallback } from 'react';
import { useServiceCustomer } from '../../../contexts/service-customer-context';
import { startDownload, buildAvailableTemplatesList, isTemplate } from '../../../utils/functions';
import { useEthosModal } from '../../../contexts/ethos-modal-context';
import { useConfigurations } from '../../../contexts/configurations-context';
import { useFiles } from '../../../contexts/files-context';
import { useService } from '../../../contexts/service-context';
import { useEthosNotification } from '../../../contexts/ethos-notification-context';

const LegacyServiceReport = ({ practiceType = null, practiceId = null }) => {
	const [templatesInfo, setTemplatesInfo] = useState(null);
	const [service, setService] = useState(null);
	const [availableTemplatesList, setAvailableTemplatesList] = useState(null);
	const { generateCqsReport, generateDebtsReport, generateLoansReport } = useServiceCustomer();
	const { openDocumentListModal } = useEthosModal();
	const { configurations } = useConfigurations();
	const { getServices } = useService();
	const { handleError, showErrorToast, showSuccessToast } = useEthosNotification();
	const { getFullFilesInfo, downloadFile } = useFiles();

	const doDownloadFileById = async (id) => {
		const { error } = await downloadFile(id);
		if (error) {
			showErrorToast("Errore durante il download del file!");
			return;
		}
	}

	const doDownloadFile = async (f) => {
		doDownloadFileById(f.id);
	}

	const loadService = useCallback(async () => {
		var { data, error } = await getServices();
		if (error) {
			handleError(error);
			return;
		}
		var services = data.data;
		var internalName = "";
		switch (practiceType) {
			case "cqs":
				internalName = "RecuperoCessione";
				break;
			case "loans":
				internalName = "RecuperoPrestito";
				break;
			case "debts":
				internalName = "GestioneDebitoria";
				break;
			default:
				break;
		}
		if (services && services.length) {
			var s = services.find(e => e.internalName === internalName);
			setService(s);
		}
	}, [getServices, handleError, practiceType]);

	const loadTemplates = useCallback(async () => {
		var { data, error } = await getFullFilesInfo();
		if (error) {
			handleError(error);
			return;
		}
		var templates = [];
		for (var i = 0; i < data.data.length; i++) {
			if (isTemplate(data.data[i].fileType, configurations)) {
				templates.push(data.data[i]);
			}
		}
		setTemplatesInfo(templates);
	}, [configurations, getFullFilesInfo, handleError]);

	useEffect(() => {
		var lista = buildAvailableTemplatesList(configurations, service, templatesInfo);
		setAvailableTemplatesList(lista);

	}, [configurations, service, templatesInfo]);

	const doGenerateReport = async () => {
		var callback = async (files) => {
			if (!files || !files.length) {
				return;
			}
			var data;
			switch (practiceType) {
				case "cqs":
					data = await generateCqsReport(practiceId, files[0].id);
					break;
				case "debts":
					data = await generateDebtsReport(practiceId, files[0].id);
					break;
				case "loans":
					data = await generateLoansReport(practiceId, files[0].id);
					break;
				default:
					data = { error: "Errore" };
					break;
			}

			if (data.error !== null) {
				showErrorToast("Errore durante la generazione del report!");
				return;
			} else {
				showSuccessToast("Report generato con successo!");
			}
			startDownload(data.data);
		}
		var customColumns = [];
		customColumns.push({ value: "templateTypeLabel", label: "Tipo Template" })
		openDocumentListModal(availableTemplatesList, null, false, true, callback, doDownloadFile, null, "Seleziona il template da utilizzare per il nuovo report", null, true, customColumns, null);

	}

	useEffect(() => {
		loadService();
		loadTemplates();
	}, [loadService, loadTemplates]);

	return (
		<>{
			(
				<div className="row">
					<div className="col-md-4 offset-4">
						<div className="btn-group w-100" role="group">
							<span onClick={() => doGenerateReport()} className="btn btn-sm btn-primary">Genera report per il servizio</span>
						</div>
					</div>
				</div>)
		}</>
	)
}

export default LegacyServiceReport;