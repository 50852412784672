export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://vz43928jn1.execute-api.eu-west-1.amazonaws.com/staging/api' //|| `https://057auuyuv8.execute-api.eu-west-1.amazonaws.com/api`;
export const AUTH_ENDPOINT = 'users';
export const USERS_ENDPOINT = 'users';
export const CUSTOMERS_ENDPOINT = 'customers';
export const PRACTICES_CQS_ENDPOINT = 'cqs';
export const PRACTICES_LOANS_ENDPOINT = 'loans';
export const PRACTICES_DEBTS_ENDPOINT = 'debts';
export const ARCHIVES_ENDPOINT = 'archives';
export const TICKETS_ENDPOINT = 'tickets';
export const GOOGLE_ENDPOINT = 'googleapis';
export const GCALENDAR_ENDPOINT = 'gcalendar';
export const BANKS_ENDPOINT = 'banks';
export const SERVICES_ENDPOINT = 'services';
export const SERVICECUSTOMER_ENDPOINT = 'servicecustomers';
export const ACTIVITIES_ENDPOINT = 'activities';
export const MEMO_ENDPOINT = 'memos';
export const FILES_ENDPOINT = 'files';
export const SYSTEM_ENDPOINT = 'system';
export const ASYNC_OP_ENDPOINT = 'asyncop';
export const HIDE_SIGNUP = process.env.REACT_APP_HIDE_SIGNUP === 'true' || false; 