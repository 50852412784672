import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ServicesConfiguration from '../components/services-configuration/services-configuration';
import { FilesProvider } from '../contexts/files-context';
import { ServiceProvider } from '../contexts/service-context';
import { UserProvider } from '../contexts/user-context';

const ServicesConfigurationPage = ({ match }) => {
	return (
		<div>
					<UserProvider>
						<ServiceProvider>
							<FilesProvider>
								<Switch>
									<Route exact path={`${match.path}`} component={ServicesConfiguration} />
								</Switch>
							</FilesProvider>
						</ServiceProvider>
					</UserProvider>
		</div>
	)
}

export default ServicesConfigurationPage;