import React, { useState } from 'react';

import { InputText, InputNumber } from '../../../forms';

const FormReclamo = ({ handleGenerateFile = () => {}, generateFile }) => {
	const [costPolizza, setCostPolizza] = useState(0);
	const [branchAss, setBranchAss] = useState(0);
	const [polizza1, setPolizza1] = useState(0);
	const [polizza2, setPolizza2] = useState(0);
	const [importN, setImportN] = useState(0);
	const [importLetter, setImportLetter] = useState(0);
	const [btnGenerationTxt, setBtnGenerationTxt] = useState('Genera');

	const handleGenerateFileLocal = async () => {
		const data = {
			costPolizza,
			branchAss,
			polizza1,
			polizza2,
			importN,
			importLetter
		};

		setBtnGenerationTxt('Genero...');
		await handleGenerateFile(generateFile, 'RECLAMO', data);
		setBtnGenerationTxt('Genera');
	}

	return (
		<>
			<div className="row mt-4 mb-4">
				<div className="col-md-4">
					<InputNumber validation={null} type="number" price={true} step="0.01" name="costPolizza" label="Costo Polizza" onChange={setCostPolizza} />
				</div>
				<div className="col-md-4">
					<InputText name="branchAss" label="Filiale Assicurazione" onChange={setBranchAss} />
				</div>
				<div className="col-md-4">
					<InputNumber validation={null} type="number" price={true} step="0.01" name="polizza1" label="Polizza 1" onChange={setPolizza1} />
				</div>
			</div>

			<div className="row mb-4">
				<div className="col-md-4">
					<InputNumber validation={null} type="number" price={true} step="0.01" name="polizza2" label="Polizza 2" onChange={setPolizza2} />
				</div>
				<div className="col-md-4">
					<InputNumber validation={null} type="number" price={true} step="0.01" name="importN" label="Importo" onChange={setImportN} />
				</div>
				<div className="col-md-4">
					<InputText name="importLetter" label="Importo in Lettere" onChange={setImportLetter} />
				</div>
			</div>

			<button type="button" onClick={handleGenerateFileLocal} className="btn btn-primary">{btnGenerationTxt}</button>
		</>
	)
}

export default FormReclamo;