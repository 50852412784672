import { useState, useEffect, useCallback } from 'react';


const PaginationBar = ({ resultsPerPage = 10, fullList = [], setVisibleList = () => { } }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageList, setPageList] = useState([]);

    const setPage = useCallback((currentPage) => {
        var newList = [];
        var startIndex = resultsPerPage * (currentPage - 1);
        var endIndex = startIndex + resultsPerPage;
        if (endIndex > fullList.length) {
            endIndex = fullList.length;
        }
        for (var i = startIndex; i < endIndex; i++) {
            newList.push(fullList[i]);
        }
        setVisibleList(newList);
    }, [fullList, resultsPerPage, setVisibleList]);

    const init = useCallback(() => {
        var numPag = Math.ceil(fullList.length / resultsPerPage);
        var list = [];
        for (var i = 1; i <= numPag; i++) {
            list.push(i);
        }
        setPageList(list);
    }, [fullList.length, resultsPerPage]);

    useEffect(() => {
        init();
    }, [init, fullList]);

    useEffect(() => {
        setPage(currentPage);
    }, [currentPage, setPage]);

    const pageDown = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const pageUp = () => {
        if (currentPage < pageList.length) {
            setCurrentPage(currentPage + 1);
        }
    }

    return (
        <>{
            (fullList && fullList.length) ? (
                <nav className="navbar justify-content-center">
                    <ul className="pagination">
                        <li className={currentPage <= 1 ? "page-item disabled" : "page-item"}>
                            <button onClick={pageDown} className="page-link">Precedente</button>
                        </li>
                        {
                            pageList.map(function (p) {
                                return (
                                    <li onClick={() => setCurrentPage(p)} key={"page_" + p} className={p === currentPage ? "page-item active" : "page-item"}>
                                        <button className="page-link">{p}</button>
                                    </li>
                                )
                            })
                        }
                        <li className={currentPage >= pageList.length ? "page-item disabled" : "page-item"}>
                            <button onClick={pageUp} className="page-link">Successivo</button>
                        </li>
                    </ul>
                </nav>) : (
                <div className="col-12 text-center">
                    Nessun elemento da mostrare
                </div>
            )
        }</>
    )
}

export default PaginationBar;