import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

import { useCustomer } from '../../../contexts/customer-context';

import '../database-app.scss';

const DatabaseAppPractices = (props) => {
	const { id } = props.match.params;

	const { listPracticesByCustomer } = useCustomer();

	const [cqs, setCqs] = useState([]);
	const [loans, setLoans] = useState([]);
	const [debts, setDebts] = useState([]);

	useEffect(() => {
		const getPractices = async () => {
			const { data, error } = await listPracticesByCustomer(id);

			if (error != null) return console.error(error);

			setCqs(data[0].cqspractices);
			setLoans(data[0].loanspractices);
			setDebts(data[0].debtspractices);
		}

		getPractices();
	}, [id, listPracticesByCustomer])

	return (
		<div className="database-section-practices">
			<div className="top-page">
				<h3 className="top-page-title">Pratiche del cliente ID: <NavLink target="_blank" to={`/customers/${id}`}>{id}</NavLink></h3>
				<NavLink className="btn btn-primary" to="/database">Torna Indietro</NavLink>
			</div>

			<hr />

			{cqs.length < 1 && loans.length < 1 && debts.length < 1 ? <p>Il cliente non presenta pratiche collegate</p> : null}

			{
				cqs.length > 0 ?
					<>
						<h3>Pratiche Cessione del Quinto</h3>
						<div className="database-list">
							{
								cqs.map((item, index) => {
									return (
										<NavLink target="_blank" className="database-item" to={`/practices-cqs/${item._id}`} key={index}>
											<div className="database-item-data">
												{`ID: ${item._id} | N. Pratica: ${item.nPractice} | N. Contratto: ${item.nContract} | Banca: ${item.bank} | Data creazione: ${moment(item.created_at).format('DD/MM/YYYY')} | Stato: ${item.status}`}
											</div>
										</NavLink>
									)
								})
							}
						</div>

						<hr />
					</>
					: null}

			{
				loans.length > 0 ?
					<>
						<h3>Pratiche Prestito Personale</h3>
						<div className="database-list">
							{
								loans.map((item, index) => {
									return (
										<NavLink target="_blank" className="database-item" to={`/practices-loans/${item._id}`} key={index}>
											<div className="database-item-data">
												{`ID: ${item._id} | N. Pratica: ${item.nPractice} | N. Contratto: ${item.nContract} | Banca: ${item.bank} | Data creazione: ${moment(item.created_at).format('DD/MM/YYYY')} | Stato: ${item.status}`}
											</div>
										</NavLink>
									)
								})
							}
						</div>

						<hr />
					</>
					: null}

			{
				debts.length > 0 ?
					<>
						<h3>Pratiche Gestione Debito</h3>
						<div className="database-list">
							{
								debts.map((item, index) => {
									return (
										<NavLink target="_blank" className="database-item" to={`/practices-debts/${item._id}`} key={index}>
											<div className="database-item-data">
												{`ID: ${item._id} | N. Pratica: ${item.nPractice} | N. Contratto: ${item.nContract} | Banca: ${item.bank} | Data creazione: ${moment(item.created_at).format('DD/MM/YYYY')} | Stato: ${item.status}`}
											</div>
										</NavLink>
									)
								})
							}
						</div>

						<hr />
					</>
					: null}

		</div>
	)
}

export default DatabaseAppPractices;