import React, { useState, useEffect } from 'react';
import { InputSelect } from '../../forms';

const ChooseNewFileModeModalContent = ({ customParams = { fileTypes: [], preSelectedType: null, selectFromArchive: false }, confirm = () => { }, closeFunction = () => { } }) => {
	const [canConfirm, setCanConfirm] = useState(false);
	const [selectedType, setSelectedType] = useState("");

	useEffect(() => {
		if (!!selectedType) {
			setCanConfirm(true);
		} else {
			setCanConfirm(false);
		}
	}, [selectedType]);

	useEffect(() => {
		if (customParams && customParams.preSelectedType) {
			setSelectedType(customParams.preSelectedType);
		}
	}, [customParams]);

	const doConfirm = (isNew) => {
		if (canConfirm) {
			confirm(selectedType, isNew);
			closeFunction();
		}
	}
	return (
		<>
			<div className="modal-body">
				<div className=" justify-content-center row">
					<div className="col-12">
						{
							customParams.fileTypes && customParams.fileTypes.length ?
								<div className="row">
									{!customParams.preSelectedType ?
										<div className="col-8 offset-2">
											<InputSelect name="fileType" isRequired={true} label="Seleziona il tipo di file" defaultValue={selectedType} data={customParams.fileTypes} onChange={setSelectedType} />
										</div> : null}
									<div className="col-8 offset-2 mt-3">
										<div className="btn-group w-100" role="group">
											{
												canConfirm ?
													<>
														<button data-bs-dismiss="modal" onClick={() => doConfirm(true)} className="btn btn-primary">
															Carica dal tuo PC
														</button>
														{customParams.selectFromArchive && <button data-bs-dismiss="modal" onClick={() => doConfirm(false)} className="btn btn-secondary">
															Seleziona da archivio
														</button>}
													</>
													:
													<>
														<button disabled className="btn btn-primary">
															Carica dal tuo PC
														</button>
														{customParams.selectFromArchive && <button disabled className="btn btn-secondary">
															Seleziona da archivio
														</button>}
													</>
											}
										</div>
									</div>
								</div>
								:
								<h6 className="text-center">Nessun tipo di file selezionabile...</h6>
						}
					</div>
				</div>
			</div>
			<div className="modal-footer">
				<button onClick={closeFunction} type="button" className="btn btn-primary" data-bs-dismiss="modal">Annulla</button>
			</div>
		</>
	)
}

export default ChooseNewFileModeModalContent;