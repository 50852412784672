import React from 'react';

import { useUser } from '../../../contexts/user-context';
import { NewPageWrapper, NewPageWrapperCopy } from '../../../ui-components/custom-components';
import FormUser from '../../../ui-components/forms-components/user';

const NewUser = ({ setShowNewUser, setUsers }) => {
	const { newUser, getUsers } = useUser();

	const onSubmit = async (form) => {
		const { error } = await newUser(form);

		if (error !==null) {
			console.log(error.response);
			return ;
		}

		const { data } = await getUsers();
		setUsers(data.data);
		setShowNewUser(false);
	}

	return (
		<NewPageWrapper className="new-user-page new-page">
			<NewPageWrapperCopy>
				<div className="top-page">
					<h3 className="top-page-title">Nuovo Utente</h3>
					<button className="close-button" onClick={() => setShowNewUser(false)}>&times;</button>
				</div>

				<hr />

				<FormUser handleSave={onSubmit} />
			</NewPageWrapperCopy>
		</NewPageWrapper>
	)
}

export default NewUser;