import { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { InputText, InputEmail } from "../../forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

const StepForm = styled.section``;

const FormBank = ({ isDisable = false, bank = null, handleSave }) => {
  const [stepOpened] = useState(0);
  const [name, setName] = useState(bank != null ? bank.name : "");
  const [headquarters, setHeadquarters] = useState(bank != null ? bank.headquarters : "");
  const [pec, setPec] = useState(bank != null ? bank.pec : [""]);

  const { register, handleSubmit, reset } = useForm({});

  const onSubmit = async () => {
    let obj = {
      name,
      headquarters,
      pec,
    };

    await handleSave(obj);
  };

  const addPec = () => {
    setPec([...pec, ""]);
  };

  const removePec = (index) => {
    const newPecList = pec.filter((_, idx) => idx !== index);
    setPec(newPecList);
    /* For update default value on react useForm */
    reset(newPecList, { keepDefaultValues: true });
  };

  const updatePec = (index, value) => {
    const newPecList = [...pec];
    newPecList[index] = value;
    setPec(newPecList);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="alert alert-info">
          <strong>TUTTI I CAMPI SEGUITI DA * SONO OBBLIGATORI</strong>
        </div>

        <StepForm style={{ display: stepOpened === 0 ? "block" : "none" }}>
          <div className="row">
            <div className="col-md-6">
              <InputText
                defaultValue={name}
                onChange={setName}
                disabled={isDisable}
                name="name"
                label="Nome Banca"
                isRequired={true}
                register={register}
              />
            </div>

            <div className="col-md-6">
              <InputText
                defaultValue={headquarters}
                onChange={setHeadquarters}
                disabled={isDisable}
                name="headquarters"
                label="Sede Centrale"
                isRequired={false}
                register={register}
              />
            </div>
            <div className="col-12 mt-3">
              <div className="d-flex flex-row align-items-end justify-content-between">
                <div>
                  <h5 className="p-0 m-0">
                    <b>Indirizzi PEC</b>
                  </h5>
                </div>
                <button className="btn btn-sm btn-primary d-flex flex-row gap-2 align-items-center" onClick={addPec}>
                  <FontAwesomeIcon icon={faPlus} />
                  <div className="">Aggiungi PEC</div>
                </button>
              </div>
            </div>

            <div className="col-12">
              <table className="table table-striped mt-3">
                <thead className="thead-dark">
                  <tr>
                    <th style={{ width: "3%" }}>N°</th>
                    <th style={{ width: "93%" }}>Indirizzo PEC</th>
                    <th style={{ width: "4%" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {pec.map((pecItem, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <InputEmail
                          defaultValue={pecItem}
                          onChange={(e) => updatePec(index, e)}
                          disabled={isDisable}
                          name={`pec_${index}`}
                          className="form-control"
                          isRequired={true}
                          hideLabel={true}
                          register={register}
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          title="Rimuovi Indirizzo PEC"
                          className="btn btn-danger w-100"
                          disabled={pec.length === 1}
                          onClick={() => {
                            removePec(index);
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </StepForm>
        <hr />
        <input type="submit" className={"btn btn-primary mx-2"} value="Conferma" />
      </form>
    </>
  );
};

export default FormBank;
