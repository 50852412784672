import React, { useEffect, useState } from 'react';

import { NewPageWrapper, NewPageWrapperCopy } from '../../custom-components';
import { InputNumber } from '../../forms';

const Calcs = ({ setShowCalcs, setCalcs, calcs = null, setHaveCalcs }) => {
	const [nRate, setNRate] = useState(calcs === null ? 0 : calcs.nRate);
	const [nRateMax, setNRateMax] = useState(calcs === null ? 1 : calcs.nRateMax);
	const [percDur, setPercDur] = useState(calcs === null ? 0 : calcs.percDur);
	const [percSpes, setPercSpes] = useState(calcs === null ? 0 : calcs.percSpes);
	const [installmentMonthly, setInstallmentMonthly] = useState(calcs === null ? 0 : calcs.installmentMonthly);
	const [expenseCommFin, setExpenseCommFin] = useState(calcs === null ? 0 : calcs.expenseCommFin);
	const [expenseCommInt, setExpenseCommInt] = useState(calcs === null ? 0 : calcs.expenseCommInt);
	const [expenseAssRim, setExpenseAssRim] = useState(calcs === null ? 0 : calcs.expenseAssRim);
	const [expenseImpost, setExpenseImpost] = useState(calcs === null ? 0 : calcs.expenseImpost);
	const [spesa, setSpesa] = useState(calcs === null ? 0 : calcs.spesa);

	const handleSaveData = async () => {
		const data = {
			nRate,
			nRateMax,
			percDur,
			percSpes,
			installmentMonthly,
			expenseCommFin,
			expenseCommInt,
			expenseAssRim,
			expenseImpost,
			spesa
		}

		setCalcs(data);
		setHaveCalcs(true);
		setShowCalcs(false);
	}

	useEffect(() => {
		const nRateMaxFix = nRateMax || 1;
		setPercDur(nRate / nRateMaxFix);
		setPercSpes(1 - (nRate / nRateMaxFix));
	}, [nRateMax, nRate])

	return (
		<NewPageWrapper>
			<NewPageWrapperCopy>
				<div className="top-page">
					<h3 className="top-page-title">Genera Calcoli</h3>
					<button className="close-button" onClick={() => setShowCalcs(false)}>&times;</button>
				</div>

				<hr />

				{/* First step */}
				<div className="step">
					<p className="fw-bold">Numero rate da pagere:</p>
					<div className="mb-3">
						<InputNumber defaultValue={nRate} type="number" style={{ display: 'inline', width: '150px' }} name="n-rate" step="1" onChange={setNRate} />
						<span className="mx-3">SU</span>
						<InputNumber defaultValue={nRateMax} type="number" style={{ display: 'inline', width: '150px' }} name="n-rate-max" step="1" onChange={setNRateMax} />
					</div>

					<p><strong>Percentuale Durata Contratto:</strong> {`${parseFloat(percDur).toFixed(1)} / ${parseFloat((percDur) * 100).toFixed(0)}%`}</p>
					<p><strong>Percentuale Spese da Stornare:</strong> {`${parseFloat(percSpes).toFixed(1)} / ${parseFloat((percSpes) * 100).toFixed(0)}%`}</p>
				</div>

				<hr />

				{/* Secondo step */}
				<div className="step">
					<div className="row mb-2">
						<div className="col-md-4">
							<InputNumber defaultValue={installmentMonthly} validation={null} price={true} type="number" label="Importo della Rata" name="installmentMonthly" step="0.01" onChange={setInstallmentMonthly} />
						</div>
						<div className="col-md-4">
							<InputNumber defaultValue={expenseCommFin} validation={null} price={true} type="number" label="Spesa Commissione Finanziaria" name="expenseCommFin" step="0.01" onChange={setExpenseCommFin} />
						</div>
						<div className="col-md-4">
							<InputNumber defaultValue={expenseCommInt} validation={null} price={true} type="number" label="Spesa Commissione Intermedio del Credito" name="expenseCommInt" step="0.01" onChange={setExpenseCommInt} />
						</div>
					</div>

					<div className="row mb-2">
						<div className="col-md-4">
							<InputNumber defaultValue={expenseAssRim} validation={null} price={true} type="number" label="Spesa Assicurazione Rimborso Credito" name="expenseAssRim" step="0.01" onChange={setExpenseAssRim} />
						</div>
						<div className="col-md-4">
							<InputNumber defaultValue={spesa} validation={null} price={true} type="number" label="Spese Istruttoria" name="spesa" step="0.01" onChange={setSpesa} />
						</div>
						<div className="col-md-4">
							<InputNumber defaultValue={expenseImpost} validation={null} price={true} type="number" label="Spesa Imposta di Bollo" name="expenseImpost" step="0.01" onChange={setExpenseImpost} />
						</div>
					</div>
				</div>

				<hr />

				<button type="button" className="btn btn-primary" onClick={handleSaveData}>Conferma</button>

			</NewPageWrapperCopy>
		</NewPageWrapper>
	)
}

export default Calcs;