import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useEthosModal } from "../../../contexts/ethos-modal-context";

const DocumentListModalContent = ({
  customParams = {
    fileList: [],
    customColumns: [],
    customActions: [],
    singleSelection: false,
    typeFilter: null,
    allowDelete: false,
    allowSelection: false,
    deleteMessage: null,
    customerScreeningInfo: null,
    doDownloadFile: () => {},
    doDeleteFile: () => {},
  },
  confirm = () => {},
  closeFunction = () => {},
}) => {
  const [visibleFiles, setVisibleFiles] = useState([]);
  const [canConfirm, setCanConfirm] = useState(false);
  const { openEthosConfirmModal } = useEthosModal();

  useEffect(() => {
    if (customParams && customParams.fileList) {
      var lista = [];
      for (var i = 0; i < customParams.fileList.length; i++) {
        if (!customParams.typeFilter || customParams.typeFilter === customParams.fileList[i].fileType) {
          lista.push({ ...customParams.fileList[i] });
        }
      }
    }
    setVisibleFiles(lista);
  }, [customParams]);

  const doConfirm = () => {
    if (canConfirm) {
      var selectedFiles = visibleFiles.filter((x) => x.selected);
      if (selectedFiles.length) {
        confirm(selectedFiles);
      }
      closeFunction();
    }
  };

  const toggleSelect = (index) => {
    var newList = [];
    for (var i = 0; i < visibleFiles.length; i++) {
      var obj = {
        ...visibleFiles[i],
      };
      if (i === index) {
        obj.selected = !obj.selected;
      } else {
        if (customParams.singleSelection) {
          obj.selected = false;
        }
      }
      newList.push(obj);
    }
    setVisibleFiles(newList);
    setCanConfirm(newList.filter((x) => x.selected).length);
  };

  const doDeleteFileInner = (f) => {
    const deleteFileCallback = async (f) => {
      f.deleted = true;
      customParams.doDeleteFile(f);
    };

    openEthosConfirmModal(
      customParams.deleteMessage ? customParams.deleteMessage : "Sicuro di voler eliminare il file?",
      deleteFileCallback,
      f,
      "Conferma eliminazione"
    );
  };


  return (
    <>
      <div className="modal-body">
        <div className=" justify-content-center row">
          <div className="col-12">
            {visibleFiles && visibleFiles.length ? (
              <table className="table table-striped">
                <tbody>
                  <tr>
                    {customParams.allowSelection ? <th className="text-center" style={{ width: "8%" }}></th> : null}
                    <th className="text-center">Nome File</th>
                    <th className="text-center">Caricato il</th>
                    <th className="text-center">Scadenza</th>
                    {customParams.customerScreeningInfo && customParams.customerScreeningInfo.length > 1 && <th>Cliente</th>}
                    {customParams.customColumns &&
                      customParams.customColumns.length &&
                      customParams.customColumns.map(function (c, index) {
                        return <th key={"custom_column_" + index}>{c.label}</th>;
                      })}
                    <th className="text-center">Azioni</th>
                  </tr>
                  {visibleFiles.map(function (f, index) {
                    const customer =
                      customParams.customerScreeningInfo && customParams.customerScreeningInfo.find((cust) => cust._id === f.customerId);
                    return !f.deleted ? (
                      <tr key={"doc_list_modal_trow_" + index}>
                        {customParams.allowSelection ? (
                          f.selected ? (
                            <td onClick={() => toggleSelect(index)} className="text-center">
                              <FontAwesomeIcon className="text-success" icon={faCheck} />
                            </td>
                          ) : (
                            <td onClick={() => toggleSelect(index)} className="text-center"></td>
                          )
                        ) : null}
                        <td className="text-center" onClick={() => toggleSelect(index)}>
                          <span>{f.filename}</span>
                        </td>
                        <td className="text-center" onClick={() => toggleSelect(index)}>
                          {f.updatedAt ? <span>{moment(f.updatedAt).format("DD/MM/YYYY hh:mm:ss")}</span> : <span>N.D.</span>}
                        </td>
                        <td className="text-center" onClick={() => toggleSelect(index)}>
                          {f.expirationDate ? <span>{moment(f.expirationDate).format("DD/MM/YYYY")}</span> : <span>N.D.</span>}
                        </td>
                        {customParams.customerScreeningInfo && customParams.customerScreeningInfo.length > 1 && (
                          <td>
                            {customer.first_name} {customer.last_name}
                          </td>
                        )}
                        {customParams.customColumns &&
                          customParams.customColumns.length &&
                          customParams.customColumns.map(function (c, cindex) {
                            return <th key={"custom_column_" + cindex}>{f[c.value]}</th>;
                          })}
                        <td className="text-center">
                          <div className="btn-group w-100" role="group">
                            <span onClick={() => customParams.doDownloadFile(f)} className="btn btn-sm btn-primary">
                              Scarica
                            </span>
                            {customParams.allowDelete ? (
                              <span onClick={() => doDeleteFileInner(f)} className="btn btn-sm btn-danger">
                                Elimina
                              </span>
                            ) : null}
                            {customParams.customActions &&
                              customParams.customActions.length &&
                              customParams.customActions.map(function (c, cindex) {
                                return (
                                  <span key={"custom_action_" + cindex} onClick={() => c.action(f)} className="btn btn-sm btn-secondary">
                                    {c.label}
                                  </span>
                                );
                              })}
                          </div>
                        </td>
                      </tr>
                    ) : null;
                  })}
                </tbody>
              </table>
            ) : (
              <h6 className="text-center">Nessun file da mostrare...</h6>
            )}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        {customParams.allowSelection ? (
          <>
            <button onClick={closeFunction} type="button" className="btn btn-secondary" data-bs-dismiss="modal">
              Annulla
            </button>
            <button disabled={!canConfirm} onClick={() => doConfirm()} data-bs-dismiss="modal" type="button" className="btn btn-primary">
              Conferma
            </button>
          </>
        ) : (
          <button onClick={closeFunction} type="button" className="btn btn-primary" data-bs-dismiss="modal">
            Chiudi
          </button>
        )}
      </div>
    </>
  );
};

export default DocumentListModalContent;
