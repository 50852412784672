import React, { useCallback } from 'react'
import { Auth } from 'aws-amplify';
import { httpGet, httpPatch, httpCustomPatch, httpPost, uploadFileToS3, httpPut } from '../http'
import { SERVICES_ENDPOINT } from '../constants/API_ENDPOINT'
import { useEthosNotification } from './ethos-notification-context';

const ServiceContext = React.createContext()

function ServiceProvider(props) {
	const { registerPendingRequest, unregisterPendingRequest } = useEthosNotification();

	const updateService = useCallback(async (service) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPatch(`${SERVICES_ENDPOINT}`, jwtToken, service, service._id);
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getServices = useCallback(async () => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${SERVICES_ENDPOINT}/`, jwtToken, {});

			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const uploadServiceTemplate = useCallback(async (serviceId, templateType, file, filename) => {
		if (!file) return;
		var re = /(?:\.([^.]+))?$/;
		const fileExtension = re.exec(file.name)[1];

		const body = {
			ext: fileExtension,
			filename: filename,
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			// const result = await httpCustomPatch(`${SERVICES_ENDPOINT}/${serviceId}/upload/url/${templateType}`, jwtToken, body);

			const response = await httpPost(`${SERVICES_ENDPOINT}/${serviceId}/upload/url/${templateType}`, jwtToken, body);

			await uploadFileToS3(response.data.url, file);

			body.fileId = response.data.fileId;
			const docResponse = await httpPut(`${SERVICES_ENDPOINT}/${serviceId}/upload/url/${templateType}`, jwtToken, body);



			return {
				data: docResponse.data,
				error: null,
				meta: null,
				status: docResponse.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const associateServiceTemplate = useCallback(async (serviceId, fileId) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpCustomPatch(`${SERVICES_ENDPOINT}/` + serviceId + "/add/" + fileId, jwtToken, {});
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	return (
		<ServiceContext.Provider
			value={{
				getServices,
				uploadServiceTemplate,
				associateServiceTemplate,
				updateService
			}}
			{...props}
		/>
	)
}
const useService = () => React.useContext(ServiceContext)

export { ServiceProvider, useService }