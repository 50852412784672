/* eslint-disable @typescript-eslint/no-unused-vars */
import { Prompt } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { NewPageWrapper, NewPageWrapperCopy } from "../../ui-components/custom-components";
import "./service-customer-modal-assign.css";
import { useCustomer } from "../../contexts/customer-context";
import { ServiceCustomerRelative } from "../../models";

interface ServiceRelativeCustomerProps {
  setOpenRelativeModal: (value: boolean) => void;
  customerRelative: [];
  existingRelation: ServiceCustomerRelative[];
  updateRelative: (id: string, item: any[]) => void;
  serviceID: string;
}

const ServiceRelativeCustomer = ({
  setOpenRelativeModal,
  customerRelative,
  serviceID,
  existingRelation,
  updateRelative,
}: ServiceRelativeCustomerProps) => {
  const { getSingleCustomer } = useCustomer();

  const [pendingChange, setPendingChange] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [selectedRelative, setSelectiveRelative] = useState(new Map<string, ServiceCustomerRelative>());

  const [relative, setRelative] = useState([]);
  const [relativesInfo, setRelativesInfo] = useState([]);

  const getRelativeInfo = useCallback(
    async (id) => {
      try {
        const { data, error } = await getSingleCustomer(id);

        if (error) {
          return;
        }

        return { _id: data._id, first_name: data.first_name, last_name: data.last_name, cf: data.cf ? data.cf : "N.D" };
      } catch (error) {
        console.log(error);
      }
    },
    [getSingleCustomer]
  );

  /* Mounting compoenente */
  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  /* Polulate relative from props */
  useEffect(() => {
    if (customerRelative && customerRelative.length > 0) {
      /* Remove from array if has been selected */
      const relative = customerRelative.filter((item: any) => !existingRelation.includes(item.relativeId));
      setRelative(relative);
    }
  }, [existingRelation, customerRelative]);

  /* Get Info */
  useEffect(() => {
    const fetchAllRelativesInfo = async () => {
      const infoPromises = relative.map((item) => getRelativeInfo(item.relativeId));
      const results = await Promise.all(infoPromises);
      setRelativesInfo(results);
    };

    if (relative.length > 0) {
      fetchAllRelativesInfo();
    }
  }, [getRelativeInfo, relative]);

  return (
    <NewPageWrapper className="new-relative-page new-page">
      <NewPageWrapperCopy>
        <div className="top-page">
          <h3 className="top-page-title">Associa cliente al Servizio</h3>
          <button className="close-button" onClick={() => setOpenRelativeModal(false)}>
            &times;
          </button>
        </div>
        {/* TODO:DEBUG, TO REMOVE*/}
        <hr />
        {relative && relative.length === 0 ? (
          <div className="d-flex justify-content-center align-item-center">
            <h4 className="py-4">Tutti i parenti sono già stati associati</h4>
          </div>
        ) : null}
        {relative && relative.length > 0 ? (
          <table className="table table-striped mt-3">
            <thead className="thead-dark">
              <tr>
                <th>Seleziona</th>
                <th>Nome</th>
                <th>Cognome</th>
                <th>CF</th>
                <th>Parentela</th>
              </tr>
            </thead>
            {relative.length === relativesInfo.length && (
              <tbody>
                {relative.map((item: any) => {
                  const relativeInfo = relativesInfo.find((customerInfo) => customerInfo._id === item.relativeId);
                  return (
                    <tr key={item.relativeId}>
                      <td>
                        <input
                          type="checkbox"
                          name="select_customer"
                          id={item._id}
                          title="Select customer"
                          onChange={() => {
                            const newSelectedRelative = new Map<string, { _id: string; relativeId: string; relation: string }>(selectedRelative);
                            if (newSelectedRelative.has(item._id)) {
                              newSelectedRelative.delete(item._id);
                            } else {
                              newSelectedRelative.set(item._id, { _id: item._id, relativeId: item.relativeId, relation: item.relation });
                            }
                            setSelectiveRelative(newSelectedRelative);
                          }}
                        />
                      </td>
                      <td>{relativeInfo ? relativeInfo.first_name : "N.D."}</td>
                      <td>{relativeInfo ? relativeInfo.last_name : "N.D."}</td>
                      <td>{relativeInfo ? relativeInfo.cf : "N.D."}</td>
                      <td>{item.relation}</td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        ) : null}

        <hr />
        <div className="d-flex flex-row justify-content-end align-items-center">
          <button
            disabled={selectedRelative.size === 0}
            type="button"
            className="btn btn-sm btn-primary"
            onClick={async () => {
              const addRelationTest = Array.from(selectedRelative.values());
              updateRelative(serviceID, addRelationTest);
              setOpenRelativeModal(false);
            }}
          >
            Associa
          </button>
        </div>
      </NewPageWrapperCopy>
      <Prompt when={pendingChange} message="Ci sono modifiche in corso, sei sicuro di uscire?" />
    </NewPageWrapper>
  );
};

export default ServiceRelativeCustomer;
