import React, { useState, useEffect, createContext } from "react";
import { SYSTEM_ENDPOINT } from '../constants/API_ENDPOINT'
import { useEthosNotification } from "./ethos-notification-context";
import { Auth } from 'aws-amplify';
import { httpGet } from '../http';

const ConfigurationsContext = createContext();

function ConfigurationsContextProvider(props) {
	const { registerPendingRequest, unregisterPendingRequest, handleError } = useEthosNotification();
	const [configurations, setConfigurations] = useState(null);

	useEffect(() => {
		const loadConfigurations = async () => {
			try {
				registerPendingRequest();
				const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
				const result = await httpGet(`${SYSTEM_ENDPOINT}/configuration`, jwtToken);
				setConfigurations(result.data);
			} catch (err) {
				handleError(err);
			} finally {
				unregisterPendingRequest();
			}
		};

		loadConfigurations();
	}, [handleError, registerPendingRequest, unregisterPendingRequest]);



	return (
		<ConfigurationsContext.Provider
			value={{
				configurations
			}}
			{...props}
		/>
	)
};

const useConfigurations = () => React.useContext(ConfigurationsContext)

export { ConfigurationsContextProvider, useConfigurations }