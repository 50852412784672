import React, { useEffect, useState, useRef } from 'react';

import { InputNumber, InputTextArea, InputText, InputDate, InputEmail } from '../../../forms';
import FamilySection from './family-section';

const RegistrySection = ({ dataToSubmit = {}, setDataToSubmit = () => { }, customer = null, isDisable = false, register = () => { } }) => {
	const [firstName, setFirstName] = useState(customer !== null ? customer.first_name : '');
	const [lastName, setLastName] = useState(customer !== null ? customer.last_name : '');
	const [email, setEmail] = useState(customer !== null ? customer.email : '');
	const [phone, setPhone] = useState(customer !== null ? customer.phone : '');
	const [dateBirth, setDateBirth] = useState(customer !== null ? customer.datebirth : '');
	const [cityBirth, setCityBirth] = useState(customer !== null ? customer.citybirth : '');
	const [pec, setPec] = useState(customer !== null ? customer.pec : '');
	const [cf, setCf] = useState(customer !== null ? customer.cf : '');
	const [piva, setPiva] = useState(customer !== null ? customer.piva : '');
	const [address, setAddress] = useState(customer !== null ? customer.address : '');
	const [postcode, setPostcode] = useState(customer !== null ? customer.postcode : '');
	const [city, setCity] = useState(customer !== null ? customer.city : '');
	const [prov, setProv] = useState(customer !== null ? customer.prov : '');
	const [note] = useState(customer !== null ? customer.note : '');
	const [additionalInfo, setAdditionalInfo] = useState(customer !== null ? customer.additionalInfo : {});
	const [campaign] = useState(customer !== null ? customer.campaign : '-');
	const didMount = useRef(false);

	useEffect(() => {
		didMount.current = false;
	}, [customer]);

	useEffect(() => {

		if (!didMount.current) {
			return didMount.current = true;
		}
		const data = {
			...dataToSubmit,
			first_name: firstName,
			last_name: lastName,
			email: email,
			phone: phone,
			datebirth: dateBirth,
			citybirth: cityBirth,
			pec: pec,
			cf: cf,
			piva: piva,
			address: address,
			postcode: postcode,
			city: city,
			prov: prov,
			note: note,
			additionalInfo: additionalInfo,
		}
		setDataToSubmit(data);
		// eslint-disable-next-line
	}, [firstName, lastName, email, phone, dateBirth, cityBirth, pec, cf, piva, address, postcode, city, prov, note, additionalInfo]);


	return (
		<>
			<div className="row mb-3">
				<div className="col-md-6">
					<InputText  disabled={true} defaultValue={campaign} name="campaign" label="Campagna" />
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md-6">
					<InputText onChange={setFirstName}  disabled={isDisable} defaultValue={firstName} name="first_name" label="Nome" register={register} isRequired={customer != null && customer.status !== "PROSPECT"} />
				</div>
				<div className="col-md-6">
					<InputText onChange={setLastName}  disabled={isDisable} defaultValue={lastName} name="last_name" label="Cognome" register={register} isRequired={customer != null && customer.status !== "PROSPECT"} />
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md-6">
					<InputEmail onChange={setEmail} disabled={isDisable} defaultValue={email} name="email" label="Email" register={register} isRequired={!phone} />
				</div>
				<div className="col-md-6">
					<InputNumber onChange={setPhone}  disabled={isDisable} defaultValue={phone} name="phone" validation={/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/} label="Numero di Telefono" register={register} isRequired={!email} />
				</div>
			</div>
			<div className="row mb-3">
				<div className="col-md-6">
					<InputDate onChange={setDateBirth} disabled={isDisable} defaultValue={dateBirth} name="datebirth" label="Data di Nascita" register={register} />
				</div>
				<div className="col-md-6">
					<InputText onChange={setCityBirth}  disabled={isDisable} defaultValue={cityBirth} name="citybirth" label="Luogo di Nascita" register={register} />
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md-4">
					<InputEmail onChange={setPec} disabled={isDisable} defaultValue={pec} name="pec" label="PEC" register={register} />
				</div>
				<div className="col-md-4">
					<InputText onChange={setCf}  disabled={isDisable} defaultValue={cf} name="cf" label="Codice Fiscale" register={register} validation={/^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/} />
				</div>
				<div className="col-md-4">
					<InputText onChange={setPiva}  disabled={isDisable} defaultValue={piva} name="piva" validation={/^[0-9]{11}$/} label="Partita IVA" register={register} />
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md-6">
					<InputText onChange={setAddress}  disabled={isDisable} defaultValue={address} name="address" label="Indirizzo" register={register} />
				</div>
				<div className="col-md-6">
					<InputText onChange={setPostcode}  disabled={isDisable} defaultValue={postcode} name="postcode" label="CAP" register={register} />
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md-6">
					<InputText onChange={setCity}  disabled={isDisable} defaultValue={city} name="city" label="Città" register={register} />
				</div>
				<div className="col-md-6">
					<InputText onChange={setProv}  disabled={isDisable} defaultValue={prov} name="prov" label="Provincia" register={register} />
				</div>
			</div>

			{!!customer && <>
				<h2>Domande introduttive</h2>

				<div className="mb-3">
					<InputTextArea
						onChange={(value) => setAdditionalInfo({ ...additionalInfo, question1: value })}
						
						disabled={isDisable}
						defaultValue={additionalInfo?.question1}
						name="question1"
						label="PER COSA CI CHIAMA? QUALI SONO LE SUE PROBLEMATICHE?"
						register={register} />
				</div>
				<div className="mb-3">
					<InputTextArea
						onChange={(value) => setAdditionalInfo({ ...additionalInfo, question2: value })}
						
						disabled={isDisable}
						defaultValue={additionalInfo?.question2}
						name="question2"
						label="HA GIA’ PROVATO A RISOLVERE IL PROBLEMA CON SUOI AVVOCATI O ALTRE SOCIETA’?"
						description="Se sì, farsi dare il contatto e nome dell’avvocato o della società che sta gestendo"
						register={register} />
				</div>
				<div className="mb-3">
					<InputTextArea
						onChange={(value) => setAdditionalInfo({ ...additionalInfo, question3: value })}
						
						disabled={isDisable}
						defaultValue={additionalInfo?.question3}
						name="question3"
						label="QUALI SONO I SUOI OBIETIVI?"
						description="esempio: Salvare la casa, evitare il pignoramento, fare un nuovo finanziamento"
						register={register} />
				</div>
			</>}
			{!!customer && !["DISCARDED"].includes(customer.status) && <>
				<h2>Famiglia</h2>
				<FamilySection dataToSubmit={dataToSubmit} setDataToSubmit={setDataToSubmit} customer={customer} isDisable={isDisable} />
			</>}

		</>
	)
}

export default RegistrySection;