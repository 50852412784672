import { useState, useEffect, useCallback } from 'react';
import { useUser } from '../../contexts/user-context';
import { useActivities } from '../../contexts/activities-context';
import PaginationBar from '../../ui-components/pagination-bar';
import moment from 'moment';
import { useEthosNotification } from '../../contexts/ethos-notification-context';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useConfigurations } from '../../contexts/configurations-context';
import { useEthosModal } from '../../contexts/ethos-modal-context';
import { getActivityStatusLabel, getActivityTypeLabel } from '../../utils/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

const LIMIT = 100;
const OFFSET = 0;

const SummaryActivities = () => {
    const { getUsers } = useUser()
    const { configurations } = useConfigurations();
    const { saveNewActivity, editActivity, deleteActivity, getAll,sendResumeByEmail } = useActivities()
    const { user } = useAuthenticator();
    const [activities, setActivities] = useState([]);
    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    // const [filteredActivities, setFilteredActivities] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('today');
    const [visibleActivities, setVisibleActivities] = useState([]);
    const { showSuccessToast, handleError } = useEthosNotification();
    const { openActivityModal, openNotesModal, openEthosConfirmModal } = useEthosModal();

    const [sortColumn, setSortColumn] = useState({ column: null, direction: 0 });

    const [sortFilter,setSortFilter] = useState(null);

    const loadActivities = useCallback(async () => {
        if (!currentUser) return;
        if (!selectedFilter) return;

        var params = {};
        params.limit = LIMIT;
        params.offset = OFFSET;
        params.owner = currentUser._id;
        params.state = "PLANNED";
        params.sort= sortFilter

        var start = null;
        var end = null;
        switch (selectedFilter) {
            case 'today':
                start = new Date();
                start.setUTCHours(0, 0, 0, 0);
                end = new Date();
                end.setUTCHours(23, 59, 59, 999);
                params.dateFrom = start;
                params.dateTo = end;
                break;
            case 'this-week':
                start = new Date();
                start.setUTCHours(0, 0, 0, 0);
                end = new Date();
                const next7Days = end.getDate() + 7;
                end.setDate(next7Days)
                end.setUTCHours(23, 59, 59, 999);
                params.dateFrom = start;
                params.dateTo = end;
                break;
            default:
                break;
        }

        const { data, error } = await getAll(params);
        if (error) {
            handleError(error);
            return;
        }
        setActivities(data.data);
        // const limit = (!!data & data.length > 0) ? data[0].ActivityData.length : 0;
        // if (limit > 0) {
        //     for (var i = 0; i < limit; i++) {
        //         if (!!data[0].ActivityData[i].customerId) {
        //             const custRes = await getSingleCustomer(data[0].ActivityData[i].customerId);
        //             if (custRes.error) {
        //                 handleError(custRes.error);
        //                 return;
        //             }
        //             data[0].ActivityData[i].customer = custRes.data;
        //         }
        //     }
        //     var newVal = data[0].ActivityData;
        //     newVal.sort(function (a, b) {
        //         return new Date(b.activityDate) - new Date(a.activityDate);
        //     });
        //     setActivities(newVal);
        // }
    }, [currentUser, selectedFilter, sortFilter, getAll, handleError]);

    const getCustomerName = (act) => {
        if (!act || !act.customerdata || !act.customerdata.length) return "N.D.";
        const cust = act.customerdata.find(c => c._id === act.customerId)
        return cust ? cust.first_name + " " + cust.last_name : "N.D.";
    }

    const loadUsers = useCallback(async () => {
        var { data, error } = await getUsers(null, null, null, 0);
        if (error) {
            handleError(error);
            return;
        }
        setUsers(data.data);
    }, [getUsers, handleError])

    useEffect(() => {
        if (!users || !user.username) return;
        const u = users.find(x => x.username === user.username);
        if (!u) return;
        setCurrentUser(u);
    }, [users, user.username]);

    useEffect(() => {
        loadActivities();
    }, [loadActivities])

    useEffect(() => {
        loadUsers()
    }, [loadUsers]);

    const saveActivity = async (newActivity) => {
        if (newActivity._id) {
            doUpdateActivity(newActivity);
        } else {
            addActivity(newActivity);
        }
    }

    const addActivity = async (newActivity) => {
        const { error } = await saveNewActivity(newActivity);
        if (error) {
            handleError(error);
            return;
        }

        loadActivities();
        showSuccessToast("Attività registrata correttamente");
    }

    const doUpdateActivity = async (activity) => {
        const { error } = await editActivity(activity);
        if (error) {
            handleError(error);
            return;
        }

        loadActivities();
        showSuccessToast("Attività aggiornata correttamente");
    }

    const sendResumeMail = useCallback(
        async (id, activity) => {
          const { error } = await editActivity(activity);
          if (error) {
            handleError(error);
            return;
          }
  
          loadActivities();
          showSuccessToast("Attività aggiornata correttamente");
  
          const sendEmailError = await sendResumeByEmail(id);
          if (sendEmailError.error) {
            handleError(sendEmailError.error);
            return;
          }
        },
        [editActivity, loadActivities, showSuccessToast, sendResumeByEmail, handleError]
      );

    // useEffect(() => {
    //     let tmpApp = null;
    //     switch (selectedFilter) {
    //         case 'today':
    //             tmpApp = activities.filter(
    //                 a => {
    //                     if (!a.activityDate) {
    //                         return false;
    //                     }
    //                     var appDate = moment(a.activityDate).startOf('day');
    //                     var todayDate = moment(new Date()).startOf('day')
    //                     var diffDays = moment.duration(appDate.diff(todayDate)).asDays();
    //                     return 0 === diffDays;
    //                 }
    //             );
    //             break;
    //         case 'this-week':
    //             tmpApp = activities.filter(
    //                 a => {
    //                     if (!a.activityDate) {
    //                         return false;
    //                     }
    //                     var appDate = moment(a.activityDate).startOf('day');
    //                     var todayDate = moment(new Date()).startOf('day')
    //                     var diffDays = moment.duration(appDate.diff(todayDate)).asDays();
    //                     return 0 <= diffDays && diffDays < 7;
    //                 }
    //             );
    //             setFilteredActivities(tmpApp);
    //             break;
    //         case 'all':
    //             tmpApp = activities.filter(
    //                 a => {
    //                     if (!a.activityDate) {
    //                         return false;
    //                     }
    //                     var appDate = moment(a.activityDate).startOf('day');
    //                     var todayDate = moment(new Date()).startOf('day')
    //                     var diffDays = moment.duration(appDate.diff(todayDate)).asDays();
    //                     return 0 <= diffDays;
    //                 }
    //             );
    //             setFilteredActivities(tmpApp);
    //             break;
    //         default:
    //             tmpApp = activities;
    //     }
    //     setFilteredActivities(tmpApp);

    // }, [selectedFilter, activities]);

    const openActivityNotesModal = (note) => {
        openNotesModal(note, "Note dell'attività");
    }

    const doOpenActivityModal = (act) => {
        openActivityModal(saveActivity, configurations, act ? act.customer : null, act, act ? act.customerId : null, act ? act.activityOwner : null, users,sendResumeMail);
    }

    const doDeleteActivity = async (activityId) => {
        const deleteCallback = async () => {
            const { error } = await deleteActivity(activityId);
            if (error) {
                handleError(error);
                return;
            }
            showSuccessToast("Attività eliminata correttamente");
            loadActivities(currentUser);
        }
        openEthosConfirmModal("Sicuro di voler eliminare l'attività?", deleteCallback, null, "Conferma eliminazione");
    }

    const sortingColumn = (columnName) => {
        if (!!sortColumn) {
          if (sortColumn.column === columnName) {
            setSortColumn({
              column: columnName,
              direction: sortColumn.direction === 1 ? -1 : 1,
            });
          } else {
            setSortColumn({ column: columnName, direction: 1 });
          }
        }
      };
  
      const renderSortArrow = (columnName) => {
        if (sortFilter === null) {
          return <FontAwesomeIcon icon={faSort} style={{opacity:".50"}} className='ml-2'/>
        }
        if (!!sortColumn) {
          if (sortColumn.column === columnName) {
            return sortColumn.direction === 1 ? <FontAwesomeIcon icon={faSortUp} className='ml-2'/> : <FontAwesomeIcon icon={faSortDown} className='ml-2'/>;
          }
        }
      };
  
      useEffect(() => {
          if(!!sortColumn.column){
              setSortFilter(`${sortColumn.column}:${sortColumn.direction}`);
          }
      }, [sortColumn]);   

      const resetFilter = useCallback(() => {
        setSortFilter(null);
      }, []);

    return (
      <div className="row">
        <div className="col-12 text-center">
          <h3>Attività pianificate</h3>
          <hr></hr>
          <div className="row text-center mb-5">
            <nav className="navbar justify-content-center navbar-expand-lg navbar-light bg-light">
              <div className="btn-group" role="group" aria-label="Activities Filter">
                <button
                  className={"btn " + ("today" === selectedFilter ? "btn-primary" : "btn-outline-secondary")}
                  onClick={() => setSelectedFilter("today")}
                >
                  Oggi
                </button>
                <button
                  className={"btn " + ("this-week" === selectedFilter ? "btn-primary" : "btn-outline-secondary")}
                  onClick={() => setSelectedFilter("this-week")}
                >
                  Prossimi 7 giorni
                </button>
                <button
                  className={"btn " + ("all" === selectedFilter ? "btn-primary" : "btn-outline-secondary")}
                  onClick={() => setSelectedFilter("all")}
                >
                  Tutti
                </button>
              </div>
            </nav>
            <div className="col-12 text-center">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        sortingColumn("activityType");
                      }}
                      className="text-center"
                    >
                      Tipo Attività {renderSortArrow("activityType")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        sortingColumn("customerdata.first_name");
                      }}
                      className="text-center"
                    >
                      Cliente {renderSortArrow("customerdata.first_name")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        sortingColumn("activityDate");
                      }}
                      className="text-center"
                    >
                      Data {renderSortArrow("activityDate")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        sortingColumn("location");
                      }}
                      className="text-center"
                    >
                      Luogo {renderSortArrow("location")}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        sortingColumn("duration");
                      }}
                      className="text-center"
                    >
                      Durata {renderSortArrow("duration")}
                    </th>
                    <th className="text-center">Stato</th>
                    <th className="text-center">Azioni</th>
                  </tr>
                </thead>
                <tbody>
                  {!!visibleActivities &&
                    !!visibleActivities.length &&
                    visibleActivities.map((act, actIndex) => (
                      <tr key={"activity_row_" + actIndex}>
                        <td className="text-center">{getActivityTypeLabel(act, configurations)}</td>
                        <td className="text-center">
                          <a href={"/customers/" + act.customerId}>
                            <span className="fw-bold">{getCustomerName(act)}</span>
                          </a>
                        </td>
                        <td className="text-center">{moment(act.activityDate).format("DD/MM/yyyy HH:mm")}</td>
                        <td className="text-center">{act.location}</td>
                        <td className="text-center">{act.duration ? act.duration + " minuti" : ""}</td>
                        <td className="text-center">{getActivityStatusLabel(act, configurations)}</td>
                        <td className="text-center">
                          <div className="btn-group" role="group">
                            <button
                              id="btnGroupDrop1"
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Azioni
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                              <li>
                                <button onClick={() => doOpenActivityModal(act)} className="dropdown-item">
                                  Modifica
                                </button>
                              </li>
                              <li>
                                <button onClick={() => openActivityNotesModal(act.note)} className="dropdown-item">
                                  Vedi note
                                </button>
                              </li>
                              <li>
                                <button onClick={() => doDeleteActivity(act._id)} className="dropdown-item text-danger">
                                  Elimina
                                </button>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <PaginationBar fullList={activities} setVisibleList={setVisibleActivities} resultsPerPage={5}></PaginationBar>
              {activities.length > 0 && sortFilter !== null ? (
                <>
                  <button className="btn btn-outline-primary" disabled={sortFilter === null } onClick={()=>{resetFilter()}}> Resetta Filtri</button>
                </>
              ) : null}
            </div>
          </div>
          <hr></hr>
        </div>
      </div>
    );
}

export default SummaryActivities;