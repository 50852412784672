import React from 'react';
import {
	Authenticator,
	Flex,
	Grid,
	useTheme,
	View
} from "@aws-amplify/ui-react";

import { Header } from "./header";
import { Footer } from "./footer";
import { SignInHeader } from "./signInHeader";
import { SignInFooter } from "./signInFooter";
import logoEthos from '../../media/logo-ethos-pdf.jpg';
import { HIDE_SIGNUP } from '../../constants/API_ENDPOINT';

const hideSignUp = HIDE_SIGNUP;

const components = {
	Header,
	SignIn: {
		Header: SignInHeader,
		Footer: SignInFooter
	},
	Footer
};

const formFields = {
	signUp: {      // component
		username: {     // field object
			order: 1   // order key
		},
		email: {
			order: 2
		},
		family_name: {
			label: 'Lastname',
			placeholder: 'Enter your Lastname',
			order: 3
		},
		name: {
			label: 'Firstname',
			placeholder: 'Enter your Firtname',
			order: 4
		},
		password: {
			order: 5
		},
		confirm_password: {
			order: 6
		}
	},
};

export function Login() {
	const { tokens } = useTheme();

	return (
		<Grid templateColumns={{ base: "1fr 0", medium: "1fr 1fr" }}>
			<Flex
				backgroundColor={tokens.colors.background.info}
				justifyContent="center"
			>
				<Authenticator hideSignUp={hideSignUp} components={components} formFields={formFields}>
					{({ signOut, user }) => (
						<main>
							<h1>Hello {user?.username}</h1>
							<button onClick={signOut}>Sign out</button>
						</main>
					)}
				</Authenticator>
			</Flex>
			<View height="100vh">
				<Flex height="100vh" justifyContent="center" alignContent="center">
					<img src={logoEthos} alt="Logo Ethos" style={{ alignSelf: 'center' }} />
				</Flex>
			</View>
		</Grid>
	);
}
