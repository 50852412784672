import React, { useCallback } from 'react'
import { Auth } from 'aws-amplify';
import { httpGet, httpDelete, httpPost, httpPatch } from '../http';
import { useEthosNotification } from './ethos-notification-context';
import { BANKS_ENDPOINT } from '../constants/API_ENDPOINT';

const BanksContext = React.createContext()

function BanksProvider(props) {
	const { registerPendingRequest, unregisterPendingRequest } = useEthosNotification();

	const getBanks = useCallback(async (search = null, filter = null, limit = 12, offset = 0) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${BANKS_ENDPOINT}`, jwtToken, {
				filter: filter,
				search: search,
				limit: limit,
				offset: offset
			});

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getBanksForPractice = useCallback(async () => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${BANKS_ENDPOINT}/practices`, jwtToken, {});

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getSingleBank = useCallback(async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${BANKS_ENDPOINT}/${id}`, jwtToken, {});

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);

	const newBank = useCallback(async (data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPost(`${BANKS_ENDPOINT}`, jwtToken, data);

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);

	const editBank = useCallback(async (id, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPatch(`${BANKS_ENDPOINT}`, jwtToken, data, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);

	const deleteBank = useCallback(async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDelete(`${BANKS_ENDPOINT}`, jwtToken, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}

		return obj;
	}, [registerPendingRequest, unregisterPendingRequest]);


	return (
		<BanksContext.Provider
			value={{
				getBanks,
				getSingleBank,
				getBanksForPractice,
				newBank,
				editBank,
				deleteBank
			}}
			{...props}
		/>
	)
}

const useBank = () => React.useContext(BanksContext)

export { BanksProvider, useBank }