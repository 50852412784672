import React, { useCallback, useState } from "react";
import { InputText, InputDate, InputSelect } from "../../forms";

const UploadFileModalContent = ({
  confirm = () => {},
  closeFunction = () => {},
  customParams = { allowSetFilename: false, allowSetExpirationDate: false, accept: null, customers: null },
}) => {
  const [myFile, setMyFile] = useState(null);
  const [filename, setFilename] = useState("");
  const [expirationDate, setExpirationDate] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const setFile = (e) => {
    setMyFile(e.target.files[0]);
  };

  const doConfirm = () => {
    if (!myFile || (customParams.allowSetFilename && !filename)) {
      return;
    }
    var data = null;
    if (expirationDate) {
      data = new Date(expirationDate);
    }
    confirm(myFile, filename, data, selectedCustomer);
    closeFunction();
  };

  const selectCustomerForUploadDoc = useCallback((id) => {
    setSelectedCustomer(id);
  }, []);

  return (
    <>
      <div className="modal-body">
        <div className=" justify-content-center row">
          {!!customParams.customers && customParams.customers.length > 1 ? (
            <>
              <div className="col-12 mb-3">
                <InputSelect
                  disabled={false}
                  name={"select-customer"}
                  isRequired={true}
                  label={"Seleziona il cliente"}
                  defaultValue={null}
                  data={customParams.customers}
                  onChange={(e) => {
                    selectCustomerForUploadDoc(e);
                  }}
                />
              </div>
            </>
          ) : null}

          <div className="col-12">
            <input accept={customParams.accept} onChange={setFile} type="file" className="form-control mb-2" name="file" id="file" />
          </div>

          {customParams.allowSetFilename ? (
            <div className="col-12">
              <InputText label="Nome File" name="filename" defaultValue={filename} isRequired={true} onChange={setFilename}></InputText>
            </div>
          ) : null}

          {customParams.allowSetExpirationDate ? (
            <div className="col-12">
              <InputDate name="Data Scadenza" onChange={setExpirationDate} defaultValue={expirationDate} label={"Data Scadenza"} />
            </div>
          ) : null}
        </div>
      </div>
      <div className="modal-footer">
        <button onClick={closeFunction} type="button" className="btn btn-secondary" data-bs-dismiss="modal">
          Annulla
        </button>
        <button
          disabled={
            !myFile || myFile == null || (customParams.allowSetFilename && !filename) || (customParams.customers && customParams.customers.length > 1 && !selectedCustomer)
          }
          onClick={() => doConfirm()}
          data-bs-dismiss="modal"
          type="button"
          className="btn btn-primary"
        >
          Conferma
        </button>
      </div>
    </>
  );
};

export default UploadFileModalContent;
