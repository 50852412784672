import { PracticesCqsProvider } from "../contexts/practices-cqs-context";
import { PracticesDebtsProvider } from "../contexts/practices-debts-context";
import { PracticesLoansProvider } from "../contexts/practices-loans-context";
import { ActivitiesProvider } from "../contexts/activities-context";
import { UserProvider } from "../contexts/user-context";
import { CustomerProvider } from "../contexts/customer-context";
import { ServiceCustomerProvider } from "../contexts/service-customer-context";
import SummaryPractices from "../components/dashboard-sections/summary-practices";
import SummaryActivities from "../components/dashboard-sections/summary-activities";

const Home = () => {
  return (
    <div className="home-page">
      <PracticesCqsProvider>
        <PracticesDebtsProvider>
          <PracticesLoansProvider>
            <ServiceCustomerProvider>
              <ActivitiesProvider>
                <UserProvider>
                  <CustomerProvider>
                    <SummaryActivities />
                    <SummaryPractices />
                  </CustomerProvider>
                </UserProvider>
              </ActivitiesProvider>
            </ServiceCustomerProvider>
          </PracticesLoansProvider>
        </PracticesDebtsProvider>
      </PracticesCqsProvider>
    </div>
  );
};

export default Home;
