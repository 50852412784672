import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { InputText, InputNumber, InputEmail, InputSelect } from '../../forms';
import { usePracticesCqs } from '../../../contexts/practices-cqs-context';
import { usePracticesLoans } from '../../../contexts/practices-loans-context';

const StepForm = styled.section`

`;

const FormUser = ({ user = null, handleSave, practicesUser = null }) => {
	const { setPayedAndDatePayed } = usePracticesCqs();
	const { setPayedAndDatePayedLoan } = usePracticesLoans();
	const [stepOpened, setStepOpened] = useState(0);
	const { register, handleSubmit } = useForm({});
	const [roleSelected, setRoleSelected] = useState(user !== null ? user.role : null);

	const roles = [
		{ value: 'ADMIN', label: 'ADMIN' },
		{ value: 'AGENTE', label: 'AGENTE' },
		{ value: 'AVVOCATO', label: 'AVVOCATO' },
		{ value: 'CLIENTE', label: 'CLIENTE' }
	]

	const onSubmit = async (data) => {
		await handleSave(data);
	}

	const handleChangeAgentInfoCheck = async (value, id) => {
		const { data } = await setPayedAndDatePayed(id, {
			date: null,
			payed: value
		});
		console.log(data);
	}

	const handleChangeAgentInfoDate = async (value, id) => {
		const { data } = await setPayedAndDatePayed(id, {
			date: value,
			payed: null
		});
		console.log(data);
	}

	const handleChangeAgentInfoCheckLoan = async (value, id) => {
		const { data } = await setPayedAndDatePayedLoan(id, {
			date: null,
			payed: value
		});
		console.log(data);
	}

	const handleChangeAgentInfoDateLoan = async (value, id) => {
		const { data } = await setPayedAndDatePayedLoan(id, {
			date: value,
			payed: null
		});
		console.log(data);
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<StepForm style={{ display: stepOpened === 0 ? 'block' : 'none' }}>
				<div className="row mb-4">
					<div className="col-md-4">
						<InputText label="Nome" name="first_name" defaultValue={user !== null ? user.first_name : ''} isRequired={true} register={register} ></InputText>
					</div>
					<div className="col-md-4">
						<InputText label="Cognome" name="last_name" defaultValue={user !== null ? user.last_name : ''} isRequired={true} register={register} ></InputText>
					</div>
					<div className="col-md-4">
						<InputText label="Nome Utente" name="username" defaultValue={user !== null ? user.username : ''} isRequired={true} register={register} ></InputText>
					</div>
				</div>

				<div className="row mb-4">
					<div className="col-md-4">
						<InputEmail label="Email" name="email" defaultValue={user !== null ? user.email : ''} isRequired={true} register={register} ></InputEmail>
					</div>
					<div className="col-md-4">
						<InputText type="password" label="Password" name="password" isRequired={true} register={register} ></InputText>
					</div>
					<div className="col-md-4">
						<InputSelect onChange={setRoleSelected} data={roles} label="Ruolo" name="role" defaultValue={user !== null ? user.role : ''} isRequired={true} register={register} ></InputSelect>
					</div>
				</div>

				{
					roleSelected !== 'ADMIN' && roleSelected !== null ?
						<div className="row mb-4">
							<div className="col-md-4">
								<InputNumber validation={null} label="Provvigioni" name="comp" defaultValue={user !== null ? user.comp : ''} isRequired={true} register={register} ></InputNumber>
							</div>
						</div>
						: null
				}
			</StepForm>


			{
				practicesUser !== null ?
					<>
						<hr />
						
						<h2>Riepilogo</h2>
						<table className="table">
							<thead>
								<tr>
									<th>Nr. Contratto</th>
									<th>Tipo Pratica</th>
									<th>Totale</th>
									<th>Pagato?</th>
									<th>Data Pagamento</th>
								</tr>
							</thead>
							<tbody>
								{
									practicesUser.practicesCqs.map((item, index) => {
										return (
											<tr key={index}>
												<td>{item.nContract}</td>
												<td>CQS</td>
												<td>&euro; {parseFloat(item.totalImport).toFixed(2)}</td>
												<td><input type="checkbox" name={`check_payed_${item._id}`} id={`check_payed_${item._id}`} onChange={(e) => handleChangeAgentInfoCheck(e.target.checked, item._id)} defaultChecked={item.agentinfo.payed} /></td>
												<td><input type="date" name={`date_payed_${item._id}`} id={`date_payed_${item._id}`} onChange={(e) => handleChangeAgentInfoDate(e.target.value, item._id)} defaultValue={item.agentinfo.date} /></td>
											</tr>
										)
									})
								}

								{
									practicesUser.practicesLoan.map((item, index) => {
										return (
											<tr key={index}>
												<td>{item.nContract}</td>
												<td>PRESTITO PERSONALE</td>
												<td>&euro; {parseFloat(item.totalImport).toFixed(2)}</td>
												<td><input type="checkbox" name={`check_payed_${item._id}`} id={`check_payed_${item._id}`} onChange={(e) => handleChangeAgentInfoCheckLoan(e.target.checked, item._id)} defaultChecked={item.agentinfo.payed} /></td>
												<td><input type="date" name={`date_payed_${item._id}`} id={`date_payed_${item._id}`} onChange={(e) => handleChangeAgentInfoDateLoan(e.target.value, item._id)} defaultValue={item.agentinfo.date} /></td>
											</tr>
										)
									})
								}

								{
									practicesUser.practicesDebt.map((item, index) => {
										return (
											<tr key={index}>
												<td>{item.nContract}</td>
												<td>GESTIONE DEBITO</td>
												<td>N/A</td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</>
					: null
			}

			<hr />

			{ stepOpened > 0 ? <input type="button" className="btn btn-dark" value="Precedente" disabled={stepOpened < 1} onClick={() => setStepOpened(stepOpened - 1)} /> : null}
			{ stepOpened !== 0 ? <input type="button" className="btn btn-primary mx-2" value="Prossimo" onClick={() => setStepOpened(stepOpened + 1)} /> : null}
			{ stepOpened === 0 ? <input type="submit" className={stepOpened === 1 ? 'btn btn-primary mx-2' : 'btn btn-primary'} value="Conferma" /> : null}
		</form>
	)
}

export default FormUser;