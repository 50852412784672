import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ListBanks from '../components/banks/list-banks/list-banks';
import EditBank from '../components/banks/edit-bank/edit-bank';
import { BanksProvider } from '../contexts/banks-context';

const Banks = ({ match }) => {
	return (
		<div className="archive-main">
			<BanksProvider>
					<Switch>
						<Route exact path={`${match.path}`} component={ListBanks} />
						<Route exact path={`${match.path}/:id`} component={EditBank} />
					</Switch>
			</BanksProvider>
		</div>
	)
}

export default Banks;