import { useCallback, useEffect, useState } from "react";
import { useCustomer } from "../../contexts/customer-context";
import { ServiceCustomerRelative, ServiceCustomerRelativeInfo } from "../../models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface IRelativeBadge {
  data: ServiceCustomerRelative;
  serviceIndex: number;
  onDelete: (relativeFullname: string, relationInfo: ServiceCustomerRelative, serviceIndex: number) => void;
  isDeletable: boolean;
}

const RelativeBadge = ({ data, serviceIndex, onDelete, isDeletable }: IRelativeBadge) => {
  const { getSingleCustomer } = useCustomer();
  const [relativeData, setRelativeData] = useState<ServiceCustomerRelativeInfo>(undefined);

  const getRelativeInfo = useCallback(
    async (id: string) => {
      const { data, error } = await getSingleCustomer(id);

      if (error) {
        const relativeError = { _id: "-1", first_name: "Cliente", last_name: "Eliminato" ,cf: "N.D." };
        setRelativeData(relativeError);
        return;
      }

      const relative = { _id: data._id, first_name: data.first_name, last_name: data.last_name, cf: data.cf ? data.cf : "N.D." };
      setRelativeData(relative);
    },
    [getSingleCustomer]
  );

  useEffect(() => {
    if (data) {
      getRelativeInfo(data.relativeId);
    }
  }, [data, getRelativeInfo]);

  return (
    <>
      {relativeData && (
        <div className="d-flex flex-row align-items-center relativeChips">
          <div className={`${isDeletable ? "verticalChipDivider":""} px-2`}>
            <span>{`${relativeData.first_name} ${relativeData.last_name}`}</span>
          </div>
          {isDeletable ? (
            <button
              title="delete relative"
              className="btn btn-sm closeArea py-0 px-2"
              onClick={() => {
                onDelete(`${relativeData.first_name} ${relativeData.last_name}`, data, serviceIndex);
              }}
            >
              <FontAwesomeIcon className="iconTrash" icon={faTimes} />
            </button>
          ) : null}
        </div>
      )}
    </>
  );
};

export default RelativeBadge;
