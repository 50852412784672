import React from 'react';

import { NewPageWrapper, NewPageWrapperCopy } from '../../../ui-components/custom-components';
import { usePracticesDebts } from '../../../contexts/practices-debts-context';
import FormPracticeDebts from '../../../ui-components/forms-components/practice-debts/form-practice-debts';
import { useEthosNotification } from '../../../contexts/ethos-notification-context';
const NewPracticeDebts = ({ setShowNewPracticeDebts, setPractices }) => {
	const { newPractice, getPractices } = usePracticesDebts();
	const {handleError } = useEthosNotification();

	const onSubmit = async (form) => {
		const { error } = await newPractice(form);

		if (error !== null){
			handleError(error);
			return;
		}
		const { data } = await getPractices();
		setPractices(data.data);
		setShowNewPracticeDebts(false);

		window.location.reload();
	}

	return (
		<NewPageWrapper className="new-pratice-page new-page">
			<NewPageWrapperCopy>
				<div className="top-page">
					<h3 className="top-page-title">Nuova Pratica Gestione Debito</h3>
					<button className="close-button" onClick={() => setShowNewPracticeDebts(false)}>&times;</button>
				</div>

				<hr />

				<FormPracticeDebts handleSave={onSubmit} />
			</NewPageWrapperCopy>
		</NewPageWrapper>
	)
}

export default NewPracticeDebts;