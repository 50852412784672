import React, { useCallback, useState } from 'react'

import { Auth } from 'aws-amplify';
import { httpPost, httpDelete, httpPatch, httpGet, httpDownload, uploadFileToS3, httpPut } from '../http'
import { PRACTICES_LOANS_ENDPOINT } from '../constants/API_ENDPOINT'

const PracticesLoansContext = React.createContext()

function PracticesLoansProvider(props) {
	const [logs, setLogs] = useState([]);

	const getPractices = useCallback(async (search = null, filter = null, filiale = null, user = null, limit = 12, offset = 1,sort=null) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null,
			sort: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const practices = await httpGet(`${PRACTICES_LOANS_ENDPOINT}`, jwtToken, {
				filter,
				filiale,
				search,
				limit,
				offset,
				user,
				sort
			});
			obj = {
				data: practices.data,
				error: null,
				meta: null,
				status: practices.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const getLoansSummary = useCallback(async () => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const practices = await httpGet(`${PRACTICES_LOANS_ENDPOINT}/summary`, jwtToken, {});
			obj = {
				data: practices.data,
				error: null,
				meta: null,
				status: practices.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const getSinglePractice = useCallback(async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const practice = await httpGet(`${PRACTICES_LOANS_ENDPOINT}/${id}`, jwtToken);

			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};

			setLogs(practice.data.logs);
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const newPractice = useCallback(async (data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const practice = await httpPost(`${PRACTICES_LOANS_ENDPOINT}`, jwtToken, data);
			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const editPractice = useCallback(async (id, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const practice = await httpPatch(`${PRACTICES_LOANS_ENDPOINT}`, jwtToken, data, id);
			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const deletePractice = useCallback(async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const practice = await httpDelete(`${PRACTICES_LOANS_ENDPOINT}`, jwtToken, id);
			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const uploadFileLoans = useCallback(async (id, type, data, file) => {
		let result = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();

			const response = await httpPost(`${PRACTICES_LOANS_ENDPOINT}/upload/url/${type}/${id}`, jwtToken, data);
			await uploadFileToS3(response.data.url, file);
			const docResponse = await httpPut(`${PRACTICES_LOANS_ENDPOINT}/upload/url/${type}/${id}`, jwtToken, data);

			result = {
				data: docResponse.data,
				error: null,
				meta: null,
				status: docResponse.status
			}
		} catch (err) {
			result = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return result;
	}, []);

	const uploadCustomFileLoans = useCallback(async (id, filename, data, file) => {
		let result = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();

			const response = await httpPost(`${PRACTICES_LOANS_ENDPOINT}/upload-custom/url/${filename}/${id}`, jwtToken, data);
			await uploadFileToS3(response.data.url, file);
			const docResponse = await httpPut(`${PRACTICES_LOANS_ENDPOINT}/upload-custom/url/${filename}/${id}`, jwtToken, data);

			result = {
				data: docResponse.data,
				error: null,
				meta: null,
				status: docResponse.status
			}
		} catch (err) {
			result = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return result;
	}, []);

	const downloadFileLoans = useCallback(async (id, type) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDownload(`${PRACTICES_LOANS_ENDPOINT}/download/url/${type}/${id}`, jwtToken);

			const link = document.createElement('a');
			link.href = result.data.url;
			link.setAttribute('download', `${result.data.filename}`);
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const deleteFileLoans = useCallback(async (id, type) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDelete(`${PRACTICES_LOANS_ENDPOINT}/delete/${type}`, jwtToken, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const downloadCustomFileLoans = useCallback(async (id, filename) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDownload(`${PRACTICES_LOANS_ENDPOINT}/download-custom/url/${filename}/${id}`, jwtToken);
			const link = document.createElement('a');
			link.href = result.data.url;
			link.setAttribute('download', `${result.data.filename}`);
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const deleteCustomFileLoans = useCallback(async (id, filename) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDelete(`${PRACTICES_LOANS_ENDPOINT}/delete-custom/${filename}`, jwtToken, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const resetExpireTimer = useCallback(async (id, date) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const practice = await httpGet(`${PRACTICES_LOANS_ENDPOINT}/reset-expire/${id}`, jwtToken, { date });

			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const generateFileByType = useCallback(async (id, type, path, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDownload(`${PRACTICES_LOANS_ENDPOINT}/generate/${id}/${type}`, jwtToken, { path, ...data });
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const generateExcel = useCallback(async (search = null, filter = null, filiale = null, user = null) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const practices = await httpGet(`${PRACTICES_LOANS_ENDPOINT}/generate-xlsx`, jwtToken, {
				filter,
				filiale,
				search,
				user
			});
			obj = {
				data: practices.data,
				error: null,
				meta: null,
				status: practices.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const setPayedAndDatePayedLoan = useCallback(async (id, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPost(`${PRACTICES_LOANS_ENDPOINT}/set-payed-agent/${id}`, jwtToken, { ...data });
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const uploadCustomFileLoansPec = useCallback(async (id, filename, data, file) => {
		let result = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();

			const response = await httpPost(`${PRACTICES_LOANS_ENDPOINT}/upload-pec/url/${filename}/${id}`, jwtToken, data);
			await uploadFileToS3(response.data.url, file);
			const docResponse = await httpPut(`${PRACTICES_LOANS_ENDPOINT}/upload-pec/url/${filename}/${id}`, jwtToken, data);

			result = {
				data: docResponse.data,
				error: null,
				meta: null,
				status: docResponse.status
			}
		} catch (err) {
			result = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return result;
	}, []);

	const downloadCustomFileLoansPec = useCallback(async (id, filename) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDownload(`${PRACTICES_LOANS_ENDPOINT}/download-pec/url/${filename}/${id}`, jwtToken);
			const link = document.createElement('a');
			link.href = result.data.url;
			link.setAttribute('download', `${result.data.filename}`);
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const deleteCustomFileLoansPec = useCallback(async (id, filename) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDelete(`${PRACTICES_LOANS_ENDPOINT}/delete-pec/${filename}`, jwtToken, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	return (
		<PracticesLoansContext.Provider
			value={{
				getPractices,
				getSinglePractice,
				newPractice,
				editPractice,
				deletePractice,
				uploadFileLoans,
				downloadFileLoans,
				deleteFileLoans,
				uploadCustomFileLoans,
				downloadCustomFileLoans,
				deleteCustomFileLoans,
				resetExpireTimer,
				generateExcel,
				generateFileByType,
				getLoansSummary,
				setPayedAndDatePayedLoan,
				uploadCustomFileLoansPec,
				deleteCustomFileLoansPec,
				downloadCustomFileLoansPec,
				logs
			}}
			{...props}
		/>
	)
}
const usePracticesLoans = () => React.useContext(PracticesLoansContext)

export { PracticesLoansProvider, usePracticesLoans }