import { useEffect, useState } from 'react';

import UICalendar from '../ui-components/calendar';
import { GoogleProvider, useGoogle } from '../contexts/google-context';

const Calendar = () => {
	const { checkIfIsAuthed } = useGoogle();
	const [canShow, setCanShow] = useState(false);

	useEffect(() => {
		if (!checkIfIsAuthed()) {
			alert("Collega il tuo account google per visualizzare il calendario, fai il login nella dashboard.")
			return window.location.href = '/';
		}

		setCanShow(true);
	}, [setCanShow, checkIfIsAuthed]);

	return (
		<GoogleProvider>
			{canShow ? <UICalendar /> : null}
		</GoogleProvider>
	)
}

export default Calendar;