import { useState, useEffect, useCallback, useRef } from "react";
import AddServiceCustomerForm from "./add-service-customer-form/add-service-customer-form";
import { InputNumber, InputDate } from "../../../../forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faLink, faPaperPlane, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { getFrequencyBetweenDate, getServiceCustomerName, isServiceScreening } from "../../../../../utils/functions";
import { useEthosModal } from "../../../../../contexts/ethos-modal-context";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { useEthosNotification } from "../../../../../contexts/ethos-notification-context";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useServiceCustomer } from "../../../../../contexts/service-customer-context";
import { useFiles } from "../../../../../contexts/files-context";
import { useConfigurations } from "../../../../../contexts/configurations-context";
import { Customer, ServiceCustomer, ServiceCustomerRelative } from "../../../../../models";
import ServiceRelativeCustomer from "../../../../../components/service-relative-modal/service-customer-modal-assign";
import RelativeBadge from "../../../../relativeBadge/relativeBadge";
import "./service-section.css";
import moment from "moment";
import { useParams } from "react-router-dom";
export interface ServicesSectionProps {
  customer?: Customer;
  users: any[];
  services: any[];
  serviceCustomerList: any[];
  updateServiceCustomer: (...args) => any;
  activateServiceCustomer: (...args) => any;
  delServiceCustomer: (...args) => any;
  addServiceCustomer: (service) => void;
  doSendTranchePaymentLink: (...args) => any;
}

const ServicesSection = (props: ServicesSectionProps) => {
  const {
    customer,
    users,
    services,
    serviceCustomerList,
    updateServiceCustomer,
    activateServiceCustomer,
    delServiceCustomer,
    addServiceCustomer,
    doSendTranchePaymentLink,
  } = props;

  const [availableServices, setAvailableServices] = useState([]);
  const [showRelativeCustomerModal, setShowRelativeCustomerModal] = useState(false);
  const [currentServiceID, setCurrentServiceID] = useState("");
  const [selectedRelativeForSerice, setSelectedRelativeForService] = useState([]);
  const [serviceIndex, setServiceIndex] = useState(0);
  const serviceRefs = useRef<{ [key: string]: HTMLElement | null }>({});
  const [newServiceCustomerList, setNewServiceCustomerList] = useState<ServiceCustomer[]>(serviceCustomerList);
  const {
    openEthosConfirmModal,
    openWriteMessageModal,
    openSelectUserModal,
    openCreatePaymentsPlanModal,
    openDocumentListModal,
    openStepReportListModal,
  } = useEthosModal();
  const { showWarningToast, showErrorToast, showSuccessToast } = useEthosNotification();
  const { user } = useAuthenticator();
  const { addRelativeCustomer, removeRelativeCustomer, generateServiceCustomerReport, sendServiceCustomerReport, getServiceCustomer } =
    useServiceCustomer();
  const { getFilesByIds, downloadFile, getFileInfoById, getAsyncOperationById, getAsyncOperations } = useFiles();
  const { configurations } = useConfigurations();
  const { handleError } = useEthosNotification();
  const navigationParams = useParams<{ serviceCustomer: string }>();

  const addRelativeToService = useCallback(
    async (id, relation, relativeId) => {
      const { error } = await addRelativeCustomer(id, relation, relativeId);
      if (error) {
        handleError(error);
        return;
      }
    },
    [addRelativeCustomer, handleError]
  );

  const role = useRef("CLIENTE");

  useEffect(() => {
    const payload = user?.getSignInUserSession()?.getAccessToken().payload;
    role.current =
      !!user && !!payload && !!payload["cognito:groups"] && !!payload["cognito:groups"].length ? payload["cognito:groups"][0] : "CLIENTE";
  }, [user]);

  useEffect(() => {
    setNewServiceCustomerList(serviceCustomerList);
  }, [serviceCustomerList]);

  const getStatusLabel = (status) => {
    switch (status) {
      case "PROPOSED":
        return "NON AVVIATO";
      case "ACTIVE":
        return "ATTIVO";
      case "ARCHIVED":
        return "ARCHIVIATO";
      case "CANCELED":
        return "ANNULLATO";
      default:
        return "STATO NON DEFINITO";
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "PROPOSED":
        return "text-warning fw-bold";
      case "ACTIVE":
        return "text-success  fw-bold";
      case "ARCHIVED":
        return "text-primary  fw-bold";
      case "CANCELED":
        return "text-dark fw-bold";
      default:
        return "text-dark fw-bold";
    }
  };

  const totalFeeChange = useCallback(
    (csId, tIndex) => {
      return (newAmount) => {
        var newList = [];
        for (var i = 0; i < newServiceCustomerList.length; i++) {
          var obj: ServiceCustomer = {
            ...newServiceCustomerList[i],
          };

          if (obj._id === csId) {
            obj.fee = newAmount;
          }
          newList.push(obj);
        }
        setNewServiceCustomerList(newList);
      };
    },
    [newServiceCustomerList]
  );

  const updateTrancheAmount = useCallback(
    (csId, tIndex) => {
      return (newAmount) => {
        var newList = [];
        for (var i = 0; i < newServiceCustomerList.length; i++) {
          var obj = {
            ...newServiceCustomerList[i],
          };

          if (obj._id === csId) {
            obj.arrayTranche[tIndex].amount = newAmount;
          }
          newList.push(obj);
        }
        setNewServiceCustomerList(newList);
      };
    },
    [newServiceCustomerList]
  );

  const updateTranchePaymentDate = useCallback(
    (csId, tIndex) => {
      return (newDate) => {
        var newList = [];
        for (var i = 0; i < newServiceCustomerList.length; i++) {
          var obj = {
            ...newServiceCustomerList[i],
          };

          if (obj._id === csId) {
            obj.arrayTranche[tIndex].expectedPaymentDate = newDate;
          }
          newList.push(obj);
        }
        setNewServiceCustomerList(newList);
      };
    },
    [newServiceCustomerList]
  );

  const togglePaid = useCallback(
    (csId, tIndex) => {
      var newList = [];

      for (var i = 0; i < newServiceCustomerList.length; i++) {
        var obj = {
          ...newServiceCustomerList[i],
        };

        if (obj._id === csId) {
          obj.arrayTranche[tIndex].paid = !obj.arrayTranche[tIndex].paid;
          obj.arrayTranche[tIndex].effectivePaymentDate = new Date();
        }
        newList.push(obj);
      }
      setNewServiceCustomerList(newList);
    },
    [newServiceCustomerList]
  );

  const deleteTranche = useCallback(
    async (scList, csId, tIndex) => {
      var aInp = {
        csId: csId,
        tIndex: tIndex,
      };
      const deleteTrancheCallback = (inp) => {
        const serviceCustomer: ServiceCustomer = scList.find((x) => x._id === inp.csId);
        if (!serviceCustomer) return;
        serviceCustomer.arrayTranche.splice(inp.tIndex, 1);
        setNewServiceCustomerList([...scList]);
      };

      openEthosConfirmModal("Sicuro di voler eliminare la tranche?", deleteTrancheCallback, aInp, "Conferma eliminazione");
    },
    [openEthosConfirmModal]
  );

  const sendTranchePaymentLink = async (csId, tIndex) => {
    const sendTranchePaymentLinkCallback = (inp) => {
      var link = null;
      for (var i = 0; i < newServiceCustomerList.length; i++) {
        if (newServiceCustomerList[i]._id === csId) {
          link = newServiceCustomerList[i].arrayTranche[tIndex].paymentLink;
          doSendTranchePaymentLink(csId, link);
        }
      }
    };
    openEthosConfirmModal("Sicuro di voler inviare il link per il pagamento della tranche?", sendTranchePaymentLinkCallback, {}, "Conferma invio");
  };

  const doUpdateServiceCustomer = useCallback(
    (serviceCust) => {
      serviceCust.arrayTranche = serviceCust.arrayTranche.filter((t) => !t.deleted);
      const { properties, ...newserviceCust } = serviceCust;
      updateServiceCustomer(newserviceCust);
    },
    [updateServiceCustomer]
  );

  const setTranchePaymentUrl = useCallback(
    async (csId, currentLink, tIndex) => {
      const setTranchePaymentUrlCallback = (link) => {
        var newList = [];
        var updatingObj = null;
        for (var i = 0; i < newServiceCustomerList.length; i++) {
          var obj = {
            ...newServiceCustomerList[i],
          };

          if (obj._id === csId) {
            obj.arrayTranche[tIndex].paymentLink = link;
            updatingObj = obj;
          }
          newList.push(obj);
        }
        setNewServiceCustomerList(newList);
        doUpdateServiceCustomer(updatingObj);
      };
      openWriteMessageModal(
        currentLink ? currentLink : "",
        "Inserire il link per il pagamento della tranche",
        "Link di pagamento",
        setTranchePaymentUrlCallback
      );
    },
    [newServiceCustomerList, doUpdateServiceCustomer, openWriteMessageModal]
  );

  const nextInstalmentDate = (startDate, count, period, frequency) => {
    let nextDate = new Date(startDate);
    switch (period) {
      case "monthly":
        nextDate.setMonth(nextDate.getMonth() + count);
        break;
      case "custom":
        nextDate.setDate(nextDate.getDate() + count * frequency);
        break;
      default:
        console.warn("Unrecognized period", period);
    }
    return nextDate;
  };

  const createServiceCustomerPaymentsPlan = useCallback(
    (serviceCust, nInstalments, startDate, period, _frequency = 30) => {
      const tranches = serviceCust.arrayTranche;

      let paid = 0;
      tranches
        .filter((t) => !t.deleted)
        .forEach((tranche) => {
          paid += Number(tranche.amount);
        });

      const fee = serviceCust.fee;
      let unsold = fee - paid;

      if (unsold <= 0) {
        showWarningToast("Piano pagamenti gia' impostato");
        return;
      }

      const unit = Math.floor(unsold / nInstalments);
      let i = 0;
      while (i < nInstalments - 1) {
        unsold -= unit;
        tranches.push({
          paid: false,
          amount: String(unit),
          paymentDate: nextInstalmentDate(startDate, i, period, _frequency).toISOString(),
          expectedPaymentDate: nextInstalmentDate(startDate, i, period, _frequency).toISOString(),
          effectivePaymentDate: null,
        });
        i++;
      }

      //ultima rata metto rimanenza
      tranches.push({
        paid: false,
        amount: String(unsold),
        paymentDate: nextInstalmentDate(startDate, i, period, _frequency).toISOString(),
        expectedPaymentDate: nextInstalmentDate(startDate, i, period, _frequency).toISOString(),
      });
      setNewServiceCustomerList(newServiceCustomerList);
    },
    [newServiceCustomerList, showWarningToast]
  );

  const addPaymentsPlan = (serviceCust) => {
    openCreatePaymentsPlanModal("", ({ tranchesNumber, firstTrancheExpirationDate, tranchesPeriod, customTranchesPeriod }) => {
      console.log(tranchesNumber, firstTrancheExpirationDate, tranchesPeriod, customTranchesPeriod);
      // creates tranches plan
      createServiceCustomerPaymentsPlan(serviceCust, tranchesNumber, firstTrancheExpirationDate, tranchesPeriod, customTranchesPeriod);
    });
  };

  const addTranche = useCallback(
    (csId) => {
      var newList = [];

      for (var i = 0; i < newServiceCustomerList.length; i++) {
        var obj = {
          ...newServiceCustomerList[i],
        };

        if (obj._id === csId) {
          var newTranche = {
            paid: false,
            amount: 0,
            paymentDate: new Date(),
          };
          obj.arrayTranche.push(newTranche);
        }
        newList.push(obj);
      }
      setNewServiceCustomerList(newList);
    },
    [newServiceCustomerList]
  );

  const hasDetailPage = (serviceCust) => {
    return serviceCust.status && serviceCust.status !== "PROPOSED";
  };

  const isActivable = (serviceCust) => {
    if (!serviceCust.status || serviceCust.status === "PROPOSED") {
      if (!isServiceScreening(services, serviceCust)) {
        return true;
      } else if (isServiceScreening(services, serviceCust)) {
        return hasPayedTranches(serviceCust);
      }
      return false;
    }
    return false;
  };

  const hasPayedTranches = (serviceCust) => {
    return serviceCust.arrayTranche.filter((t) => t.paid).length > 0;
  };

  useEffect(() => {
    setAvailableServices(services);
  }, [services]);

  const doActivateServiceCustomer = (serviceCust) => {
    activateServiceCustomer(serviceCust);
  };

  const getUserFullName = (id) => {
    var s = users.find((e) => e._id === id);
    if (s) {
      return s.first_name + " " + s.last_name;
    } else {
      return "Non assegnato";
    }
  };

  const getUsername = (id) => {
    var s = users.find((e) => e._id === id);
    if (s) {
      return s.username;
    } else {
      return "N.D.";
    }
  };

  const canDelete = (serviceCust) => {
    if (role.current === "ADMIN") return true;
    const username = getUsername(serviceCust.createdBy);
    return username === user.username;
  };

  const canSave = (index) => {
    if (!newServiceCustomerList || newServiceCustomerList.length < index + 1) {
      return false;
    }
    var obj = newServiceCustomerList[index];

    if (!obj || isNaN(obj.fee) || !obj.tranche || !obj.customerId || !obj.serviceId) {
      return false;
    }

    if (obj.arrayTranche.some((t) => !t.deleted && (isNaN(t.amount) || !t.paymentDate))) {
      return false;
    }
    return obj.arrayTranche.reduce((partialSum, a) => +partialSum + (a.deleted ? 0 : +a.amount), 0) === +obj.fee;
  };

  const openRelativeModal = (index: number, openModal: boolean) => {
    if (!newServiceCustomerList || newServiceCustomerList.length < index + 1) {
      return;
    }
    setCurrentServiceID(newServiceCustomerList[index]._id);
    setSelectedRelativeForService(newServiceCustomerList[index].relatives.map((item: any) => item.relativeId));
    setServiceIndex(index);
    setShowRelativeCustomerModal(openModal);
  };

  const canSendPaymentLink = (scId, tIndex) => {
    for (var i = 0; i < newServiceCustomerList.length; i++) {
      if (newServiceCustomerList[i]._id === scId) {
        return newServiceCustomerList[i].arrayTranche[tIndex].paymentLink && newServiceCustomerList[i].arrayTranche[tIndex].paymentLink !== "";
      }
    }
    return false;
  };

  const doAssignBroker = async (serviceCustomer, ownerId) => {
    serviceCustomer.broker = ownerId;
    doUpdateServiceCustomer(serviceCustomer);
  };
  const changeBroker = (serviceCustomer) => {
    openSelectUserModal(
      "Selezionare un nuovo segnalatore",
      users,
      customer.broker,
      false,
      "BROKER",
      (ownerId) => {
        doAssignBroker(serviceCustomer, ownerId);
      },
      "Nessun segnalatore",
      true
    );
  };

  const updateRelativeService = useCallback(
    async (id, data) => {
      const serviceCustomerToUpdate = newServiceCustomerList[serviceIndex];

      for (const item of data) {
        await addRelativeToService(id, item.relation, item.relativeId);
        serviceCustomerToUpdate.relatives!.push(item);
      }

      updateServiceCustomer(serviceCustomerToUpdate);
    },
    [addRelativeToService, newServiceCustomerList, serviceIndex, updateServiceCustomer]
  );

  const removeRelation = useCallback(
    (relativeFullname: string, data: ServiceCustomerRelative, index: number) => {
      const serviceCustomerToUpdate = newServiceCustomerList[index];

      const removeRelativeToService = async (relativeInfo) => {
        const { error } = await removeRelativeCustomer(serviceCustomerToUpdate._id, relativeInfo.relation, relativeInfo.relativeId);
        if (error) {
          handleError(error);
          return;
        }
        const dataS = serviceCustomerToUpdate.relatives.filter((item) => item._id !== relativeInfo._id);
        const updatedService = { ...serviceCustomerToUpdate, relatives: dataS };
        updateServiceCustomer(updatedService);
      };
      openEthosConfirmModal(`Sicuro di voler rimuovere ${relativeFullname} dal servizio?`, removeRelativeToService, data, "Conferma eliminazione");
    },
    [handleError, newServiceCustomerList, openEthosConfirmModal, removeRelativeCustomer, updateServiceCustomer]
  );

  const isPreviousTrancheUnpaid = useCallback((arrayTranche, tIndex): boolean => {
    if (tIndex <= 0) return false;
    const filteredTranches = arrayTranche.filter((t) => !t.deleted);
    return !filteredTranches[tIndex - 1].paid;
  }, []);

  const findLastPaid = useCallback((serviceCustomer): number => {
    let lastIndex: number | undefined;
    serviceCustomer.arrayTranche.forEach((t, index) => {
      if (!t.deleted && t.paid) {
        lastIndex = index;
      }
    });
    return lastIndex !== undefined ? lastIndex : -1;
  }, []);

  const updateExpPayDay = (serviceIndex, lastPayTranche, serviceCustomer) => {
    const frequency = getFrequencyBetweenDate(
      new Date(serviceCustomer.arrayTranche[1].paymentDate),
      new Date(serviceCustomer.arrayTranche[0].paymentDate)
    );

    const updatedate = (serviceCustomer.arrayTranche || []).map((item, index) => {
      if (index >= lastPayTranche + 1) {
        const updatedDate = nextInstalmentDate(new Date(), index - lastPayTranche, frequency === 30 ? "monthly" : "custom", frequency);
        return {
          ...item,
          expectedPaymentDate: updatedDate.toISOString(),
        };
      }
      return item;
    });

    const updatedList = [
      ...newServiceCustomerList.slice(0, serviceIndex),
      {
        ...serviceCustomer,
        arrayTranche: updatedate,
      },
      ...newServiceCustomerList.slice(serviceIndex + 1),
    ];

    setNewServiceCustomerList(updatedList);
  };

  const getPaymentPlaneTemplate = (services, serviceCust) => {
    return services.filter((s) => s._id === serviceCust.serviceId)[0].templates || [];
  };

  const doDownloadTemplateFile = useCallback(
    async (f) => {
      const { error } = await downloadFile(f._id || f.id);
      if (error) {
        showErrorToast("Errore durante il download del file!");
        return;
      }
    },
    [downloadFile, showErrorToast]
  );

  const doDownloadFileById = useCallback(
    async (f) => {
      const { error } = await downloadFile(f._id || f.id);
      if (error) {
        showErrorToast("Errore durante il download del file!");
        return;
      }
    },
    [downloadFile, showErrorToast]
  );

  const sendServiceReport = useCallback(
    async (services, serviceCust, reportId) => {
      var callback = async (body) => {
        var subject = "Report servizio: " + getServiceCustomerName(services, serviceCust);
        var { error } = await sendServiceCustomerReport(serviceCust._id, body, subject, reportId);
        if (error) {
          handleError(error);
          return;
        }
        showSuccessToast("Report inviato correttamente!");
      };
      openWriteMessageModal(
        "In allegato il report per il servizio in oggetto.\n\nDistinti Saluti",
        "Scrivi il messaggio a cui allegare il report",
        null,
        callback
      );
    },
    [handleError, openWriteMessageModal, sendServiceCustomerReport, showSuccessToast]
  );

  const generateReport = useCallback(
    async (services, serviceCust) => {
      const validIdTemplate = getPaymentPlaneTemplate(services, serviceCust)
        .filter((t) => t.type === "templatePaymentPlan" && t.status === "ACTIVE")
        .map((s) => {
          return s.id;
        });

      if (validIdTemplate.length === 0) {
        showErrorToast("Non è stato trovato nessun template di tipo 'Piano Pagamento' per questo tipo di servizio");
        return;
      }

      /* Viene recuperato il file */
      if (!validIdTemplate || !validIdTemplate.length) return;
      var { data, error } = await getFilesByIds(validIdTemplate);
      if (error) {
        handleError(error);
        return;
      }

      /* Viene formattato per farlo funzionare nella modal */
      const validTemplateInfo = data.data.map((f) => {
        const templateType = configurations.typeTemplate?.find((tt) => tt.value === f.fileType);
        return {
          ...f,
          templateTypeLabel: templateType ? templateType.label : "N.D.",
        };
      });

      var callback = async (files) => {
        if (!files || !files.length) {
          return;
        }
        const { error } = await generateServiceCustomerReport(serviceCust._id, files[0]._id);

        if (error) {
          handleError(error);
          return;
        }

        const { data, error: error2 } = await getServiceCustomer(serviceCust._id);
        if (error2) {
          handleError(error);
          return;
        }

        updateServiceCustomer({
          ...serviceCust,
          properties: data.properties,
        });
      };

      var customColumns = [];
      customColumns.push({ value: "templateTypeLabel", label: "Tipo Template" });
      openDocumentListModal(
        validTemplateInfo,
        null,
        false,
        true,
        callback,
        doDownloadTemplateFile,
        null,
        "Seleziona il template da utilizzare per il nuovo report 'Piano di Rateizzazione'",
        null,
        true,
        customColumns,
        null
      );
    },
    [
      configurations.typeTemplate,
      doDownloadTemplateFile,
      generateServiceCustomerReport,
      getFilesByIds,
      getServiceCustomer,
      handleError,
      openDocumentListModal,
      showErrorToast,
      updateServiceCustomer,
    ]
  );

  const viewReport = useCallback(
    async (services, serviceCust) => {
      var sendCallback = async (file) => {
        sendServiceReport(services, serviceCust, file.id);
      };

      if (!configurations || !configurations.typeReport) {
        showErrorToast("Errore nel caricamento dei file");
        return;
      }

      if (!serviceCust.properties || !serviceCust.properties.reports || serviceCust.properties.reports.length === 0) {
        showErrorToast("Non sono stati trovati report 'Piano di Rateizzazione' per questo servizio");
        return;
      }

      const reportsFileIds = serviceCust.properties.reports.map((r) => r.id);

      var { data, error } = await getFilesByIds(reportsFileIds);
      if (error) {
        handleError(error);
        return;
      }

      var lista = [];
      for (var i = 0; i < configurations.typeReport.length; i++) {
        const item = configurations.typeReport[i];
        var obj: any = {};
        obj.reportType = item.value;
        obj.reportTypeLabel = item.label;
        const filteredReports = serviceCust.properties.reports.filter((x) => x.docType === item.value);
        for (var j = 0; j < filteredReports.length; j++) {
          const report = filteredReports[j];
          var fileInfo = data.data.find((x) => x._id === report.id);
          if (fileInfo) {
            report.filename = fileInfo.filename;
            report.createdAt = fileInfo.createdAt;
            report.updatedAt = fileInfo.updatedAt;
            report.expirationDate = fileInfo.expirationDate;
            report.AsyncOpData = fileInfo.AsyncOpData;
          }
          report.reportType = obj.reportType;
          report.reportTypeLabel = obj.reportTypeLabel;
        }
        obj.associatedReports = filteredReports;
        lista.push(obj);
      }
      const reportsList = lista.find((report) => report.reportType === "reportPaymentPlan");

      openStepReportListModal(
        reportsList.associatedReports,
        doDownloadFileById,
        sendCallback,
        getFileInfoById,
        getAsyncOperationById,
        getAsyncOperations,
        "Lista dei report di tipo 'Report-Piano-Pagamento' generati per il servizio"
      );
    },
    [
      configurations,
      doDownloadFileById,
      getAsyncOperationById,
      getAsyncOperations,
      getFileInfoById,
      getFilesByIds,
      handleError,
      openStepReportListModal,
      sendServiceReport,
      showErrorToast,
    ]
  );

  useEffect(() => {
    if (navigationParams.serviceCustomer && serviceRefs.current) {
      const element = serviceRefs.current[navigationParams.serviceCustomer];
      if (element) {
        element.style.transition = "box-shadow 0.5s ease, outline 0.5s ease";
        element.style.boxShadow = "0 0 15px 5px rgba(17, 77, 87, 0.7)";
        element.style.outline = "3px solid rgba(17, 77, 87, 1)";

        element.scrollIntoView({ behavior: "smooth" });

        const timeoutId = setTimeout(() => {
          element.style.boxShadow = "0 0 0 0 rgba(17, 77, 87, 0)";
          element.style.outline = "0px solid rgba(17, 77, 87, 0)";
        }, 3000);

        return () => clearTimeout(timeoutId);
      } else {
        showErrorToast("Servizio non trovato");
      }
    }
  }, [navigationParams.serviceCustomer, showErrorToast]);

  return (
    <div className="step-services">
      {availableServices.length ? (
        <AddServiceCustomerForm
          customerId={customer._id}
          services={availableServices}
          addServiceCustomer={addServiceCustomer}
        ></AddServiceCustomerForm>
      ) : null}
      {!!newServiceCustomerList && !!newServiceCustomerList.length ? (
        <>
          {newServiceCustomerList.map(function (serviceCust, index) {
            return (
              <div
                key={"SC_" + serviceCust._id}
                className="card my-4"
                ref={(el) => {
                  if (el) {
                    serviceRefs.current[serviceCust._id] = el;
                  }
                }}
              >
                <div className="card-header">
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <h4>
                        {getServiceCustomerName(services, serviceCust)}{" - "}{!!serviceCust.subTitle ? `${serviceCust.subTitle} - ` : ""}
                        <span className={getStatusClass(serviceCust.status)}>{getStatusLabel(serviceCust.status)}</span>
                      </h4>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <div className="col-3">
                        <div className="form-group row">
                          <label htmlFor={"serviceFee" + serviceCust._id} className=" col-3 col-form-label">
                            Fee
                          </label>
                          <div className="col-9">
                            <InputNumber
                              price={true}
                              defaultValue={serviceCust.fee}
                              type="number"
                              step="0.01"
                              name={"serviceFee" + serviceCust._id}
                              onChange={totalFeeChange(serviceCust._id, -1)}
                              label={""}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-5 px-2 pl-4">
                        Segnalatore:
                        <button onClick={() => changeBroker(serviceCust)} className="btn btn-sm btn-primary d-inline-block ml-2">
                          <FontAwesomeIcon className="mr-2" icon={faUserTie} />
                          {getUserFullName(serviceCust.broker)}
                        </button>
                      </div>
                      <div className="col">
                        <div className="row justify-content-center">
                          <div className="btn-group" role="group">
                            <div className="btn-group" style={{ marginBottom: 0 }} role="group">
                              <button
                                id="btnGroupDrop1"
                                type="button"
                                className="btn btn-secondary dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Azioni
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                <li>
                                  <button onClick={() => addTranche(serviceCust._id)} className="dropdown-item">
                                    Aggiungi Tranche
                                  </button>
                                </li>
                                <li>
                                  <button onClick={() => addPaymentsPlan(serviceCust)} className="dropdown-item">
                                    Aggiungi piano di pagamento
                                  </button>
                                </li>

                                {isActivable(serviceCust) && (
                                  <li>
                                    <button onClick={() => doActivateServiceCustomer(serviceCust)} className="dropdown-item">
                                      Avvia servizio
                                    </button>
                                  </li>
                                )}
                                {hasDetailPage(serviceCust) && (
                                  <li>
                                    {" "}
                                    <a
                                      href={
                                        `${isServiceScreening(services, serviceCust) ? "/service-screening/" : "/service-details/"}` + serviceCust._id
                                      }
                                    >
                                      <span className="dropdown-item">Vedi dettagli</span>
                                    </a>{" "}
                                  </li>
                                )}
                                <li>
                                  <button
                                    onClick={async () => {
                                      await generateReport(services, serviceCust);
                                    }}
                                    className="dropdown-item"
                                  >
                                    Genera Report del piano di Pagamento
                                  </button>
                                </li>
                                <li>
                                  <button
                                    onClick={() => {
                                      viewReport(services, serviceCust);
                                    }}
                                    className="dropdown-item"
                                  >
                                    Esporta Report del piano di Pagamento
                                  </button>
                                </li>
                              </ul>
                            </div>

                            {canSave(index) ? (
                              <span onClick={() => doUpdateServiceCustomer(serviceCust)} className="btn btn-primary">
                                Salva
                              </span>
                            ) : (
                              <span style={{ cursor: "not-allowed" }} onClick={() => { }} className="btn disabled btn-primary">
                                Salva
                              </span>
                            )}
                            {canDelete(serviceCust) ? (
                              <span onClick={() => delServiceCustomer(serviceCust._id)} className="btn btn-danger">
                                Elimina
                              </span>
                            ) : (
                              <span onClick={() => { }} style={{ cursor: "not-allowed" }} className="btn btn-danger disabled">
                                Elimina
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {isServiceScreening(services, serviceCust) && (
                      <>
                        <hr />

                        <div className="customer-relative-area">
                          <div className="d-flex flex-row justify-content-between align-items-end">
                            <span>
                              <b>SOGGETTI ASSOCIATI</b>
                            </span>
                            {serviceCust.status !== "ACTIVE" ? (
                              <button
                                type="button"
                                onClick={() => openRelativeModal(index, !showRelativeCustomerModal)}
                                className="btn btn-sm btn-primary d-inline-block"
                                disabled={customer.relatives.length === 0}
                              >
                                <FontAwesomeIcon className="mr-2" icon={faUserPlus} />
                                Associa Soggetti
                              </button>
                            ) : null}
                          </div>
                          <div className="d-flex flex-row flex-wrap align-items-center gap-3 mt-3">
                            <div className="d-flex flex-row align-items-center relativeChips">
                              <div className="px-2">
                                <span>{`${customer.first_name} ${customer.last_name}`}</span>
                              </div>
                            </div>
                            {serviceCust.relatives &&
                              serviceCust.relatives.map((serviceRelative: any, IndexRelarion) => {
                                return (
                                  <RelativeBadge
                                    key={`service_${index}-relative_${IndexRelarion}`}
                                    data={serviceRelative}
                                    serviceIndex={index}
                                    onDelete={removeRelation}
                                    isDeletable={serviceCust.status !== "ACTIVE"}
                                  />
                                );
                              })}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="card-body">
                  <div className="row mt-2">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="text-center">Tranche</th>
                          <th className="text-center">Importo</th>
                          <th className="text-center">Data pagamento prevista</th>
                          <th className="text-center">Data pagamento effettiva</th>
                          <th className="text-center">Pagato</th>
                          <th className="text-center w-25"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {serviceCust.arrayTranche
                          .filter((t) => !t.deleted)
                          .map(function (t, tIndex) {
                            return (
                              <tr key={"trow_" + tIndex + "_" + serviceCust._id}>
                                <td className="text-center">
                                  <h6 className="fw-bold">#{tIndex + 1}</h6>
                                </td>

                                <td className="text-center w-25">
                                  <InputNumber
                                    price={true}
                                    defaultValue={t.amount}
                                    type="number"
                                    step="0.01"
                                    name={"payment_date_" + tIndex + "_" + serviceCust._id}
                                    onChange={updateTrancheAmount(serviceCust._id, tIndex)}
                                    label={""}
                                  />
                                </td>
                                <td>
                                  <div className="d-flex flex-column">
                                    <InputDate
                                      defaultValue={t.expectedPaymentDate}
                                      name={"payment_date_" + tIndex + "_" + serviceCust._id}
                                      onChange={updateTranchePaymentDate(serviceCust._id, tIndex)}
                                      label={""}
                                    />
                                    {t.paymentDate !== t.expectedPaymentDate && (
                                      <>
                                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>Data Pagamento Originale</span>
                                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>{moment(t.paymentDate).format("DD/MM/YYYY")}</span>
                                      </>
                                    )}
                                  </div>
                                </td>
                                <td className="tableTop">
                                  <div className="d-flex flex-column" style={{ alignItems: "center", justifyContent: "flex-start" }}>
                                    <InputDate
                                      readOnly={true}
                                      defaultValue={t.effectivePaymentDate}
                                      name={"payment_date_" + tIndex + "_" + serviceCust._id}
                                      label={""}
                                    />
                                  </div>
                                </td>
                                <td className="text-center">
                                  <div className="form-check form-switch">
                                    <input
                                      aria-label="check-box"
                                      style={{ float: "none" }}
                                      onChange={() => togglePaid(serviceCust._id, tIndex)}
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexSwitchCheckChecked"
                                      disabled={isPreviousTrancheUnpaid(serviceCust.arrayTranche, tIndex)}
                                      checked={t.paid}
                                    />
                                  </div>
                                </td>

                                <td className="text-center w-25">
                                  {!t.paid && (
                                    <>
                                      {canSave(index) ? (
                                        <span
                                          title="Imposta il link per il pagamento della tranche"
                                          onClick={() => setTranchePaymentUrl(serviceCust._id, t.paymentLink, tIndex)}
                                          className="btn btn-sm btn-primary mr-2"
                                        >
                                          <FontAwesomeIcon icon={faLink} />
                                        </span>
                                      ) : (
                                        <span
                                          title="Imposta il link per il pagamento della tranche"
                                          onClick={() => { }}
                                          className="btn btn-sm disabled btn-primary mr-2"
                                          style={{ cursor: "not-allowed" }}
                                        >
                                          <FontAwesomeIcon icon={faLink} />
                                        </span>
                                      )}
                                      {canSendPaymentLink(serviceCust._id, tIndex) && canSave(index) ? (
                                        <span
                                          title="Invia il link per il pagamento della tranche"
                                          onClick={() => sendTranchePaymentLink(serviceCust._id, tIndex)}
                                          className="btn btn-sm btn-secondary mr-2"
                                        >
                                          <FontAwesomeIcon icon={faPaperPlane} />
                                        </span>
                                      ) : (
                                        <span
                                          title="Invia il link per il pagamento della tranche"
                                          onClick={() => { }}
                                          style={{ cursor: "not-allowed" }}
                                          className="btn disabled btn-sm btn-secondary mr-2"
                                        >
                                          <FontAwesomeIcon icon={faPaperPlane} />
                                        </span>
                                      )}
                                      <span
                                        title="Elimina tranche"
                                        onClick={() => deleteTranche(newServiceCustomerList, serviceCust._id, tIndex)}
                                        className="btn btn-sm btn-danger"
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </span>
                                    </>
                                  )}

                                  {t.paid && tIndex !== serviceCust.arrayTranche.filter((t) => !t.deleted).length - 1 && (
                                    <span>
                                      {tIndex === findLastPaid(serviceCust) ? (
                                        <>
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => {
                                              updateExpPayDay(index, tIndex, serviceCust);
                                            }}
                                          >
                                            Aggiorna Prospetto
                                          </button>
                                        </>
                                      ) : null}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!canSave(index) ? (
                  <div className="col-12 text-center mb-3">
                    <small className="text-danger">
                      Impossibile Salvare: tutti i campi devono essere compilati e la somma degli importi deve essere pari a € {serviceCust.fee}.
                    </small>
                  </div>
                ) : null}
                {index !== newServiceCustomerList.length - 1 ? <hr></hr> : null}
              </div>
            );
          })}
        </>
      ) : (
        <div className="text-center">
          <h2>Nessun servizio associato al cliente</h2>
        </div>
      )}
      {showRelativeCustomerModal ? (
        <ServiceRelativeCustomer
          customerRelative={customer.relatives}
          setOpenRelativeModal={setShowRelativeCustomerModal}
          serviceID={currentServiceID}
          existingRelation={selectedRelativeForSerice}
          updateRelative={updateRelativeService}
        />
      ) : null}
    </div>
  );
};

export default ServicesSection;
