import { useState, useEffect } from 'react';
import moment from 'moment';
import { useEthosNotification } from '../../../contexts/ethos-notification-context';

const StepReportListModalContent = ({ customParams = { fileList: [], doDownloadFile: () => { }, doSendReport: () => { }, getAsyncOperationById: async () => { }, getFileInfoById: async () => { } }, confirm = () => { }, closeFunction = () => { } }) => {
	const [visibleFiles, setVisibleFiles] = useState([]);
	const { handleError } = useEthosNotification();

	useEffect(() => {
		var lista = [];
		if (customParams.fileList) {
			lista = customParams.fileList;
			for (var i = 0; i < lista.length; i++) {
				const item = lista[i];
				let asyncOpData = null;
				if (!!item.AsyncOpData && !!item.AsyncOpData.length && !!item.asyncOpId) {
					asyncOpData = item.AsyncOpData.find(o => o._id === item.asyncOpId);
				}
				item.asyncOpState = asyncOpData ? asyncOpData.status : "OK";
			}
		}
		setVisibleFiles(lista);
	}, [customParams]);

	const updateStatus = async (f) => {
		var newFiles = [];
		for (var i = 0; i < visibleFiles.length; i++) {
			var obj = { ...visibleFiles[i] }
			if (obj.id === f.id) {
				const { data, error } = await customParams.getAsyncOperationById(f.asyncOpId);
				if (error) {
					handleError(error);
				}
				obj.asyncOpState = data.status;
				const r2 = await customParams.getFileInfoById(f.id);
				if (r2.error) {
					handleError(r2.error);
				} else {
					obj.filename = r2.data.filename;
				}
			}

			newFiles.push(obj)
		}

		setVisibleFiles(newFiles);
	}

	return (
		<>
			<div className="modal-body">
				<div className=" justify-content-center row">
					<div className="col-12">
						{
							visibleFiles && visibleFiles.length ?
								<table className="table table-striped">
									<thead>
										<tr>
											<th className="text-center">Nome File</th>
											<th className="text-center">Caricato il</th>
											<th className="text-center"></th>
										</tr>
									</thead>
									<tbody>
										{visibleFiles.map(function (f, index) {
											return (
												!f.deleted ? <tr key={"doc_list_modal_trow_" + (index)} className={(f.asyncOpState && (!f.asyncOpState || f.asyncOpState !== "OK")) ? "bg-warning" : ""}>
													<td className="text-center">
														<span>
															{
																(f.asyncOpState && (!f.asyncOpState || f.asyncOpState !== "OK"))
																	? "Report in elaborazione" : f.filename
															}</span>
													</td>
													<td className="text-center">
														{f.updatedAt ?
															<span>{moment(f.updatedAt).format('DD/MM/YYYY hh:mm:ss')}</span>
															: <span>N.D.</span>
														}
													</td>
													<td className="text-center">
														{
															(f.asyncOpState && (!f.asyncOpState || f.asyncOpState !== "OK")) ?
																<div>
																	<span onClick={() => updateStatus(f)} className="btn btn-sm btn-primary">Aggiorna</span>
																</div> :
																<div className="btn-group w-100" role="group">
																	<span onClick={() => customParams.doDownloadFile(f)} className="btn btn-sm btn-primary">Scarica</span>
																	<span onClick={() => customParams.doSendReport(f)} className="btn btn-sm btn-secondary">Invia al cliente</span>
																</div>
														}
													</td>
												</tr> : null
											)
										})
										}
									</tbody>
								</table> :
								<h6 className="text-center">Nessun file da mostrare...</h6>
						}
					</div>
				</div>
			</div>
			<div className="modal-footer">
				<button onClick={closeFunction} type="button" className="btn btn-primary" data-bs-dismiss="modal">Chiudi</button>
			</div>
		</>
	)
}

export default StepReportListModalContent;