import { useState, useRef, useCallback, useEffect } from "react";
import { useEthosNotification } from "../../contexts/ethos-notification-context";
import { useServiceCustomer } from "../../contexts/service-customer-context";
import { DeleteReportName, isExpired, renderPaginator } from "../../utils/functions";
import { debounce } from "lodash";
import { faSort, faSortUp, faSortDown, faEdit, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { useConfigurations } from "../../contexts/configurations-context";
import { useService } from "../../contexts/service-context";
import "./style.css";
import { DeleteReportType } from "../../models";



const ListDeleteReport = () => {
  const { configurations } = useConfigurations();
  const { getServiceCustomers } = useServiceCustomer();
  const { handleError } = useEthosNotification();
  const { getServices } = useService();
  const LIMIT = 12;
  const searchRef = useRef<HTMLInputElement>();
  const sortTypeFilterRef = useRef<HTMLSelectElement>();
  const sortStatusFilterRef = useRef<HTMLSelectElement>();

  const [pagination, setPagination] = useState(null);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState<string | null>(null);

  const [deleteReportRequest, setDeleteReportRequest] = useState([]);

  const [selectedReportType, setSelectedReportType] = useState<DeleteReportType | null>(null);
  const [selectReportStatus, setSelectReportStatus] = useState<string | null>(null);

  const [services, setServices] = useState(null);

  const [sortColumn, setSortColumn] = useState({ column: null, direction: 0 });
  const [sortFilter, setSortFilter] = useState(null);

  const initDeleteReports = useCallback(async () => {
    const { data, error } = await getServiceCustomers(
      selectedReportType === null ? DeleteReportType.ALL : selectedReportType,
      LIMIT,
      offset,
      sortFilter,
      search,
      null,
      selectReportStatus
    );
    if (error) {
      handleError(error);
      return;
    }
    setDeleteReportRequest(data.data);
    renderPaginator(data.length, LIMIT, setOffset, setPagination, offset);
  }, [getServiceCustomers, handleError, offset, search, selectReportStatus, selectedReportType, sortFilter]);

  const init = useCallback(() => {
    initDeleteReports();
  }, [initDeleteReports]);

  const resetFilter = () => {
    setOffset(0);
    sortTypeFilterRef.current.value = null;
    setSortFilter(null);
    setSearch(null);
    searchRef.current.value = null;
    setSelectedReportType(DeleteReportType.ALL);
    setSelectReportStatus(null);
    sortStatusFilterRef.current.value = null;
  };

  const onSearchChange = debounce((value: string) => {
    setSearch(value);
  }, 500);

  const onChangeType = debounce((value: DeleteReportType) => {
    setSelectedReportType(value);
  }, 500);

  const onChangeStatus = debounce((value: DeleteReportType) => {
    setSelectReportStatus(value);
  }, 500);

  const loadServices = useCallback(async () => {
    var { data, error } = await getServices();

    if (error) {
      handleError(error);
      return;
    }
    setServices(data.data);
  }, [getServices, handleError]);

  const sortingColumn = (columnName: string) => {
    if (!!sortColumn) {
      if (sortColumn.column === columnName) {
        setSortColumn({
          column: columnName,
          direction: sortColumn.direction === 1 ? -1 : 1,
        });
      } else {
        setSortColumn({ column: columnName, direction: 1 });
      }
    }
  };

  const renderSortArrow = (columnName: string) => {
    if (sortFilter === null) {
      return <FontAwesomeIcon icon={faSort} style={{ opacity: ".50" }} className="ml-2" />;
    }
    if (!!sortColumn) {
      if (sortColumn.column === columnName) {
        return sortColumn.direction === 1 ? (
          <FontAwesomeIcon icon={faSortUp} className="ml-2" />
        ) : (
          <FontAwesomeIcon icon={faSortDown} className="ml-2" />
        );
      }
    }
  };

  const getStatusLabel = (item): string => {
    switch (item.status) {
      case "PROPOSED":
        return "NON AVVIATO";
      case "ACTIVE":
        if (item.properties && item.properties.steps && item.properties.steps.length) {
          var nonCompleti = item.properties.steps.filter((x) => x.stepState !== "COMPLETED");
          return nonCompleti.length ? "ATTIVO" : "COMPLETATO";
        }
        return "ATTIVO";
      case "ARCHIVED":
        return "ARCHIVIATO";
      case "CANCELED":
        return "ANNULLATO";
      default:
        return "N.D.";
    }
  };

  const getPaymentStateLabel = (item): string => {
    var pagate = item.arrayTranche.filter((x) => x.paid);

    if (pagate.length === 0) {
      return "NON PAGATO";
    }

    if (pagate.length === item.arrayTranche.length) {
      return "PAGATO";
    }

    return "PARZIALMENTE PAGATO";
  };

  const getLabelDeleteType = (serviceCustomer) => {
    const internal = services.find((e) => e._id === serviceCustomer.serviceId).internalName;

    switch (internal) {
      case DeleteReportName.SIC:
        return "SIC";
      case DeleteReportName.PROTESTI:
        return "PROTESTI";
      default:
        return "N.D.";
    }
  };

  const getCustomerFullname = (item): string => {
    const customer = item.customerData.find((c) => c._id === item.customerId);
    if (!!customer) {
      return `${customer.first_name} ${customer.last_name}`;
    }
    return "N.D.";
  };

  const isLoaded = () => {
    return services && configurations;
  };

  useEffect(() => {
    loadServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    init();
  }, [offset, init]);

  useEffect(() => {
    if (!!sortColumn.column) {
      setSortFilter(`${sortColumn.column}:${sortColumn.direction}`);
    }
  }, [sortColumn]);

  return (
    <>
      {isLoaded() && (
        <div className="row">
          {/* FILTER AREA */}
          <div className="col-12 text-center">
            <h3>Lista dei servizi di cancellazione</h3>
          </div>

          <div className="row align-items-center">
            <div className="col-12 col-md-12 col-lg-3 my-2">
              <input
                ref={searchRef}
                className="form-control"
                placeholder="Cerca un cliente"
                onChange={(e) => {
                  onSearchChange(e.target.value);
                }}
              />
            </div>
            <div className="col-12 col-md-12 col-lg-3">
              <select
                title="select type"
                ref={sortTypeFilterRef}
                className="form-select d-inline w-100 my-2"
                onChange={(e) => {
                  if (e.target.value === "null") {
                    setSelectedReportType(null);
                  } else {
                    onChangeType(e.target.value as DeleteReportType);
                  }
                  setOffset(0);
                }}
              >
                <option value="null">Filtra per Tipologia</option>
                <option value={DeleteReportType.DELETESIC}>Cancellazione Segnalazioni SIC</option>
                <option value={DeleteReportType.DELETEPROP}>Cancellazione Segnalazioni Protesti</option>
              </select>
            </div>
            <div className="col-12 col-md-12 col-lg-3">
              <select
                title="select status"
                ref={sortStatusFilterRef}
                className="form-select d-inline w-100 my-2"
                onChange={(e) => {
                  if (e.target.value === "null") {
                    setSelectReportStatus(null);
                  } else {
                    onChangeStatus(e.target.value as DeleteReportType);
                  }
                  setOffset(0);
                }}
              >
                <option value="null">Filtra per Avanzamento</option>
                {configurations.typeServiceStatus.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.value}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-12 col-md-12 col-lg-3 my-2">
              <div className="btn-group w-100">
                <button className="btn btn-primary">Cerca</button>

                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => {
                    resetFilter();
                  }}
                >
                  Resetta Filtri
                </button>
              </div>
            </div>
          </div>
          {/* PAGINATOR */}
          <div className="col-12 mt-1">
            {deleteReportRequest && deleteReportRequest.length > 0 ? (
              <nav className="pagination-section">
                <ul className="pagination justify-content-center">{pagination}</ul>
              </nav>
            ) : null}
          </div>

          {/* TABLE */}
          <table className="table table-striped">
            <thead className="thead-dark">
              <tr>
                <th></th>
                <th></th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("customerId");
                  }}
                >
                  Cliente {renderSortArrow("customerId")}
                </th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("status");
                  }}
                >
                  Stato {renderSortArrow("status")}
                </th>
                <th style={{ cursor: "not-allowed" }}>Stato Pagamento</th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("serviceStatus.status");
                  }}
                >
                  Stato Avanzamento {renderSortArrow("serviceStatus.status")}
                </th>
                <th style={{ cursor: "not-allowed" }}>Tipologia</th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("createdAt");
                  }}
                >
                  Data Creazione {renderSortArrow("createdAt")}
                </th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("serviceStatus.expireAt");
                  }}
                >
                  Data Scadenza {renderSortArrow("serviceStatus.expireAt")}
                </th>
              </tr>
            </thead>

            <tbody>
              {deleteReportRequest && deleteReportRequest.length > 0 ? (
                deleteReportRequest.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <button title="edit" className="btn btn-secondary" disabled={item.status !== "ACTIVE" || item.serviceStatus == null}>
                          <NavLink to={`service-details/${item._id}`} className="text-white">
                            <FontAwesomeIcon icon={faEdit} />
                          </NavLink>
                        </button>
                      </td>
                      <td>
                        {item.serviceStatus === null || item.serviceStatus.expireAt === null ? (
                          ""
                        ) : moment(item.serviceStatus.expireAt) > moment() ? null : (
                          <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "#f00" }} />
                        )}
                      </td>
                      <td className="text-uppercase">
                        <a href={"/customers/" + item.customerId}>
                          <span className="fw-bold">{getCustomerFullname(item)}</span>
                        </a>
                      </td>
                      <td className="text-uppercase">{getStatusLabel(item)}</td>
                      <td className="text-uppercase">{getPaymentStateLabel(item)}</td>
                      <td className="text-uppercase">{item.serviceStatus !== null ? item.serviceStatus.status : "N.D."}</td>
                      <td className="text-uppercase">{getLabelDeleteType(item)}</td>
                      <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td>
                      <td
                        className={isExpired(
                          item.serviceStatus === null || item.serviceStatus.expireAt === null
                            ? "N.D."
                            : moment(item.serviceStatus.expireAt).format("DD/MM/YYYY")
                        )}
                      >
                        {item.serviceStatus === null || item.serviceStatus.expireAt === null
                          ? "N.D."
                          : moment(item.serviceStatus.expireAt).format("DD/MM/YYYY")}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <th colSpan={8} style={{ textAlign: "center" }}>
                    Non è stato trovato nessun servizio di cancellazione
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ListDeleteReport;
