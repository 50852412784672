import React from 'react';

import { useBank } from '../../../contexts/banks-context';
import { NewPageWrapper, NewPageWrapperCopy } from '../../../ui-components/custom-components';
import FormBank from '../../../ui-components/forms-components/bank/form-bank';
import { useEthosNotification } from '../../../contexts/ethos-notification-context';

const NewBank = ({ setShowNewBank, setBanks }) => {
	const { newBank, getBanks } = useBank();
	const{ handleError} = useEthosNotification();
	const onSubmit = async (form) => {
		const { error } = await newBank(form);

		if (error !== null){
			handleError(error);
			return;
		}
		const { data } = await getBanks();
		setBanks(data.data);
		setShowNewBank(false);
	}

	return (
		<NewPageWrapper className="new-Bank-page new-page">
			<NewPageWrapperCopy>
				<div className="top-page">
					<h3 className="top-page-title">Nuova Banca</h3>
					<button className="close-button" onClick={() => setShowNewBank(false)}>&times;</button>
				</div>

				<hr />

				<FormBank handleSave={onSubmit} />
			</NewPageWrapperCopy>
		</NewPageWrapper>
	)
}

export default NewBank;