import { useEffect, useState, useRef, useCallback } from 'react';
import moment from 'moment';
import { renderPaginator } from '../../utils/functions';
import { useEthosNotification } from '../../contexts/ethos-notification-context';
import { useActivities } from '../../contexts/activities-context';
import { useConfigurations } from '../../contexts/configurations-context';
import { useUser } from '../../contexts/user-context';
import { useEthosModal } from '../../contexts/ethos-modal-context';
import { getActivityStatusLabel, getActivityTypeLabel } from '../../utils/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

const ListActivities = (props) => {
    const [activities, setActivities] = useState([]);
    const { configurations } = useConfigurations();
    const userRef = useRef();
    const typeRef = useRef();
    const statusRef = useRef();
    const dateFromRef = useRef();
    const dateToRef = useRef();
    const LIMIT = 12;
    const [offset, setOffset] = useState(0);
    const [pagination, setPagination] = useState(null);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [activityTypes, setActivityTypes] = useState(null);
    const [activityStatuses, setActivityStatuses] = useState(null);
    const { handleError, showSuccessToast } = useEthosNotification();
    const [users, setUsers] = useState(null);
    const { getAll, deleteActivity, editActivity,sendResumeByEmail } = useActivities();
    const { getUsers } = useUser();
    const { openNotesModal, openActivityModal, openEthosConfirmModal } = useEthosModal();

    const [sortColumn, setSortColumn] = useState({ column: null, direction: 0 });

    const [sortFilter,setSortFilter] = useState(null);

    const initActivities = useCallback(async () => {
        var params = {};
        params.limit = LIMIT;
        params.offset = offset;
        if (!!selectedUser) {
            params.owner = selectedUser;
        }
        if (!!selectedStatus) {
            params.state = selectedStatus;
        }
        if (!!selectedType) {
            params.type = selectedType;
        }
        if (!!sortFilter) {
            params.sort = sortFilter;
        }
        if (!!dateFrom) {
            var start = new Date(dateFrom);
            start.setUTCHours(0, 0, 0, 0);
            params.dateFrom = start;
        }
        if (!!dateTo) {
            var end = new Date(dateTo);
            end.setUTCHours(23, 59, 59, 999);
            params.dateTo = end;
        }
        const { data, error } = await getAll(params);
        if (error) {
            handleError(error);
            return;
        }
        setActivities(data.data);
        renderPaginator(data.length, LIMIT, setOffset, setPagination, offset);
    }, [dateFrom, dateTo, getAll, handleError, offset, selectedStatus, selectedType, selectedUser, sortFilter]);


    useEffect(() => {
        const loadUsers = async () => {
            var { data, error } = await getUsers(null, null, null, 0);
            if (error) {
                handleError(error);
                return;
            }
            setUsers(data.data);
        }

        const init = () => {
            loadUsers();
            initActivities();
        }

        init();
    }, [offset, getUsers, handleError, initActivities]);



    useEffect(() => {
        if (configurations) {
            var statuses = [];
            var types = [];
            for (let i = 0; i < configurations.typeActivity.length; i++) {
                const obj = {};
                obj.key = configurations.typeActivity[i].value;
                obj.label = configurations.typeActivity[i].label;
                types.push(obj);
            }

            for (let i = 0; i < configurations.activityStatus.length; i++) {
                const obj = {};
                obj.key = configurations.activityStatus[i].value;
                obj.label = configurations.activityStatus[i].label;
                statuses.push(obj);
            }

            setActivityStatuses(statuses);
            setActivityTypes(types);
        }
    }, [configurations]);

    const getCustomerName = (activity) => {
        var cust = activity.customerdata[0];
        return cust ? cust.first_name + " " + cust.last_name : "N.D.";
    }

    const getOwnerName = (activity) => {
        var idOwner = activity.activityOwner;
        var owner = users.find(x => x._id === idOwner);
        return owner ? owner.first_name + " " + owner.last_name : "N.D.";
    }

    const getCreatedBy = (activity) => {
        var idCreatedBy = activity.createdBy;
        var userCreatedBy = users.find(x => x._id === idCreatedBy);
        return userCreatedBy ? userCreatedBy.first_name + " " + userCreatedBy.last_name : "N.D.";
    }

    const openActivityNotesModal = (note) => {
        openNotesModal(note, "Note dell'attività");
    }

    const sendResumeMail = useCallback(
      async (id, activity) => {
        const { error } = await editActivity(activity);
        if (error) {
          handleError(error);
          return;
        }

        initActivities();
        showSuccessToast("Attività aggiornata correttamente");

        const sendEmailError = await sendResumeByEmail(id);
        if (sendEmailError.error) {
          handleError(sendEmailError.error);
          return;
        }
      },
      [editActivity, handleError, initActivities, sendResumeByEmail, showSuccessToast]
    );

    const doOpenActivityModal = (act) => {
        openActivityModal(doUpdateActivity, configurations, act ? act.customer : null, act, act ? act.customerId : null, act ? act.activityOwner : null, users,sendResumeMail);
    }

    const doDeleteActivity = async (activityId) => {
        const deleteCallback = async () => {
            const { error } = await deleteActivity(activityId);
            if (error) {
                handleError(error);
                return;
            }
            showSuccessToast("Attività eliminata correttamente");
            initActivities();
        }
        openEthosConfirmModal("Sicuro di voler eliminare l'attività?", deleteCallback, null, "Conferma eliminazione");
    }

    const doUpdateActivity = async (activity) => {
        const { error } = await editActivity(activity);
        if (error) {
            handleError(error);
            return;
        }

        initActivities();
        showSuccessToast("Attività aggiornata correttamente");
    }

    const clearFilter = () => {
        setSelectedUser(null)
        userRef.current.value = null;

        setSelectedType(null)
        typeRef.current.value = null;

        setSelectedStatus(null);
        statusRef.current.value = null;

        setDateFrom(null);
        dateFromRef.current.value = null;

        setDateTo(null);
        dateToRef.current.value = null;

        setSortFilter(null)
    }

    const sortingColumn = (columnName) => {
      if (!!sortColumn) {
        if (sortColumn.column === columnName) {
          setSortColumn({
            column: columnName,
            direction: sortColumn.direction === 1 ? -1 : 1,
          });
        } else {
          setSortColumn({ column: columnName, direction: 1 });
        }
      }
    };

    const renderSortArrow = (columnName) => {
      if (sortFilter === null) {
        return <FontAwesomeIcon icon={faSort} style={{opacity:".50"}} className='ml-2'/>
      }
      if (!!sortColumn) {
        if (sortColumn.column === columnName) {
          return sortColumn.direction === 1 ? <FontAwesomeIcon icon={faSortUp} className='ml-2'/> : <FontAwesomeIcon icon={faSortDown} className='ml-2'/>;
        }
      }
    };

    useEffect(() => {
        if(!!sortColumn.column){
            setSortFilter(`${sortColumn.column}:${sortColumn.direction}`);
        }
    }, [sortColumn]);

    useEffect(()=>{
      setOffset(0)
    },[selectedUser,selectedType,selectedStatus,dateFrom,dateTo])
  

    return (
        <>
            <div className="row">
                <div className="col-12 text-center mb-3">
                    <h3>Lista delle attività</h3>
                </div>

                <div className="col-10 offset-1">
                    <hr></hr>
                </div>

                <div className="col-10 offset-1">
                    <div className="row">
                        <div className="col-2">
                            <label htmlFor="user">Filtra per utente</label>
                            <select name="user" ref={userRef} className="form-select" onChange={(e) => setSelectedUser(e.target.value)}>
                                <option value="null">Scegli utente</option>
                                {users &&
                                    users.map((item, index) => <option key={"user_" + index} value={item._id}>{item.username}</option>)
                                }
                            </select>
                        </div>

                        <div className="col-2">
                            <label>Filtra per Tipo</label>
                            <select ref={typeRef} className="form-select" onChange={(e) => setSelectedType(e.target.value)}>
                                <option value="null">Scegli Tipo</option>
                                {activityTypes &&
                                    activityTypes.map((item, index) => <option key={"type_" + index} value={item.key}>{item.label}</option>)
                                }
                            </select>
                        </div>

                        <div className="col-2">
                            <label>Filtra per Stato</label>
                            <select ref={statusRef} className="form-select" onChange={(e) => setSelectedStatus(e.target.value)}>
                                <option value="null">Scegli Stato</option>
                                {activityStatuses &&
                                    activityStatuses.map((item, index) => <option key={"status_" + index} value={item.key}>{item.label}</option>)
                                }
                            </select>
                        </div>

                        <div className="col-2">
                            <label>Data da</label>
                            <input className={'form-control'} name="date_from" id="date_from" type="date" ref={dateFromRef} onChange={(e) => setDateFrom(e.target.value)} />
                        </div>

                        <div className="col-2">
                            <label>Data a</label>
                            <input className={'form-control'} name="date_to" id="date_to" type="date" ref={dateToRef} onChange={(e) => setDateTo(e.target.value)} />
                        </div>

                        <div className="col-2 mt-auto">
                            <div className="btn-group" style={{ 'marginBottom': '3px' }}>
                                <button onClick={() => clearFilter()} type="button" className="btn btn-sm btn-secondary">Resetta Filtri</button>
                                <button onClick={() => initActivities()} type="button" className="btn btn-sm btn-primary">Cerca</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-10 offset-1">
                    <hr></hr>
                </div>

                <div className="col-10 offset-1 mt-2">
                    <nav className="pagination-section">
                        <ul className="pagination justify-content-center">
                            {pagination}
                        </ul>
                    </nav>

                    <table className="table table-striped">
                        <thead className="thead-dark">
                            <tr>
                                <th style={{cursor:"pointer"}} className="text-center" onClick={()=>{sortingColumn("createdBy")}}>Creata da {renderSortArrow("createdBy")}</th>
                                <th style={{cursor:"pointer"}} className="text-center" onClick={()=>{sortingColumn("activityOwner")}}>Assegnata a {renderSortArrow("activityOwner")}</th>
                                <th style={{cursor:"pointer"}} className="text-center" onClick={()=>{sortingColumn("activityType")}}>Tipo Attività {renderSortArrow("activityType")}</th>
                                <th style={{cursor:"pointer"}} className="text-center" onClick={()=>{sortingColumn("customerdata.first_name")}}>Cliente {renderSortArrow("customerdata.first_name")}</th>
                                <th style={{cursor:"pointer"}} className="text-center" onClick={()=>{sortingColumn("activityDate")}}>Data {renderSortArrow("activityDate")}</th>
                                <th style={{cursor:"pointer"}} className="text-center" onClick={()=>{sortingColumn("location")}}>Luogo {renderSortArrow("location")}</th>
                                <th style={{cursor:"pointer"}} className="text-center" onClick={()=>{sortingColumn("duration")}}>Durata {renderSortArrow("duration")}</th>
                                <th style={{cursor:"pointer"}} className="text-center" onClick={()=>{sortingColumn("activityStatus")}}>Stato {renderSortArrow("activityStatus")}</th>
                                <th className="text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(activities && users) ?
                                activities.map((act, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-center">{getCreatedBy(act)}</td>
                                            <td className="text-center">{getOwnerName(act)}</td>
                                            <td className="text-center">{getActivityTypeLabel(act, configurations)}</td>
                                            <td className="text-center">
                                                <a href={"/customers/" + act.customerId}>
                                                    <span className="fw-bold">{getCustomerName(act)}</span>
                                                </a>
                                            </td>
                                            <td className="text-center">{moment(act.activityDate).format("DD/MM/yyyy HH:mm")}</td>
                                            <td className="text-center">{act.location}</td>
                                            <td className="text-center">{act.duration ? (act.duration + " minuti") : ""}</td>
                                            <td className="text-center">{getActivityStatusLabel(act, configurations)}</td>
                                            <td className="text-center">
                                                <div className="btn-group" role="group">
                                                    <button id="btnGroupDrop1" type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Azioni
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                        <li><button onClick={() => doOpenActivityModal(act)} className="dropdown-item">Modifica</button></li>
                                                        <li><button onClick={() => openActivityNotesModal(act.note)} className="dropdown-item">Vedi note</button></li>
                                                        <li><button onClick={() => doDeleteActivity(act._id)} className="dropdown-item text-danger">Elimina</button></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                : null}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default ListActivities;