import { Switch, Route } from 'react-router-dom';

import { CustomerProvider } from '../contexts/customer-context';
import { UserProvider } from '../contexts/user-context';
import { ConfigurationsContextProvider } from '../contexts/configurations-context';
import { ActivitiesProvider } from '../contexts/activities-context';
import ListActivities from '../components/activities/list-activities';

const Activities = ({ match }) => {
	return (
		<div className="activities-page">
			<CustomerProvider>
				<UserProvider>
					<ConfigurationsContextProvider>
						<ActivitiesProvider>
							<Switch>
								<Route exact path={`${match.path}`} component={ListActivities} />
							</Switch>
						</ActivitiesProvider>
					</ConfigurationsContextProvider>
				</UserProvider>
			</CustomerProvider>
		</div>
	)
}

export default Activities;