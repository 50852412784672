import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEdit, faExclamationCircle, faExclamationTriangle, faSort, faSortDown, faSortUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { usePracticesCqs } from '../../../contexts/practices-cqs-context';
import { useEthosNotification } from '../../../contexts/ethos-notification-context';
import { useBank } from '../../../contexts/banks-context';
import { useUser } from '../../../contexts/user-context';
import NewPracticeCqs from '../new-practice-cqs/new-practice-cqs';
import { renderPaginator } from '../../../utils/functions';


const ListPracticesCqs = (props) => {
	const { user } = useAuthenticator();
	const { getPractices, deletePractice, generateExcel } = usePracticesCqs();
	const { getUsersPractice } = useUser();
	const { getBanksForPractice } = useBank();
	const [practices, setPractices] = useState(null)
	const [showNewPracticeCqs, setShowNewPracticeCqs] = useState(false);
	const [filter, setFilter] = useState(null);
	const [filiale, setFiliale] = useState(null);
	const [search, setSearch] = useState(null);
	const [myUser, setMyUser] = useState(null);
	const [users, setUsers] = useState([]);
	const [banks, setBanks] = useState([]);
	const { handleError } = useEthosNotification();

	const statusRef = useRef();
	const bankRef = useRef();
	const userRef = useRef();
	const searchRef = useRef();


	const [sortColumn, setSortColumn] = useState({ column: null, direction: 0 });
	const [sortFilter,setSortFilter] = useState(null);	

	const limit = 12;
	const [offset, setOffset] = useState(0);
	const [pagination, setPagination] = useState(null);
	const role = user && user.signInUserSession && user.signInUserSession.accessToken && user.signInUserSession.accessToken.payload &&
		user.signInUserSession.accessToken.payload["cognito:groups"] && user.signInUserSession.accessToken.payload["cognito:groups"].length
		? user.signInUserSession.accessToken.payload["cognito:groups"][0] : "CLIENTE";
	

	useEffect(() => {
		const _getPractices = async () => {
			const { data, error } = await getPractices(search, filter === 'null' ? null : filter, filiale === 'null' ? null : filiale, myUser === 'null' ? null : myUser, limit, offset,sortFilter);
			if (error) {
				handleError(error);
				return;
			}
			setPractices(data.data);
			renderPaginator(data.length, limit, setOffset, setPagination, offset);
		}

		const getUsers = async () => {
			const { data, error } = await getUsersPractice();
			if (error) {
				handleError(error);
				return;
			}
			setUsers(data);
		}

		const getBanks = async () => {
			const { data, error } = await getBanksForPractice();
			if (error) {
				handleError(error);
				return;
			}
			setBanks(data);
		}

		_getPractices();
		getBanks();
		getUsers();
	}, [search, filter, filiale, myUser, limit, offset, getPractices, getBanksForPractice, getUsersPractice, handleError, sortFilter]);

	const handleSearch = async () => {
	}

	const handleDeletePractices = async (id) => {
		const confirmDelete = window.confirm('Sei sicuro di voler eliminare la pratica?');

		if (!confirmDelete) {
			return;
		}

		const { error } = await deletePractice(id);

		if (error !== null) {
			return console.log('Impossibile eliminare la pratica');
		}

		const { data } = await getPractices(search, filter === 'null' ? null : filter, filiale === 'null' ? null : filiale, myUser === 'null' ? null : myUser, limit, offset,sortFilter);
		setPractices(data.data);
		renderPaginator(data.length, limit, setOffset, setPagination, offset);
	}

	const handleGenerateExcel = async () => {
		const { data, error } = await generateExcel(search, filter === 'null' ? null : filter, filiale === 'null' ? null : filiale, myUser === 'null' ? null : myUser);


		if (error !== null) {
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}

		const buffer = new Uint8Array(data.buffer.data);
		const filename = 'Pratiche-CQS.xlsx';

		const blob = new Blob([buffer], {});

		const url = window.URL.createObjectURL(blob);
		const element = document.createElement('a');

		element.href = url;
		element.download = filename;

		document.body.appendChild(element);

		element.click();
		element.remove();
		console.log(data);
	}

	const sortingColumn = (columnName) => {
		if (!!sortColumn) {
		  if (sortColumn.column === columnName) {
			setSortColumn({
			  column: columnName,
			  direction: sortColumn.direction === 1 ? -1 : 1,
			});
		  } else {
			setSortColumn({ column: columnName, direction: 1 });
		  }
		}
	  };
  
	  const renderSortArrow = (columnName) => {
		if (sortFilter === null) {
		  return <FontAwesomeIcon icon={faSort} style={{opacity:".50"}} className='ml-2'/>
		}
		if (!!sortColumn) {
		  if (sortColumn.column === columnName) {
			return sortColumn.direction === 1 ? <FontAwesomeIcon icon={faSortUp} className='ml-2'/> : <FontAwesomeIcon icon={faSortDown} className='ml-2'/>;
		  }
		}
	  };
  
	  const resetFilter = () => {
      setFilter(null);
      statusRef.current.value = null;

      setFiliale(null);
      bankRef.current.value = null;

      setMyUser(null);
      userRef.current.value = null;

      setSearch(null);
      searchRef.current.value = null;

      setSortFilter(null);
    };

	
	  useEffect(() => {
      if (!!sortColumn.column) {
        setSortFilter(`${sortColumn.column}:${sortColumn.direction}`);
      }
    }, [sortColumn]);

	useEffect(() => {
		setOffset(0);
	}, [filter,filiale,search,myUser,user]);

	return (
		<div className="list-practices-page">
			<div className="top-bar">
				<button className="btn btn-primary" onClick={() => setShowNewPracticeCqs(!showNewPracticeCqs)}>Nuova Pratica</button>
				<button className="btn btn-outline-primary mx-2" onClick={() => {resetFilter()}}>Resetta Filtri</button>

				<select ref={statusRef} className="form-select d-inline w-auto mx-2" onChange={(e) => setFilter(e.target.value)}>
					<option value="null">Filtra per stato</option>
					<option value="BOZZA">BOZZA</option>
					<option value="119">119</option>
					<option value="IN CORSO">IN CORSO</option>
					<option value="DA ESAMINARE">DA ESAMINARE</option>
					<option value="RECLAMO">RECLAMO</option>
					<option value="CONTROPROPOSTA">CONTROPROPOSTA</option>
					<option value="SOLLECITO">SOLLECITO</option>
					<option value="KO">KO</option>
					<option value="ACCETTAZIONE">ACCETTAZIONE</option>
					<option value="CONTRODEDUZIONE">CONTRODEDUZIONE</option>
					<option value="ABF">ABF</option>
					<option value="RECUPERATA">RECUPERATA</option>
					<option value="SCADENZA:1">SCADENZA</option>
					<option value="40%">40%</option>
				</select>

				{
					String(filter).split(':')[0] === 'SCADENZA' ?
						<select className="form-select d-inline w-auto mx-2" onChange={(e) => setFilter(e.target.value)}>
							<option value="SCADENZA:1">Gennaio</option>
							<option value="SCADENZA:2">Febbraio</option>
							<option value="SCADENZA:3">Marzo</option>
							<option value="SCADENZA:4">Aprile</option>
							<option value="SCADENZA:5">Maggio</option>
							<option value="SCADENZA:6">Giugno</option>
							<option value="SCADENZA:7">Luglio</option>
							<option value="SCADENZA:8">Agosto</option>
							<option value="SCADENZA:9">Settembre</option>
							<option value="SCADENZA:10">Ottobre</option>
							<option value="SCADENZA:11">Novembre</option>
							<option value="SCADENZA:12">Dicembre</option>
						</select>
						: null
				}

				<select ref={bankRef} className="form-select d-inline w-auto mx-2" onChange={(e) => {setFiliale(e.target.value);setOffset(0);}}>
					<option value="null">Filtra per banca</option>
					{
						banks.map((item, index) => <option key={index} value={item.name}>{item.name}</option>)
					}
				</select>

				{
					role === 'ADMIN' ?
						<select ref={userRef} className="form-select d-inline w-auto mx-2" onChange={(e) => {setMyUser(e.target.value);setOffset(0);}}>
							<option value="null">Filtra per utente</option>
							{
								users.map((item, index) => <option key={index} value={item._id}>{item.username}</option>)
							}
						</select>
						: null
				}

				<div className="wrapper-input-group w-auto d-inline-block">
					<div className="input-group">
						<input ref={searchRef} className="form-control" placeholder="Cerca una pratica" onChange={(e) => {setSearch(e.target.value);setOffset(0);}} />
						<input className="btn btn-primary" type="submit" value="Cerca" onClick={handleSearch} />
					</div>
				</div>

				<button onClick={handleGenerateExcel} className="btn btn-primary mx-2">Genera XLSX</button>
			</div>

			<hr />

			<nav className="pagination-section">
				<ul className="pagination justify-content-center">
					{pagination}
				</ul>
			</nav>

			<table className="table table-striped">
				<thead className="thead-dark">
					<tr>
						<th></th>
						<th></th>
						<th style={{cursor:"pointer"}} onClick={()=>{sortingColumn("nContract")}}>N. Contratto {renderSortArrow("nContract")}</th>
						<th style={{cursor:"pointer"}} onClick={()=>{sortingColumn("bank")}}>Banca {renderSortArrow("bank")}</th>
						<th style={{cursor:"pointer"}} onClick={()=>{sortingColumn("customerdata.first_name")}}>Cliente {renderSortArrow("customerdata.first_name")}</th>
						<th style={{cursor:"pointer"}} onClick={()=>{sortingColumn("userdata.username")}}>Operatore {renderSortArrow("userdata.username")}</th>
						<th style={{cursor:"pointer"}} onClick={()=>{sortingColumn("status")}}>Stato {renderSortArrow("status")}</th>
						<th style={{cursor:"pointer"}} onClick={()=>{sortingColumn("expired_at")}}>Data Scadenza {renderSortArrow("expired_at")}</th>
						<th style={{cursor:"pointer"}} onClick={()=>{sortingColumn("created_at")}}>Data Creazione {renderSortArrow("created_at")}</th>
					</tr>
				</thead>
				<tbody className="practice-list">
					{practices !== null ?
						practices.map((item, index) => {
							return (
								<tr key={index} className={item.status === 'KO' ? 'red' : item.status === 'RECUPERATA' ? 'green' : item.status === 'IN CORSO' ? 'orange' : null}>
									<td>
										<NavLink to={`${props.match.path}/${item._id}`} className="btn btn-secondary"><FontAwesomeIcon icon={faEdit} /></NavLink>
										<button type="button" onClick={() => handleDeletePractices(item._id)} className="mx-2 btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
									</td>
									{
										item.expired_at != null ?
											<td>{moment(item.expired_at) > moment() ? null : <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#f00' }} />}</td>
											: item.status === '40%' && filter === '40%' ?
												moment() > moment(item.dateStart).add((((moment(item.dateEnd).year() - moment(item.dateStart).year()) * 40) / 100), 'years') ? <td><FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 24 }} /></td> : <td><FontAwesomeIcon icon={faExclamationCircle} style={{ fontSize: 24 }} /></td>
												: <td></td>
									}
									<td className="text-uppercase">{item.nContract}</td>
									<td className="text-uppercase">{item.bank}</td>
									<td className="text-uppercase">{`${item.customerdata[0].first_name} ${item.customerdata[0].last_name}`}</td>
									<td>{item.userdata[0]?.username}</td>
									<td className="text-uppercase">{item.status}</td>
									<td className="text-uppercase">{item.expired_at ? moment(item.expired_at).format('DD/MM/YYYY') : 'n/a'}</td>
									<td className="text-uppercase">{moment(item.created_at).format('DD/MM/YYYY')}</td>
								</tr>
							)
						})
						: null}
				</tbody>
			</table>

			{showNewPracticeCqs ? <NewPracticeCqs setShowNewPracticeCqs={setShowNewPracticeCqs} setPractices={setPractices} /> : null}

		</div>
	)
}

export default ListPracticesCqs;