import React from 'react';

import Dashboard from '../../ui-components/dashboard/dashboard';

const AuthenticatedApp = ({ signOut }) => {
	return (
		<Dashboard signOut={signOut} />
	)
}

export default AuthenticatedApp