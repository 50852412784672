import React from 'react';
import { useEthosNotification } from '../../contexts/ethos-notification-context';

import { useTickets } from '../../contexts/tickets-context';
import { NewPageWrapper, NewPageWrapperCopy } from '../../ui-components/custom-components';

import FormTicket from '../../ui-components/forms-components/ticket';

const CreateTicket = ({ setShowCreateTicket, setTickets }) => {
	const { createTicket, getTickets } = useTickets();
	const {handleError} = useEthosNotification();

	const onSubmit = async (form) => {
		const { error } = await createTicket(form);

		if (error != null){
			handleError(error);
			return;
		}
		const { data } = await getTickets();
		setTickets(data);
		setShowCreateTicket(false);
	}

	return (
		<NewPageWrapper className="create-ticket-paeg new-page">
			<NewPageWrapperCopy>
				<div className="top-page">
					<h3 className="top-page-title">Nuovo Ticket</h3>
					<button className="close-button" onClick={() => setShowCreateTicket(false)}>&times;</button>
				</div>

				<hr />

				<FormTicket handleSave={onSubmit} />
			</NewPageWrapperCopy>
		</NewPageWrapper>
	)
}

export default CreateTicket;

