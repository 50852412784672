import React, { useCallback } from 'react'
import { Auth } from 'aws-amplify';
import { httpPost, httpDelete, httpPatch, httpGet } from '../http'
import { ACTIVITIES_ENDPOINT } from '../constants/API_ENDPOINT';
import { useEthosNotification } from './ethos-notification-context';

const ActivitiesContext = React.createContext();

function ActivitiesProvider(props) {
	const { registerPendingRequest, unregisterPendingRequest } = useEthosNotification();

	const getAll = useCallback(async (params) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${ACTIVITIES_ENDPOINT}`, jwtToken, params);

			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getActivityById = useCallback(async (id) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${ACTIVITIES_ENDPOINT}/` + id, jwtToken, {});

			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const editActivity = useCallback(async (newObj) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPatch(`${ACTIVITIES_ENDPOINT}`, jwtToken, newObj, newObj._id);
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const saveNewActivity = useCallback(async (data) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPost(`${ACTIVITIES_ENDPOINT}`, jwtToken, data);
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const deleteActivity = useCallback(async (id) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDelete(`${ACTIVITIES_ENDPOINT}`, jwtToken, id);
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const sendResumeByEmail = useCallback(async (id) => {
    try {
      registerPendingRequest();
	  const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
	  const result = await httpPost(`${ACTIVITIES_ENDPOINT}/${id}/sendMail`, jwtToken,{});
		return {
			data: result.data,
			error: null,
			meta: null,
			status: result.status,
		}
    } catch (err) {
		return {
			data: null,
			error: err,
			meta: null,
			status: null,
		}
    } finally {
      unregisterPendingRequest();
    }
  },[registerPendingRequest,unregisterPendingRequest]);

	return (
		<ActivitiesContext.Provider
			value={{
				getAll,
				getActivityById,
				editActivity,
				saveNewActivity,
				deleteActivity,
				sendResumeByEmail

			}}
			{...props}
		/>
	)
}
const useActivities = () => React.useContext(ActivitiesContext)

export { ActivitiesProvider, useActivities }