import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Login } from '../../ui-components/login/login';


const UnauthenticatedApp = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Route path="*" component={Login} />
			</Switch>
		</BrowserRouter>
	)
}

export default UnauthenticatedApp