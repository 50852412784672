import React from 'react'
import { AuthProvider } from './auth-context'
import { EthosNotificationContextProvider } from './ethos-notification-context'
import { EthosModalContextProvider } from './ethos-modal-context'
import { ConfigurationsContextProvider } from './configurations-context'

function AppProviders({ children }) {
  return (
      <EthosNotificationContextProvider>
        <ConfigurationsContextProvider>
          <EthosModalContextProvider>
            <AuthProvider>
              {children}
            </AuthProvider>
          </EthosModalContextProvider>
        </ConfigurationsContextProvider>
      </EthosNotificationContextProvider>
  )
}
export default AppProviders