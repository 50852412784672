import React from 'react';
import { useForm } from 'react-hook-form';

import { InputTextArea } from '../../forms';

const FormAddComment = ({ handleSave }) => {
	const { register, handleSubmit } = useForm({});

	const onSubmit = async (data) => {
		await handleSave(data);
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InputTextArea label="Commento" name="comment" register={register} />
			<input type="submit" className={'btn btn-primary mt-2'} value="Aggiungi" />
		</form>
	)
}

export default FormAddComment;