import React, { useCallback, useState } from 'react'

import { Auth } from 'aws-amplify';
import { httpPost, httpDelete, httpPatch, httpGet, httpDownload, uploadFileToS3, httpPut } from '../http'
import { PRACTICES_CQS_ENDPOINT } from '../constants/API_ENDPOINT'

const PracticesCqsContext = React.createContext()

function PracticesCqsProvider(props) {
	const [logs, setLogs] = useState([]);

	const getPractices = useCallback(async (search = null, filter = null, filiale = null, user = null, limit = 12, offset = 1,sort=null) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null,
			sort: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const practices = await httpGet(`${PRACTICES_CQS_ENDPOINT}`, jwtToken, {
				filter,
				filiale,
				search,
				limit,
				offset,
				user,
				sort
			});
			obj = {
				data: practices.data,
				error: null,
				meta: null,
				status: practices.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const generateExcel = useCallback(async (search = null, filter = null, filiale = null, user = null) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const practices = await httpGet(`${PRACTICES_CQS_ENDPOINT}/generate-xlsx`, jwtToken, {
				filter,
				filiale,
				search,
				user
			});
			obj = {
				data: practices.data,
				error: null,
				meta: null,
				status: practices.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const getCqsSummary = useCallback(async () => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const practices = await httpGet(`${PRACTICES_CQS_ENDPOINT}/summary`, jwtToken, {});
			obj = {
				data: practices.data,
				error: null,
				meta: null,
				status: practices.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const getSinglePractice = useCallback(async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const practice = await httpGet(`${PRACTICES_CQS_ENDPOINT}/${id}`, jwtToken);

			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};

			setLogs(practice.data.logs);
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const newPractice = useCallback(async (data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const practice = await httpPost(`${PRACTICES_CQS_ENDPOINT}`, jwtToken, data);
			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const editPractice = useCallback(async (id, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const practice = await httpPatch(`${PRACTICES_CQS_ENDPOINT}`, jwtToken, data, id);
			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const deletePractice = useCallback(async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const practice = await httpDelete(`${PRACTICES_CQS_ENDPOINT}`, jwtToken, id);
			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const uploadFileCqs = useCallback(async (id, type, data, file) => {
		let result = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();

			const response = await httpPost(`${PRACTICES_CQS_ENDPOINT}/upload/url/${type}/${id}`, jwtToken, data);
			await uploadFileToS3(response.data.url, file);
			const docResponse = await httpPut(`${PRACTICES_CQS_ENDPOINT}/upload/url/${type}/${id}`, jwtToken, data);

			result = {
				data: docResponse.data,
				error: null,
				meta: null,
				status: docResponse.status
			}
		} catch (err) {
			result = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return result;
	}, []);

	const uploadCustomFileCqs = useCallback(async (id, filename, data, file) => {
		let result = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();

			const response = await httpPost(`${PRACTICES_CQS_ENDPOINT}/upload-custom/url/${filename}/${id}`, jwtToken, data);
			await uploadFileToS3(response.data.url, file);
			const docResponse = await httpPut(`${PRACTICES_CQS_ENDPOINT}/upload-custom/url/${filename}/${id}`, jwtToken, data);

			result = {
				data: docResponse.data,
				error: null,
				meta: null,
				status: docResponse.status
			}
		} catch (err) {
			result = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return result;
	}, []);

	const downloadFileCqs = useCallback(async (id, type) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDownload(`${PRACTICES_CQS_ENDPOINT}/download/url/${type}/${id}`, jwtToken);

			const link = document.createElement('a');
			link.href = result.data.url;
			link.setAttribute('download', `${result.data.filename}`);
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const deleteFileCqs = useCallback(async (id, type) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDelete(`${PRACTICES_CQS_ENDPOINT}/delete/${type}`, jwtToken, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const downloadCustomFileCqs = useCallback(async (id, filename) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDownload(`${PRACTICES_CQS_ENDPOINT}/download-custom/url/${filename}/${id}`, jwtToken);

			const link = document.createElement('a');
			link.href = result.data.url;
			link.setAttribute('download', `${result.data.filename}`);
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const deleteCustomFileCqs = useCallback(async (id, filename) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDelete(`${PRACTICES_CQS_ENDPOINT}/delete-custom/${filename}`, jwtToken, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const uploadCustomFileCqsPec = useCallback(async (id, filename, data, file) => {
		let result = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();

			const response = await httpPost(`${PRACTICES_CQS_ENDPOINT}/upload-pec/url/${filename}/${id}`, jwtToken, data);
			await uploadFileToS3(response.data.url, file);
			const docResponse = await httpPut(`${PRACTICES_CQS_ENDPOINT}/upload-pec/url/${filename}/${id}`, jwtToken, data);

			result = {
				data: docResponse.data,
				error: null,
				meta: null,
				status: docResponse.status
			}
		} catch (err) {
			result = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return result;
	}, []);

	const downloadCustomFileCqsPec = useCallback(async (id, filename) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDownload(`${PRACTICES_CQS_ENDPOINT}/download-pec/url/${filename}/${id}`, jwtToken);

			const link = document.createElement('a');
			link.href = result.data.url;
			link.setAttribute('download', `${result.data.filename}`);
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const deleteCustomFileCqsPec = useCallback(async (id, filename) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDelete(`${PRACTICES_CQS_ENDPOINT}/delete-pec/${filename}`, jwtToken, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const resetExpireTimer = useCallback(async (id, date) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const practice = await httpGet(`${PRACTICES_CQS_ENDPOINT}/reset-expire/${id}`, jwtToken, { date });

			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const generateFileByType = useCallback(async (id, type, path) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDownload(`${PRACTICES_CQS_ENDPOINT}/generate/${id}/${type}`, jwtToken, { path });
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	const setPayedAndDatePayed = useCallback(async (id, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPost(`${PRACTICES_CQS_ENDPOINT}/set-payed-agent/${id}`, jwtToken, { ...data });
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}, []);

	return (
		<PracticesCqsContext.Provider
			value={{
				getPractices,
				getSinglePractice,
				newPractice,
				editPractice,
				deletePractice,
				uploadFileCqs,
				downloadFileCqs,
				generateExcel,
				deleteFileCqs,
				uploadCustomFileCqs,
				downloadCustomFileCqs,
				deleteCustomFileCqs,
				uploadCustomFileCqsPec,
				downloadCustomFileCqsPec,
				deleteCustomFileCqsPec,
				resetExpireTimer,
				generateFileByType,
				getCqsSummary,
				setPayedAndDatePayed,
				logs
			}}
			{...props}
		/>
	)
}
const usePracticesCqs = () => React.useContext(PracticesCqsContext)

export { PracticesCqsProvider, usePracticesCqs }