import { useCustomer } from "../../../contexts/customer-context";
import { NewPageWrapper, NewPageWrapperCopy } from "../../../ui-components/custom-components";
import FormCustomer from "../../../ui-components/forms-components/customer/customer";
import { useEthosNotification } from "../../../contexts/ethos-notification-context";
import { Prompt } from "react-router-dom";
import { useState, useEffect } from "react";

const NewCustomer = ({ setShowNewCustomer, setCustomers, sendNewCustomerId }) => {
  const { newCustomer, getCustomers } = useCustomer();
  const { handleError } = useEthosNotification();
  const [pendingChange, setPendingChange] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const handleSendNewCustomerId = sendNewCustomerId || (() => {});

  useEffect(() => {
    // Component did mount
    setIsMounted(true);
    // Cleanup function for when the component unmounts
    return () => setIsMounted(false);
  }, []);

  const onSubmit = async (form) => {
    const createCustomerResponse = await newCustomer(form);

    if (createCustomerResponse.error !== null) {
      handleError(createCustomerResponse.error);
      return;
    }

    handleSendNewCustomerId(createCustomerResponse.data._id);

    const { data } = await getCustomers();
    if (isMounted) {
      setCustomers(data);
      setShowNewCustomer(false);
      setPendingChange(false);
    } else {
      setShowNewCustomer(false);
      setPendingChange(false);
    }
  };

  const customerChanged = () => {
    setPendingChange(true);
  };

  return (
    <NewPageWrapper className="new-customer-page new-page">
      <NewPageWrapperCopy>
        <div className="top-page">
          <h3 className="top-page-title">Nuovo Cliente</h3>
          <button className="close-button" onClick={() => setShowNewCustomer(false)}>
            &times;
          </button>
        </div>

        <hr />

        <FormCustomer customerChanged={customerChanged} handleSave={onSubmit} />
      </NewPageWrapperCopy>
      <Prompt when={pendingChange} message="Ci sono modifiche in corso, sei sicuro di uscire?" />
    </NewPageWrapper>
  );
};

export default NewCustomer;
