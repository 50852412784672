import React, { useState, createContext, useReducer, useCallback } from "react";
import EthosLoader from '../ui-components/ethos-loader/ethos-loader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EthosNotificationContext = createContext();

const initialPendingRequestsState = { count: 0 };
function reducer(state, action) {
    switch (action.type) {
        case 'increment':
            return { count: state.count + 1 };
        case 'decrement':
            return { count: state.count - 1 };
        default:
            throw new Error();
    }
}

function EthosNotificationContextProvider({ children }) {
    const [toastDisabled, setToastDisabled] = useState(false);
    const [pendingRequestsState, dispatch] = useReducer(reducer, initialPendingRequestsState);

    const showSuccessToast = useCallback((message) => {
        if (toastDisabled) {
            return;
        }
        toast.success(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "colored",
        });
    }, [toastDisabled]);

    const showWarningToast = useCallback((message) => {
        if (toastDisabled) {
            return;
        }
        toast.warning(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "colored",
        });
    }, [toastDisabled]);

    const doSetToastDisabled = useCallback((disabled) => {
        setToastDisabled(disabled)
    }, []);

    const handleError = useCallback((error) => {
        var message = "Errore durante il processamento della richiesta";
        if (error && error.response && error.response.data && error.response.data.error) {
            if (!!error.response.data.error.description) {
                message = error.response.data.error.description;
            }
            if (error.response.data.error.exposeMeta && error.response.data.error.exposeMeta.errors && error.response.data.error.exposeMeta.errors.length) {
                message = message + ":";
                for (var i = 0; i < error.response.data.error.exposeMeta.errors.length; i++) {
                    message = message + "\n" + error.response.data.error.exposeMeta.errors[i];
                }
            }

        }
        toast.error(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "colored",
        });
    }, []);

    const showErrorToast = useCallback((message) => {
        toast.error(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "colored",
        });
    }, []);

    const registerPendingRequest = useCallback(() => {
        dispatch({ type: 'increment' })
    }, []);


    const unregisterPendingRequest = useCallback(() => {
        dispatch({ type: 'decrement' })
    }, []);

    return (
        <EthosNotificationContext.Provider value={{
            showSuccessToast,
            showWarningToast,
            showErrorToast,
            handleError,
            // handleUploadProspectsResult,
            doSetToastDisabled,
            registerPendingRequest,
            unregisterPendingRequest
        }}>
            {children}
            {!toastDisabled && <ToastContainer />}
            {pendingRequestsState.count > 0 ? <EthosLoader></EthosLoader> : null}
        </EthosNotificationContext.Provider>
    );
};

const useEthosNotification = () => React.useContext(EthosNotificationContext)

export { EthosNotificationContextProvider, useEthosNotification }