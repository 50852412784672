import React, { useState, useEffect } from 'react';
import { InputTextArea } from '../../forms';

const WriteMessageModalContent = ({ confirm = () => { }, closeFunction = () => { }, customParams = { message: null, label: "Testo del messaggio" } }) => {
    const [canConfirm, setCanConfirm] = useState(false);
    const [message, setMessage] = useState(customParams.message);

    useEffect(() => {
        if (!!message) {
            setCanConfirm(true);
        } else {
            setCanConfirm(false);
        }
    }, [message]);


    const doConfirm = () => {
        if (!canConfirm) {
            return;
        }
        confirm(message);
        closeFunction();
    }

    return (
        <>
            <div className="modal-body">
                <div className="text-center">
                    <InputTextArea name="body" isRequired={true} onChange={setMessage} defaultValue={customParams.message} label={customParams.label} />
                </div>
            </div>
            <div className="modal-footer">
                <button onClick={closeFunction} type="button" className="btn btn-primary" data-bs-dismiss="modal">CHIUDI</button>
                <button disabled={!canConfirm} onClick={doConfirm} type="button" className="btn btn-secondary" data-bs-dismiss="modal">CONFERMA</button>
            </div>
        </>
    )
}

export default WriteMessageModalContent;