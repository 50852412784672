import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useTickets } from '../../contexts/tickets-context';
import FormAddComment from '../../ui-components/forms-components/ticket/add-comment';
import { useEthosNotification } from '../../contexts/ethos-notification-context';

const SingleTicket = (props) => {
	const { id } = props.match.params;
	const { listCommentsByTicket, getSingleTicket, addCommentByTicket } = useTickets();

	const [ticket, setTicket] = useState(null);
	const [comments, setComments] = useState([]);
	const { handleError } = useEthosNotification();

	useEffect(() => {
		const getTicketData = async () => {
			const { data, error } = await getSingleTicket(id);

			if (error != null) {
				handleError(error);
				return;
			}
			setTicket(data);
		}

		const getComments = async () => {
			const { data, error } = await listCommentsByTicket(id);

			if (error != null) {
				handleError(error);
				return;
			}
			setComments(data);
		}

		getTicketData();
		getComments();
	}, [getSingleTicket, handleError, id, listCommentsByTicket]);

	const handleAddComment = async (form) => {
		const { error } = await addCommentByTicket(id, form);

		if (error != null) {
			handleError(error);
			return;
		}
		const { data } = await listCommentsByTicket(id);
		setComments(data);
	}

	return (
		<div className="single-ticket-page">
			<div className="top-bar">
				{
					ticket != null ?
						<>
							<h3 style={{ overflow: 'auto' }} className="top-page-title">{ticket.title}</h3>
							<p style={{ overflow: 'auto' }}>{ticket.description}</p>
						</>
						: null
				}
			</div>

			<hr />

			<FormAddComment handleSave={handleAddComment} />

			<hr />

			<div className="comments-list">
				{
					comments.length > 0 ?
						<>
							{
								<div className="list-comments">
									<ul style={{ maxHeight: '100%' }}>
										{
											comments.map((item, index) => {
												return (
													<li className="comment-item" key={index}>
														<p className="comment-author">{item.userdata[0]?.username} - <span className="comment-date">{moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}</span></p>
														<p className="comment-text">{item.comment}</p>
													</li>
												)
											})
										}
									</ul>
								</div>
							}
						</>
						: <p>Non ci sono commenti</p>
				}
			</div>


		</div>
	)
}

export default SingleTicket;

