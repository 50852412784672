import React, { useState } from 'react';

import { InputText, InputNumber } from '../../../forms';

const AbfRequest = ({ handleGenerateFile = () => {}, generateFile }) => {
	const [taegIn, setTaegIn] = useState(0);
	const [taegRil, setTaegRil] = useState(0);
	const [importN, setImportN] = useState(0);
	const [importLetter, setImportLetter] = useState(0);
	const [btnGenerationTxt, setBtnGenerationTxt] = useState('Genera');

	const handleGenerateFileLocal = async () => {
		const data = {
			taegIn,
			taegRil,
			importN,
			importLetter
		};

		setBtnGenerationTxt('Genero...');
		await handleGenerateFile(generateFile, 'ABF-Richieste', data);
		setBtnGenerationTxt('Genera');
	}

	return (
		<>
			<div className="row mt-4 mb-4">
				<div className="col-md-3">
					<InputNumber validation={null} type="number" price={true} step="0.01" name="taegIn" label="TEAG Indicato" onChange={setTaegIn} />
				</div>
				<div className="col-md-3">
					<InputNumber validation={null} type="number" price={true} step="0.01" name="taegRil" label="TEAG Rilevato" onChange={setTaegRil} />
				</div>
				<div className="col-md-3">
					<InputNumber validation={null} type="number" price={true} step="0.01" name="importN" label="Importo" onChange={setImportN} />
				</div>
				<div className="col-md-3">
					<InputText name="importLetter" label="Importo in Lettere" onChange={setImportLetter} />
				</div>
			</div>

			<button type="button" onClick={handleGenerateFileLocal} className="btn btn-primary">{btnGenerationTxt}</button>
		</>
	)
}

export default AbfRequest;